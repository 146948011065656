import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Pagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

export default function WalletTransactions({ EmptyState }) {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchTransactions = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(
        `/get-user-wallet-transactions/?page=${pageNum}`
      );
      console.log("API Response:", response.data);

      const results = response.data.results || response.data;
      const count =
        response.data.count ||
        (Array.isArray(response.data) ? response.data.length : 0);

      setTransactions(results);
      setTotalPages(Math.ceil(count / 10));

      console.log("Processed transactions:", results);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const formatAmount = (amount) => {
    const numAmount = parseFloat(amount);
    const prefix = numAmount >= 0 ? "+ " : "- ";
    return `${prefix}${Math.abs(numAmount).toFixed(2)}`;
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  if (isLoading && transactions.length === 0) {
    return <EmptyState message={t("Načítavam...")} />;
  }

  if (!transactions || transactions.length === 0) {
    return <EmptyState message={t("Žiadne transakcie")} />;
  }

  return (
    <div>
      <Typography variant="h5" component="h2" className="mb-4">
        {t("História pohybov v elektronickej peňaženke")}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>{t("Dátum")}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{t("Suma")}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>
                {t("Popis pohybu")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactions.map((transaction) => (
              <TableRow key={transaction.id}>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {formatDate(transaction.date)}
                </TableCell>
                <TableCell
                  className={
                    parseFloat(transaction.amount) >= 0
                      ? "text-green-600"
                      : "text-red-600"
                  }
                  style={{ whiteSpace: "nowrap" }}
                >
                  {formatAmount(transaction.amount)}
                </TableCell>
                <TableCell>
                  {transaction.custom_description.map((desc) => t(desc))}
                  {/* {transaction.bill ? ` ID:${transaction.bill}` : ""}
                  {transaction.commision ? ` ID:${transaction.commision}` : ""} */}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box className="flex justify-center mt-4">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
}
