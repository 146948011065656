import BasicNavbar from "../components/BasicNavbar";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import Footer from "../components/Footer";
import "../utils/quill.css";

const TermsAndConditions = () => {
  const { i18n } = useTranslation();

  // Get the current language key
  const languageKey = i18n.language;

  const termsContent = {
    sk: `
**Podmienky komunity bilionbuy**

- Podmienky užívania webu Bilionbuy členom komunity  
- Podmienky internetového predaja  
- Podmienky zodpovednosti za obsah príspevkov a recenzií  
- Ochrana osobných údajov na webe Bilionbuy

**Podmienky užívania webu Bilionbuy členom komunity**

**1\. Základné ustanovenia**

1.1. Bilionbuy je webová platforma poskytujúca internetový predaj vlastných a partnerských produktov a služieb, a zároveň umožňujúca registrovaným členom komunity získavať finančné a iné výhody vo forme cashbacku alebo odmien.

1.2. Tieto podmienky upravujú pravidlá užívania webovej platformy Bilionbuy, pravidlá zapojenia sa do komunity Bilionbuy, a z toho vyplývajúcich výhod pre registrovaných členov komunity Bilionbuy.

**2\. Definície pojmov**

2.1. Webová platforma \- webová stránka [www.bilionbuy.com](http://www.bilionbuy.com).

2.2. Prevádzkovateľ \- prevádzkovateľom webovej platformy je spoločnosť Bilionbuy International s.r.o., IČO: 56190999, so sídlom Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3. Člen komunity \- registráciou na webovej platforme sa registrovaný automaticky stáva členom komunity Bilionbuy.

2.4. Obchodná sieť \- do obchodnej siete člena komunity Bilionbuy patria všetci priamo aj nepriamo zaregistrovaní priatelia, ktorí boli pozvaní do komunity Bilionbuy priamo členom komunity alebo nepriamo od ním pozvaných zaregistrovaných priateľov.

2.5. Priamo pozvaný priateľ \- priateľ, ktorého do Bilionbuy pozval člen komunity Bilionbuy priamo prostredníctvom vlastnej aktivity, zaslaním pozvánky cez webovú platformu alebo zaslaním odporúčacieho linku. 

2.6. Nepriamo pozvaný priateľ \- priateľ, ktorého do Bilionbuy pozval niektorý z priateľov, ktorých člen komunity Bilionbuy pozval alebo niektorý z ich priateľov.

**3\. Registrácia a pozvanie do komunity Bilionbuy**

3.1. Registrácia prebieha na webovej platforme. 

3.2. Registrácia je možná na základe pozvania (odporúčania) od priateľa alebo priamo registráciou na webovej platforme bez odporúčateľa.

3.3. Registrácia na základe pozvania prebieha zaslaním odporúčacieho linku alebo priamo vo webovej platforme zaslaním pozvánky cez funkciu “Pozvať priateľa”.

3.4. Registráciou sa registrovaný automaticky stáva členom komunity Bilionbuy bez povinnosti platenia akýchkoľvek poplatkov za členstvo v komunite Bilionbuy.

3.5. Registráciou na webovej platforme získava člen komunity výhody členstva v komunite Bilionbuy.

**4\. Výhody členstva v komunite Bilionbuy**

4.1. Bilionbuy nestanovuje žiadne obmedzujúce podmienky na získanie výhod. Nie sú stanovené žiadne minimálne nákupy alebo minimálne aktivity. Finančné výhody sú ovplyvnené dosiahnutým obratom nákupov.

4.2. Člen komunity Bilionbuy má nárok na nasledujúce výhody za členstvo v komunite Bilionbuy. 

4.3. Cashback z vlastného nákupu \- výška cashbacku z vlastného nákupu je uvedená pri internetovom obchode, konkrétnom produkte alebo službe uvedenom na webovej platforme Bilionbuy a je rozličná pri každom internetovom obchode, produkte alebo službe.

4.4. Priamy odporúčateľský cashback \- je forma cashbacku stanovená za nákupy priamo pozvaných priateľov. Výška priameho odporúčateľského cashbacku je uvedená pri internetovom obchode, produkte alebo službe uvedenom na webovej platforme Bilionbuy a je rozličná pri každom internetovom obchode, produkte alebo službe.

4.5. Kariérne odmeny \- je forma odmeny stanovená za odporúčanie a budovanie komunity Bilionbuy podľa získaných bodov za nákupy obchodnej siete člena komunity Bilionbuy. Podmienky dosiahnutia kariérnych pozícií a výšku odmien na týchto pozíciách sa dozviete v časti “Kariérna cesta”.

4.6. Cieľový bonus \- je forma odmeny stanovená pre člena komunity Bilionbuy, v prípade ak ním priamo pozvaný člen komunity Bilionbuy, dosiahne v danom mesiaci kariérnu pozíciu Partner. V tomto prípade sa člen komunity zaradzuje medzi skupinu členov komunity Bilionbuy, ktorým je rovnakým dielom vyplácaný cieľový bonus. Cieľový bonus tvorí sumu pozostávajúcu z 1 % obratu Bilionbuy bez DPH.

4.7. Iné špeciálne výhody \- sú to ďalšie výhody, o ktorých Bilionbuy informuje členov komunity Bilionbuy prostredníctvom e-mailu alebo webovej platformy.

**5\. Body, sledovacie obdobie a kariérna cesta**

5.1. Za každý nákup získava člen komunity Bilionbuy body. Počet bodov je uvedený pri produkte alebo službe uvedenom na webe Bilionbuy a je rozličný pri každom produkte alebo službe.

5.2. Člen komunity Bilionbuy pre účely získania kariérnych odmien zbiera body v každom mesiaci od nuly. Vždy na začiatku mesiaca sa nazbierané body vynulujú.

5.3. Sledovacie obdobie je obdobie, v ktorom sa sleduje dosiahnutý obrat člena komunity Bilionbuy a všetkých jeho podriadených členov komunity Bilionbuy. 

5.3. Sledovacie obdobie trvá od 1\. dňa do posledného dňa každého mesiaca. Vždy na začiatku mesiaca sa začína od dosiahnutého obratu 0\.

5.4. Do sledovacieho obdobia sa započítava výlučne obrat, ktorý tvoria zaplatené provízie za uskutočnené nákupy za produkty a služby. Nepostačuje, že bola zaplatená čiastka za nákup, musí byť zaplatená provízia do Bilionbuy. Doba trvania zaplatenia provízie do Bilionbuy sa líši od jednotlivých partnerov a táto doba môže byť od 1 do 40 dní.

5.5. Tabuľka kariérnej cesty

![][image1]

5.6. Člen komunity BILIONBUY má nárok na vyplatenie kariérnej odmeny podľa dosiahnutnej pozície v kariérnej ceste podľa počtu získaných bodov v danom mesiaci vynásobených eurovou hodnotou. Pozíciu je možné dosiahnuť historicky nazbieranými bodmi, pričom v tomto prípade patrí členovi táto pozícia navždy alebo splnením obratu za daný mesiac, pričom v tomto prípade patrí členovi táto pozícia v danom mesiaci. V prípade, ak mu časť bodového obratu získal jemu podriadený člen komunity BILIONBUY, v tomto prípade má nadriadený člen komunity BILIONBUY nárok na rozdielovú odmenu eurovej hodnoty.

**6\. Výplatné termíny**

6.1. Cashback odmeny sú vyplácané každý týždeň, vždy v pondelok. 

6.2. Kariérne odmeny sú vyplácané jedenkrát mesačne, do 5\. dňa nasledujúceho mesiaca, za ktorý patrí členovi komunity BILIONBUY kariérna odmena.

**7\. Reklamácie**

7.1. Reklamácie poskytnutých finančných výhod sa uskutočňuje prostredníctvom e-mailovej komunikácie [info@bilionbuy.com](mailto:info@bilionbuy.com) alebo prostredníctvom internej zóny webovej platformy.

7.2. Bilionbuy sa zaväzuje vyriešiť reklamáciu najneskôr do 30 dní odo dňa doloženia všetkých informácií potrebných k reklamácii.

**8\. Záverečné ustanovenia**

8.1. Prevádzkovateľ si vyhradzuje právo, že web Bilionbuy nemusí byť vždy dostupný, najmä kvôli technickým problémom alebo pravidelnej údržbe. Za prípadné škody spôsobené nedostupnosťou, zmenami na webe Bilionbuy nenesie Prevádzkovateľ zodpovednosť.

8.2. Prevádzkovateľ má právo jednostranne upraviť tieto Podmienky v primeranom rozsahu, hlavne za účelom doplnenia konkrétnejšieho znenia podmienok. Člen komunity bude na zmenu Podmienok upozornený minimálne 14 dní pred tým, ako nové Podmienky nadobudnú účinnosť, a to prostredníctvom e-mailu alebo webovej platformy. Člen komunity má možnosť zmeny Podmienok odmietnuť, a to tak, že požiada o zrušenie svojho účtu na webe Bilionbuy alebo prestane web Bilionbuy používať.

8.3. Práva a povinnosti neupravené týmito podmienkami sa riadia právnymi predpismi platnými v štáte, na ktorý sa prevádzkovanie platformy vzťahuje.

8.4. Tieto všeobecné podmienky nadobúdajú účinnosť dňa 19\. 09\. 2024\.

**Podmienky internetového predaja**

**1\. Základné ustanovenia**

1.1. Podmienky internetového predaja upravujú práva a povinnosti zmluvných strán pri uzatváraní kúpnej zmluvy dojednanej na diaľku medzi partnerom prevádzkovateľa a kupujúcim, predmetom ktorej je predaj tovaru alebo služby.

**2\. Definície pojmov**

2.1. Webová platforma \- web www.bilionbuy.com.

2.2. Partnerský internetový obchod \- je internetový obchod partnera Bilionbuy, ktorý je zobrazovaný na webe Bilionbuy na základe osobitnej spolupráce medzi Bilionbuy a partnerom.

2.3. Partner \- prevádzkovateľ internetového obchodu, na ktorého v porovnaní produktov a služieb odkazuje Bilionbuy, pričom samotná objednávka a uzatvorenie kúpnej zmluvy prebieha v internetovom obchode Partnera na vlastný účet a zodpovednosť Partnera.

**3\. Predaj prostredníctvom Bilionbuy**

3.1. Člen komunity Bilionbuy alebo nezaregistrovaný užívateľ je oprávnený vyhľadať produkt, o ktorého kúpu má záujem alebo vyhľadať partnerský internetový obchod, v ktorom chce nakupovať. V tomto prípade sa internetový predaj neuskutočňuje na strane Bilionbuy, ale priamo v partnerskom internetovom obchode, a to v súlade s obchodnými podmienkami partnerského internetového obchodu.

**4\. Záverečné ustanovenia**

4.1. Otázky neupravené týmito podmienkami sa riadia príslušnými platnými právnymi predpismi.

4.2. Tieto podmienky internetového predaja doplňujú Podmienky užívania webu Bilionbuy členom komunity.

4.3. Tieto podmienky internetového predaja nadobúdajú účinnosť dňa 03\. 09\. 2024\.

**Podmienky vkladania príspevkov a recenzií na webovú platformu**

**1\. Základné ustanovenia**

Tieto podmienky vkladania príspevkov a recenzií na webovú platformu Bilionbuy slúžia ako možnosť členov komunity Bilionbuy podieľať sa na obsahu webu prostredníctvom zdieľania vlastných skúsenosti so zakúpenými produktmi alebo službami (tzv. recenzie).

**2\. Zodpovednosť za obsah recenzie**

2.1. Člen komunity Bilionbuy nesie v plnom rozsahu občianskoprávnu a trestnú zodpovednosť za obsah svojej recenzie. 

2.2. Člen komunity Bilionbuy je povinný zdieľať takú recenziu, ktorá je pravdivá a nebola vytvorená za účelom poškodzovania konkurenta v konkurenčnom boji.

**3\. Overovanie obsahu recenzie**

3.1. Na webovej platforme Bilionbuy sa zverejňujú overené, ale aj neoverené recenzie, ktorú sú na webe viditeľne označené ako overené, resp. neoverené.

3.2. Bilionbuy si vyhradzuje právo overovať akúkoľvek recenziu poskytnutú členom komunity Bilionbuy, a za tým účelom je oprávnený žiadať od člena komunity alebo od partnera prevádzkovateľa potrebné dôkazy.

3.3. Podať námietku proti nezákonnému obsahu recenzie, resp. požiadať o overenie zákonnosti obsahu recenzie je oprávnený podať partner prevádzkovateľa formou e-mailovej adresy [partner@bilionbuy.com](mailto:partner@bilionbuy.com), pričom uvedenie o ktorú recenziu ide a dôvody podania námietky.

3.4. Bilionbuy si vyhradzuje právo zmazať neoverené recenzie, vymyslené recenzie alebo recenzie, ktoré z veľkej pravdepodobnosti nie sú skutočné.

**4\. Záverečné ustanovenia**

4.1. Tieto podmienky vkladania príspevkov a recenzií na webovú platformu doplňujú Podmienky užívania webu Bilionbuy členom komunity.

4.2. Tieto podmienky vkladania príspevkov a recenzií na webovú platformu nadobúdajú účinnosť dňa 03\. 09\. 2024\.

**Ochrana osobných údajov**

Prevádzkovateľ:

Bilionbuy International s.r.o.

sídlo: Farbiarska 53/29, 064 01 Stará Ľubovňa

zapísané v OR Mestského súdu Bratislava III, oddiel: Sro, vl.č. 177746/B

štatutárny orgán: Mgr. Štefan Lazorčák, konateľ

Prevádzkovateľ spracováva bežné kategórie osobných údajov ako aj osobitnú kategóriu osobných údajov.

Bežné kategórie osobných údajov, ktoré prevádzkovateľ spracováva sú:  
Meno, priezvisko, podobizeň, dátum narodenia, adresa trvalého pobytu, korešpondenčná adresa, štátnu príslušnosť, IBAN, telefón, e-mail, IP adresa, číslo produktu (zmluvy), údaje o používaných produktoch a službách ako napríklad údaje o využívaných produktoch a službách, údaje súvisiace s vybavovaním Vašich požiadaviek, socio-demografické údaje ako napríklad vek, pohlavie, audiozáznamy ako napríklad nahrávky hovorov uskutočnených prostredníctvom kontaktného centra, údaje súvisiace s používaním našich webových stránok a aplikácií (napríklad cookies), údaje súvisiace so sledovaním našich marketingových kanálov ako napríklad časy otvárania zaslaných správ, na akom zariadení informácie čítate, aké technické parametre a operačný systém má dané zariadenie, údaje súvisiace s plnením vašich zmluvných povinností a záväzkov, údaje vyplývajúce z aktivity na sociálnych sieťach, odvodené údaje vyplývajúce z Vašich nastavení, údajov o produktoch a službách, údajoch o používaní našich webových stránok.

Osobitné kategórie osobných údajov prevádzkovateľ nespracováva.

Prevádzkovateľ spracováva osobné údaje za účelom:

- propagácie prevádzkovateľa, najmä odosielanie newslettera, e-mailové správy, e-maily reklamnej povahy, SMS správy, SMS reklamnej povahy,   
- komunikácie s dotknutou osobou, napr. uskutočňovanie on-line alebo telefonickej komunikácie,  
- evidencie a zverejňovania recenzií a referencií,  
- organizovania podujatí,  
- archivácie a štatistiky,  
- správy cookies,  
- evidencie a realizácie zmluvných vzťahov dotknutej osoby uzatvorených s prevádzkovateľom, jeho obchodnými partnermi alebo s inými subjektami a prípadného vymáhania pohľadávok,  
- získavania, zaznamenávania, zhromažďovania, ukladania osobných údajov do informačného systému, sprístupňovanie, uchovávanie, upravovanie a pozmeňovanie, vyhľadávanie, prehliadanie, odovzdávanie, triedenie a ich kombinovanie za účelom evidencie obchodných výsledkov, veľkosti obchodnej siete a klientskej siete, odmien, evidencie zaevidovaných, resp. zadaných obchodov,  
- sprístupnenia vybraných osobných údajov členom komunity Bilionbuy, ktorí sú v rámci kariéry zaradení ako nadriadení,  
- spracovania a zverejňovania fotografií, na ktorých je vyobrazená podobizeň člena komunity Bilionbuy, a to za účelom marketingovej činnosti, propagačnej činnosti a zverejnenie podobizne v osobnom profile vo webovej platforme,  
- identifikácie a overenia identifikácie klientov a ich zástupcov,  
- uzatvárania a plnenia zmluvných vzťahov vrátane predzmluvných vzťahov,  
- vzťahového manažmentu,  
- správy zmluvných vzťahov vrátane vykonávania zmien a ich ukončenia,  
- prijímania a vybavovanie podnetov a sťažností klientov, resp. členov komunity Bilionbuy,  
- zdokumentovania a zabezpečenia činností členov komunity Bilionbuy vzhľadom na plnenie záväzkov vyplývajúcich z uzatvorených zmluvných vzťahov v súlade s ustanoveniami osobitných predpisov,  
- plnenia povinností vyplývajúcich členom Bilionbuy zo zákona č. 297/2008 Z. z. o ochrane pred legalizáciou príjmov z trestnej činnosti a o ochrane pred financovaním terorizmu,  
- ďalšie činnosti súvisiace s plnením si úloh a povinností Bilionbuy podľa platných právnych predpisov,  
- marketing pri poskytovaní produktov a služieb,  
- spotrebiteľské súťaže (vyhodnotenie a určenie výhercov a súťažiacich),  
- súťaže členov komunity Bilionbuy (vyhodnotenie a určenie výhercov a súťažiacich).  


Právny základ spracúvania osobných údajov pre vyššie uvedené účely je skutočnosť, že spracúvanie osobných údajov je nevyhnutné na plnenie zmluvy, ktorej zmluvnou stranou je dotknutá osoba. Všetky dotknuté účely sú nevyhnutné na účely plnenia zmluvy, avšak pre prípad akýchkoľvek pochybností, týmto dotknutá osoba vyjadruje súhlas na spracúvanie osobných údajov na vyššie uvedené účely. Toto spracúvanie osobných údajov je odvolateľné len v prípade ukončenia zmluvného vzťahu s prevádzkovateľom. Zmluva, o ktorú sa naše spracovanie opiera vzniká zadaním vašich údajov prostredníctvom registrácie alebo v internej zóne.

Osobné údaje sú poskytované v súvislosti s vyššie uvedenými účelmi iba oprávneným osobám u prevádzkovateľa, ktoré osobné údaje spracúvajú z dôvodu spracúvania na základe vyššie uvedených účelov, a tiež sprostredkovateľom, ktorí spracúvajú osobné údaje v mene prevádzkovateľa, a to na základe sprostredkovateľskej zmluvy. 

Osobné údaje dotknutej osoby prevádzkovateľ v súvislosti s vyššie uvedenými účelmi uchováva po dobu určenú osobitnými predpismi, v ostatných prípadoch po dobu trvania zmluvného vzťahu.

Prenos osobných údajov do tretej krajiny v súvislosti s vyššie uvedenými účelmi sa neuskutočňuje.

Poskytovanie osobných údajov v súvislosti s vyššie uvedenými účelmi je zákonnou požiadavkou.

Prevádzkovateľ informuje dotknutú osobu, že v súvislosti s vyššie uvedenými účelmi vykonáva automatizované rozhodovanie a profilovanie. V rámci toho prostredníctvom technickej operácie zisťuje, o čo používateľ na digitálnom mieste prejavil záujem, a na základe toho ďalej zisťuje, či bol záujem premietnutý v obchod, a ak nie, tak mu do budúcna zasiela informácie digitálnou formou o možnom uzatvorení obchodu (napr. formou newsletteru, obchodnej resp. reklamnej ponuky formou e-mailu alebo SMS a pod.). Táto činnosť sa vykonáva v záujme zabezpečenia lepšieho používateľského zážitku a z pohľadu dotknutej osoby okrem ponuky produktov a služieb nepredstavuje pre ňu žiadne iné nebezpečenstvo ani následky.

Medzi práva dotknutej osoby patria:  
Právo na prístup k svojim osobným údajom  
Právo na opravu svojich osobných údajov  
Právo na výmaz svojich osobných údajov (právo „na zabudnutie“)  
Právo na obmedzenie spracúvania svojich osobných údajov  
Právo namietať proti spracúvaniu svojich osobných údajov  
Právo na prenosnosť svojich osobných údajov  
Právo podať sťažnosť dozornému orgánu, t.j. Úradu na ochranu osobných údajov SR  
Právo namietať automatizované individuálne rozhodovanie a profilovanie   
Právo podať návrh na začatie konania podľa § 100 zákona o ochrane osobných údajov  
Právo na odvolanie súhlasu s poskytnutím osobných údajov  
Kontaktné údaje zodpovednej osoby:   
Mgr. Štefan Lazorčák  
e-mail: info@bilionbuy.com

[image1]: https://bbuy.fra1.cdn.digitaloceanspaces.com/terms/terms_sk.png
`,
    cs: `
    **Podmínky Společenství Bilionbuy**

- Podmínky používání pro členy komunity Bilionbuy 
- Podmínky online prodeje 
- Podmínky odpovědnosti za obsah příspěvků a recenzí 
- Zásady ochrany osobních údajů Bilionbuy

**Podmínky používání stránek člena komunity Bilionbuy**

**1\. Základní ustanovení**

1.1 Bilionbuy je webová platforma, která zajišťuje online prodej vlastních i partnerských produktů a služeb a zároveň umožňuje registrovaným členům komunity získávat finanční a jiné výhody ve formě cashbacku nebo odměn.

1.2 Tyto podmínky upravují pravidla používání webové platformy Bilionbuy, pravidla pro vstup do komunity Bilionbuy a z toho plynoucí výhody pro registrované členy komunity Bilionbuy.

**2\. Definice**

2.1 Webová platforma \- webová stránka [www.bilionbuy.com](http://www.bilionbuy.com).

2.2 Provozovatel \- provozovatelem webové platformy je společnost Bilionbuy International s.r.o., IČO: 56190999, se sídlem Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3 Člen komunity \- registrací na webové platformě se registrovaný automaticky stává členem komunity Bilionbuy.

2.4 Obchodní síť \- obchodní síť člena komunity Bilionbuy zahrnuje všechny přímo i nepřímo registrované přátele, kteří byli do komunity Bilionbuy pozváni přímo členem komunity nebo nepřímo registrovanými přáteli pozvanými členem komunity.

2.5 Přímo pozvaný přítel \- přítel, který byl do Bilionbuy pozván členem komunity Bilionbuy přímo prostřednictvím jeho vlastní aktivity, zasláním pozvánky prostřednictvím webové platformy nebo zasláním doporučujícího odkazu. 

2.6 Nepřímo pozvaný přítel \- přítel, který byl pozván na Bilionbuy jedním z přátel pozvaných členem komunity Bilionbuy nebo jedním z jeho přátel.

**3\. Registrace a pozvání do komunity Bilionbuy**

3.1 Registrace probíhá na webové platformě. 

3.2 Registrace je možná na základě pozvání (doporučení) od přítele nebo přímo registrací na webové platformě bez doporučení.

3.3 Registrace pozváním probíhá zasláním odkazu na doporučení nebo přímo ve webové platformě zasláním pozvánky prostřednictvím funkce „Pozvat přítele“.

3.4 Registrací se registrovaný automaticky stává členem komunity Bilionbuy, aniž by byl povinen platit jakékoli poplatky za členství v komunitě Bilionbuy.

3.5 Registrací na webové platformě získává člen komunity výhody členství v komunitě Bilionbuy.

**4\. Výhody členství v komunitě Bilionbuy**

4.1 Společnost Bilionbuy neukládá žádné omezující podmínky pro získání výhod. Nejsou stanoveny žádné minimální nákupy ani minimální aktivity. Finanční výhody jsou ovlivněny obratem dosažených nákupů.

4.2 Člen komunity Bilionbuy má za členství v komunitě Bilionbuy nárok na následující výhody. 

4.3 Cashback za vlastní nákupy \- výše cashbacku za vlastní nákupy je uvedena u internetového obchodu, konkrétního výrobku nebo služby uvedené na webové platformě Bilionbuy a je pro každý internetový obchod, výrobek nebo službu jiná.

4.4 Cashback za přímé doporučení \- je forma cashbacku stanovená za nákupy uskutečněné přímo pozvanými přáteli. Výše přímého doporučeného cashbacku je uvedena u internetového obchodu, produktu nebo služby uvedených na webové platformě Bilionbuy a je pro každý internetový obchod, produkt nebo službu jiná.

4.5 Kariérní odměny \- je forma odměny stanovená za doporučování a budování komunity Bilionbuy podle bodů získaných za nákupy v obchodní síti člena komunity Bilionbuy. Podmínky pro dosažení kariérních pozic a výši odměn za tyto pozice naleznete v části „Kariérní cesta“.

4.6 Cílový bonus \- je forma odměny stanovená pro člena komunity Bilionbuy v případě, že jím přímo pozvaný člen komunity Bilionbuy dosáhne v daném měsíci kariérní pozice Partner. V takovém případě je člen komunity zařazen do skupiny členů komunity Bilionbuy, kterým je cílový bonus vyplácen rovným dílem. Cílový bonus je částka tvořená 1 % z obratu společnosti Bilionbuy bez DPH.

4.7 Další zvláštní výhody \- jedná se o další výhody, o kterých Bilionbuy informuje členy komunity Bilionbuy prostřednictvím e-mailu nebo webové platformy.

**5\. Body, období sledování a kariérní postup**

5.1 Za každý nákup získává člen komunity Bilionbuy body. Počet bodů je uveden u výrobku nebo služby uvedených na webových stránkách Bilionbuy a liší se u každého výrobku nebo služby.

5.2 Pro účely získání odměn za kariéru sbírá člen komunity Bilionbuy body v každém měsíci počínaje nulou. Na začátku každého měsíce se nasbírané body vynulují.

5.3 Sledované období je období, ve kterém se sleduje obrat dosažený členem komunity Bilionbuy a všemi jeho podřízenými členy komunity Bilionbuy. 

5.3 Sledované období trvá od 1. do posledního dne každého měsíce. Začíná vždy na začátku měsíce od 0\ dosaženého obratu.

5.4 Do sledovaného období se započítává pouze obrat tvořený provizemi vyplacenými za uskutečněné nákupy produktů a služeb. Nestačí, že částka za nákup byla zaplacena, provize musí být vyplacena společnosti Bilionbuy. Doba výplaty provize společnosti Bilionbuy se u jednotlivých partnerů liší a tato doba může být od 1 do 40 dnů.

5.5 Tabulka kariérního postupu

![][obrázek1]

5.6 Člen komunity BILIONBUY má nárok na kariérní odměnu podle dosažené pozice v kariérní dráze podle počtu bodů získaných v daném měsíci vynásobeného hodnotou v eurech. Pozice může být dosažena na základě historicky nasbíraných bodů, v takovém případě má člen nárok na pozici navždy, nebo dokončením obratu za daný měsíc, v takovém případě má člen nárok na pozici v daném měsíci. V případě, že část bodového obratu získal jemu podřízený člen společenství BILIONBUY, má v tomto případě nadřízený člen společenství BILIONBUY nárok na rozdílovou odměnu v hodnotě eura.

**6\. Platební podmínky**

6.1 Cashback odměny jsou vypláceny týdně, vždy v pondělí. 

6.2 Kariérní odměny jsou vypláceny jednou měsíčně, a to do 5. dne následujícího měsíce, za který členu komunity BILIONBUY náleží kariérní odměna.

**7\. Reklamace**

7.1 Reklamace poskytnutých finančních odměn se podávají prostřednictvím e-mailové komunikace [info@bilionbuy.com](mailto:info@bilionbuy.com) nebo prostřednictvím interní oblasti webové platformy.

7.2 Společnost Bilionbuy se zavazuje vyřešit reklamaci do 30 dnů ode dne doložení všech informací potřebných k reklamaci.

**8\. Závěrečná ustanovení**

8.1 Provozovatel si vyhrazuje právo, že webové stránky Bilionbuy nemusí být vždy dostupné, zejména z důvodu technických problémů nebo pravidelné údržby. Provozovatel neodpovídá za případné škody způsobené nedostupností, změnami na webových stránkách Bilionbuy.

8.2 Provozovatel má právo jednostranně měnit tyto obchodní podmínky v přiměřeném rozsahu, zejména za účelem doplnění konkrétnějšího znění obchodních podmínek. O změně Podmínek bude Člen komunity informován nejméně 14 dní před nabytím účinnosti nových Podmínek, a to buď e-mailem, nebo prostřednictvím webové platformy. Člen komunity má možnost změny Podmínek odmítnout tím, že požádá o zrušení svého účtu Bilionbuy nebo přestane používat webové stránky Bilionbuy.

8.3 Práva a povinnosti neupravené těmito Podmínkami se řídí zákony platnými v zemi, na kterou se Platforma vztahuje.

8.4 Tyto všeobecné obchodní podmínky vstupují v platnost dnem 19\. 09\. 2024\.

**Všeobecné obchodní podmínky pro online prodej**

**1\. Základní ustanovení**

1.1 Obchodní podmínky internetového prodeje upravují práva a povinnosti smluvních stran při uzavírání kupní smlouvy sjednané na dálku mezi partnerem provozovatele a kupujícím, jejímž předmětem je prodej zboží nebo služeb.

**2\. Definice**

2.1. Webová platforma \- webové stránky www.bilionbuy.com.

2.2. Partnerský internetový obchod \- je internetový obchod partnera Bilionbuy, který je zobrazen na webových stránkách Bilionbuy na základě zvláštní spolupráce mezi Bilionbuy a partnerem.

2.3 Partner \- provozovatel internetového obchodu, na kterého Bilionbuy odkazuje při porovnávání produktů a služeb, přičemž vlastní objednávka a uzavření kupní smlouvy probíhá v internetovém obchodě partnera na jeho vlastní účet a odpovědnost.

**3\. Prodej prostřednictvím Bilionbuy**

3.1 Člen komunity Bilionbuy nebo neregistrovaný uživatel je oprávněn vyhledat produkt, o jehož koupi má zájem, nebo vyhledat internetový obchod Partnera, ve kterém chce nakoupit. V takovém případě neprobíhá online prodej na stránkách Bilionbuy, ale přímo v partnerském online obchodě, a to v souladu s podmínkami partnerského online obchodu.

**4\. Závěrečná ustanovení**

4.1 Záležitosti neupravené těmito obchodními podmínkami se řídí příslušnými platnými právními předpisy.

4.2 Tyto obchodní podmínky online prodeje doplňují podmínky používání pro členy komunity Bilionbuy.

4.3 Tyto podmínky internetového prodeje nabývají účinnosti dne 03**. 09\. 2024\.

**Podmínky pro vkládání příspěvků a recenzí na internetovou platformu**

**1\. Základní ustanovení**

Tyto podmínky pro vkládání příspěvků a recenzí na webovou platformu Bilionbuy jsou určeny jako příležitost pro členy komunity Bilionbuy podílet se na obsahu webových stránek sdílením vlastních zkušeností se zakoupenými produkty nebo službami (tzv. recenze).

**2\. Odpovědnost za obsah recenzí**

2.1 Člen komunity Bilionbuy nese plnou občanskoprávní a trestněprávní odpovědnost za obsah své recenze. 

2.2 Člen komunity Bilionbuy je povinen sdílet recenzi, která je pravdivá a nebyla vytvořena za účelem poškození konkurenta v konkurenčním boji.

**3\. Ověření obsahu recenze**

3.1 Ověřené i neověřené recenze jsou zveřejňovány na webové platformě Bilionbuy a jsou na webu viditelně označeny jako ověřené nebo neověřené.

3.2 Společnost Bilionbuy si vyhrazuje právo ověřit jakoukoli recenzi poskytnutou členem komunity Bilionbuy a za tímto účelem je oprávněna vyžádat si od člena komunity nebo partnera provozovatele potřebné důkazy.

3.3 Partner provozovatele je oprávněn vznést námitku proti nezákonnému obsahu recenze nebo požádat o ověření zákonnosti obsahu recenze zasláním e-mailu na adresu [partner@bilionbuy.com](mailto:partner@bilionbuy.com), v němž uvede, o jakou recenzi se jedná, a důvody námitky.

3.4 Společnost Bilionbuy si vyhrazuje právo smazat neověřené recenze, vymyšlené recenze nebo recenze, u nichž je vysoce nepravděpodobné, že jsou pravé.

**4\. Závěrečná ustanovení**

4.1 Tyto podmínky pro vkládání příspěvků a recenzí na webovou platformu doplňují podmínky používání pro členy komunity Bilionbuy.

4.2 Tyto podmínky pro nahrávání příspěvků a recenzí na webovou platformu nabývají účinnosti dne 03.03.2018. 09\. 2024\.

**Zásady ochrany osobních údajů**

Provozovatel: Společnost Bilibuy Online, s.r.o., se sídlem v Praze, ul:

Bilionbuy International Ltd.

Sídlo: Bilibuybu, s. r. o: Farbiarska 53/29, 064 01 Stará Ľubovňa.

zapsaná u Městského soudu Bratislava III, oddíl: Sro, vložka č. 177746/B

Statutární orgán: Městský soud v Brně. Štefan Lazorčák, jednatel společnosti: Mgr.

Správce zpracovává běžné kategorie osobních údajů i zvláštní kategorie osobních údajů.

Běžné kategorie osobních údajů zpracovávané správcem jsou: - osobní údaje: 
Jméno, příjmení, podoba, datum narození, adresa trvalého bydliště, korespondenční adresa, státní příslušnost, IBAN, telefon, e-mail, IP adresa, číslo produktu (smlouvy), údaje o využívaných produktech a službách, jako jsou údaje o využívaných produktech a službách, údaje související s vyřizováním vašich požadavků, sociodemografické údaje, jako je věk, pohlaví, zvukové záznamy, jako jsou záznamy hovorů uskutečněných prostřednictvím kontaktního centra, údaje související s používáním našich webových stránek a aplikací (např. soubory cookie), údaje související se sledováním našich marketingových kanálů, jako je doba otevření zaslaných zpráv, na jakém zařízení jste si informace přečetli, jaké má zařízení technické parametry a operační systém, údaje související s plněním vašich smluvních povinností a závazků, údaje vyplývající z aktivity na sociálních sítích, odvozené údaje vyplývající z vašeho nastavení, údaje o produktech a službách, údaje o používání našich webových stránek.

Zvláštní kategorie osobních údajů správce nezpracovává.

Správce zpracovává osobní údaje za účelem:

- Propagace správce, zejména zasílání newsletterů, e-mailových zpráv, e-mailů reklamního charakteru, SMS zpráv, SMS zpráv reklamního charakteru, 
- komunikace se subjektem údajů, např. provádění online nebo telefonické komunikace, 
- zaznamenávání a zveřejňování recenzí a posudků, 
- pořádání akcí, 
- archivace a statistiky, 
- správa souborů cookie, 
- evidence a realizace smluvních vztahů subjektu údajů se správcem, jeho obchodními partnery nebo s jinými subjekty a případné vymáhání pohledávek, 
- získávání, zaznamenávání, shromažďování, uchovávání osobních údajů v informačním systému, přístup k nim, jejich ukládání, úprava a pozměňování, vyhledávání, prohlížení, předávání, třídění a kombinování za účelem evidence obchodních výsledků, velikosti obchodní sítě a klientské sítě, odměn, evidence registrovaných nebo objednaných transakcí, 
- zpřístupnění vybraných osobních údajů členům komunity Bilionbuy, kteří jsou ve své kariéře zařazeni jako nadřízení, 
- zpracování a zveřejnění fotografií zobrazujících podobiznu člena komunity Bilionbuy pro účely marketingových a propagačních aktivit a zveřejnění podobizny v osobním profilu na webové platformě, 
- identifikace a ověřování totožnosti klientů a jejich zástupců, 
- uzavírání a plnění smluvních vztahů, včetně předsmluvních vztahů, 
- řízení vztahů, 
- správu smluvních vztahů, včetně provádění jejich změn a ukončování, 
- přijímání a vyřizování stížností a reklamací klientů nebo členů komunity Bilionbuy, 
- dokumentování a zajišťování činnosti členů společenství Bilionbuy v souvislosti s plněním povinností vyplývajících z uzavřených smluvních vztahů v souladu s ustanoveními zvláštních předpisů, 
- plnění povinností vyplývajících pro členy společenství Bilionbuy ze zákona č. 297/2008 Sb. o ochraně před legalizací výnosů z trestné činnosti a o ochraně před financováním terorismu, 
- další činnosti související s plněním úkolů a povinností Bilionbuy podle platných právních předpisů, 
- marketing při poskytování produktů a služeb, 
- spotřebitelské soutěže (vyhodnocení a určení výherců a soutěžících), 
- soutěže pro členy komunity Bilionbuy (hodnocení a určování výherců a soutěžících). 


Právním základem pro zpracování osobních údajů pro výše uvedené účely je skutečnost, že zpracování osobních údajů je nezbytné pro plnění smlouvy, jejíž smluvní stranou je subjekt údajů. Všechny uvedené účely jsou nezbytné pro plnění smlouvy, avšak v případě jakýchkoli pochybností subjekt údajů tímto dává souhlas se zpracováním osobních údajů pro výše uvedené účely. Toto zpracování osobních údajů je odvolatelné pouze v případě ukončení smluvního vztahu se správcem. Smlouva, na jejímž základě zpracování provádíme, vzniká zadáním údajů prostřednictvím registrace nebo ve vnitřní zóně.

Osobní údaje jsou v souvislosti s výše uvedenými účely poskytovány pouze oprávněným osobám u správce, které zpracovávají osobní údaje na základě zpracování pro výše uvedené účely, a dále zpracovatelům, kteří zpracovávají osobní údaje jménem správce, a to na základě zprostředkovatelské smlouvy.

Osobní údaje subjektu údajů uchovává správce v souvislosti s výše uvedenými účely po dobu stanovenou zvláštními předpisy, v ostatních případech po dobu trvání smluvního vztahu.

Osobní údaje se v souvislosti s výše uvedenými účely nepředávají do třetí země.

Poskytnutí osobních údajů v souvislosti s výše uvedenými účely je zákonným požadavkem.

Správce informuje subjekt údajů, že v souvislosti s výše uvedenými účely provádí automatizované rozhodování a profilování. V rámci toho pomocí technické operace zjišťuje, o co uživatel na digitálních stránkách projevil zájem, a na základě toho dále zjišťuje, zda se zájem promítl do transakce, a pokud ne, zasílá mu v budoucnu v digitální podobě informace o možném uzavření transakce (např. formou newsletteru, obchodní nebo reklamní nabídky ve formě e-mailu nebo SMS apod.) Děje se tak v zájmu zajištění lepšího uživatelského komfortu a nepředstavuje pro subjekt údajů žádná jiná rizika ani důsledky než nabídku produktů a služeb.

Mezi práva subjektu údajů patří např: 
právo na přístup ke svým osobním údajům 
právo na opravu svých osobních údajů 
právo na výmaz svých osobních údajů (právo být zapomenut) 
právo na omezení zpracování svých osobních údajů 
právo vznést námitku proti zpracování svých osobních údajů 
Právo na přenositelnost svých osobních údajů 
Právo podat stížnost u dozorového úřadu, tj. u Úřadu pro ochranu osobních údajů Slovenské republiky. 
Právo vznést námitku proti automatizovanému individuálnímu rozhodování a profilování. 
Právo podat žalobu podle § 100 zákona o ochraně osobních údajů. 
Právo odvolat souhlas se zveřejněním osobních údajů 
Kontaktní údaje odpovědné osoby: 
Kontaktní osoba: Mgr. Štefan Lazorčák 
E-mail: info@bilionbuy.com

[obrázek1]: https://bbuy.fra1.cdn.digitaloceanspaces.com/terms/terms_cs.png
    `,
    en: `

**Bilionbuy Community Terms**

- Bilionbuy Community Member Terms of Use 
- Terms and conditions of online sales 
- Terms of responsibility for the content of posts and reviews 
- Bilionbuy Privacy Policy

**Terms and Conditions of Use of the Bilionbuy Community Member Site**

**1\. Basic Provisions**

1.1 Bilionbuy is a web platform providing online sales of its own and partner products and services, while allowing registered community members to earn financial and other benefits in the form of cashback or rewards.

1.2 These terms and conditions govern the rules for the use of the Bilionbuy web platform, the rules for joining the Bilionbuy community, and the resulting benefits for registered members of the Bilionbuy community.

**2\. Definitions**

2.1 Web Platform \- website [www.bilionbuy.com](http://www.bilionbuy.com).

2.2 Operator \- the operator of the web platform is the company Bilionbuy International s.r.o., ID No.: 56190999, with registered office at Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3 Community member \- by registering on the web platform, the registered automatically becomes a member of the Bilionbuy community.

2.4 Business network \- the business network of a Bilionbuy community member includes all directly and indirectly registered friends who have been invited to the Bilionbuy community directly by the community member or indirectly by the registered friends invited by the community member.

2.5 Directly invited friend \- a friend who has been invited to Bilionbuy by a Bilionbuy Community Member directly through his/her own activity, by sending an invitation via the web platform or by sending a referral link. 

2.6. Indirectly invited friend \- a friend who has been invited to Bilionbuy by one of the friends invited by a Bilionbuy Community Member or one of their friends.

**3\. Registration and invitation to the Bilionbuy community**

3.1 Registration takes place on the web platform. 

3.2 Registration is possible by invitation (referral) from a friend or directly by registering on the web platform without a referrer.

3.3 Registration by invitation is done by sending a referral link or directly in the web platform by sending an invitation via the "Invite a friend" function.

3.4 By registering, the registrant automatically becomes a member of the Bilionbuy community without being obliged to pay any fees for membership of the Bilionbuy community.

3.5 By registering on the Web Platform, the Community Member receives the benefits of membership of the Bilionbuy Community.

**4\. Benefits of Bilionbuy Community Membership**

4.1 Bilionbuy does not impose any restrictive conditions to receive benefits. There are no minimum purchases or minimum activities. Financial benefits are influenced by the turnover of purchases achieved.

4.2 A member of the Bilionbuy community is entitled to the following benefits for being a member of the Bilionbuy community. 

4.3 Cashback on own purchases \- the amount of cashback on own purchases is indicated for the online shop, specific product or service listed on the Bilionbuy web platform and is different for each online shop, product or service.

4.4 Direct referral cashback \- is a form of cashback set for purchases made directly by invited friends. The amount of direct referral cashback is indicated for the online shop, product or service listed on the Bilionbuy web platform and is different for each online shop, product or service.

4.5 Career Rewards \- is a form of reward set for recommending and building the Bilionbuy community according to the points earned for purchases of the Bilionbuy community member's merchant network. Please refer to the "Career Path" section for the conditions for achieving Career Positions and the amount of rewards for these positions.

4.6 Target bonus \- is a form of reward set for a Bilionbuy Community Member in case a Bilionbuy Community Member directly invited by him/her reaches the Partner career position in a given month. In this case, the community member is included in the group of Bilionbuy community members to whom the target bonus is paid equally. The target bonus is an amount consisting of 1% of Bilionbuy's turnover excluding VAT.

4.7 Other special benefits \- these are additional benefits that Bilionbuy informs the members of the Bilionbuy community about via email or the web platform.

**5\. Points, tracking period and career path**

5.1 For each purchase, a Bilionbuy community member earns points. The number of points is indicated for the product or service listed on the Bilionbuy website and varies for each product or service.

5.2 For the purpose of earning career rewards, a Bilionbuy Community Member accumulates points in each month starting from zero. At the beginning of each month, the accumulated points are reset to zero.

5.3 The tracking period is the period in which the turnover achieved by the Bilionbuy Community Member and all his subordinate Bilionbuy Community Members is tracked. 

5.3 The tracking period runs from the 1st to the last day of each month. It always starts at the beginning of the month from the 0\ turnover achieved.

5.4 The tracking period shall only include turnover made up of commissions paid for purchases made for products and services. It is not sufficient that the amount of the purchase has been paid, the commission must be paid to Bilionbuy. The duration of the commission payment to Bilionbuy varies from partner to partner and this period can be from 1 to 40 days.

5.5 Career path table

![][image1]

5.6 A member of the BILIONBUY community is entitled to receive a career reward according to the position achieved in the career path according to the number of points earned in a given month multiplied by the euro value. The position can be achieved by points historically accumulated, in which case the member is entitled to the position forever, or by completing the turnover for the month, in which case the member is entitled to the position in that month. In the event that a part of the points turnover has been earned by a BILIONBUY community member subordinate to him/her, in this case the BILIONBUY community member superior is entitled to a differential reward of a euro value.

**6\. Payment terms**

6.1 Cashback rewards are paid weekly, always on Mondays. 

6.2 Career Rewards are paid once a month, by the 5\th day of the following month for which the BILIONBUY Community Member is due a Career Reward.

**7\. Claims**

7.1 Complaints about the financial benefits granted are made via email communication [info@bilionbuy.com](mailto:info@bilionbuy.com) or via the internal area of the web platform.

7.2 Bilionbuy undertakes to resolve the complaint within 30 days from the date of documentation of all the information necessary for the complaint.

**8\. Final provisions**

8.1 The Operator reserves the right that the Bilionbuy website may not always be available, in particular due to technical problems or regular maintenance. The Operator shall not be liable for any damages caused by unavailability, changes to the Bilionbuy website.

8.2 The Operator has the right to unilaterally modify these Terms and Conditions to a reasonable extent, in particular in order to supplement the more specific wording of the Terms and Conditions. The Community Member will be notified of the change to the Terms at least 14 days before the new Terms come into effect, either by email or via the Web Platform. The Community Member has the option to reject the changes to the Terms by requesting the cancellation of his/her Bilionbuy account or by ceasing to use the Bilionbuy website.

8.3 The rights and obligations not governed by these Terms shall be governed by the laws in force in the country to which the Platform applies.

8.4 These general terms and conditions come into force on 19\. 09\. 2024\.

**Terms and Conditions of Online Sale**

**1\. Basic provisions**

1.1 The Terms and Conditions of Internet Sales regulate the rights and obligations of the contracting parties when concluding a purchase contract negotiated remotely between the operator's partner and the buyer, the subject of which is the sale of goods or services.

**2\. Definitions**

2.1. web platform \- website www.bilionbuy.com.

2.2 Partner online shop \- is the online shop of a Bilionbuy partner, which is displayed on the Bilionbuy website on the basis of a special cooperation between Bilionbuy and the partner.

2.3 Partner \- the operator of the online shop to which Bilionbuy refers in the comparison of products and services, whereby the actual ordering and conclusion of the purchase contract takes place in the Partner's online shop on the Partner's own account and responsibility.

**3\. Sales through Bilionbuy**

3.1 A member of the Bilionbuy community or an unregistered user is entitled to search for the product he/she is interested in purchasing or to search for the Partner online shop where he/she wants to buy. In this case, the online sale does not take place on the Bilionbuy site, but directly in the partner online shop, in accordance with the terms and conditions of the partner online shop.

**4\. Final provisions**

4.1 Matters not covered by these terms and conditions shall be governed by the relevant applicable legislation.

4.2 These terms and conditions of online sale supplement the Bilionbuy Community Member Terms of Use.

4.3 These Terms and Conditions of Internet Sale shall come into force on 03**. 09\. 2024\.

**Terms and Conditions for uploading posts and reviews to the web platform**

**1\. Basic Provisions**

These terms and conditions for uploading posts and reviews to the Bilionbuy web platform are intended as an opportunity for members of the Bilionbuy community to participate in the content of the website by sharing their own experiences with the products or services they have purchased (called reviews).

**2\. Responsibility for review content**

2.1 A member of the Bilionbuy Community is fully civilly and criminally liable for the content of his/her review. 

2.2 A member of the Bilionbuy community is obliged to share a review that is truthful and has not been created for the purpose of harming a competitor in a competitive battle.

**3\. Verification of the content of the review**

3.1 Both verified and unverified reviews are published on the Bilionbuy web platform and are visibly marked as verified or unverified on the site.

3.2 Bilionbuy reserves the right to verify any review provided by a member of the Bilionbuy community, and for this purpose, Bilionbuy is entitled to request the necessary evidence from the community member or the operator's partner.

3.3 The operator's partner is entitled to object to the illegal content of a review or to request verification of the legality of the content of a review by sending an e-mail to [partner@bilionbuy.com](mailto:partner@bilionbuy.com), indicating which review is in question and the reasons for the objection.

3.4 Bilionbuy reserves the right to delete unverified reviews, fabricated reviews or reviews that are highly unlikely to be genuine.

**4\. Final provisions**

4.1 These terms and conditions for uploading posts and reviews to the web platform are in addition to the Bilionbuy Community Member Terms of Use.

4.2 These Terms and Conditions for uploading posts and reviews to the Web Platform shall come into effect on 03/03/2018. 09\. 2024\.

**Privacy Policy**

Operator:

Bilionbuy International Ltd.

Registered office: Farbiarska 53/29, 064 01 Stará Ľubovňa

registered in the Municipal Court of Bratislava III, Section: Sro, File No. 177746/B

Statutory body. Mgr. Štefan Lazorčák, Managing Director

The controller processes common categories of personal data as well as special categories of personal data.

Common categories of personal data processed by the controller are: 
Name, surname, likeness, date of birth, permanent address, correspondence address, nationality, IBAN, telephone, e-mail, IP address, product (contract) number, data on products and services used, such as data on products and services used, data related to the handling of your requests, socio-demographic data such as age, gender, audio recordings such as recordings of calls made through the contact centre, data related to the use of our websites and applications (such as cookies), data related to the tracking of our marketing channels such as the opening times of the messages sent, on which device you read the information, what technical parameters and operating system the device has, data related to the fulfilment of your contractual obligations and commitments, data resulting from activity on social networks, derived data resulting from your settings, data on products and services, data on the use of our websites.

Special categories of personal data are not processed by the controller.

The controller processes personal data in order to:

- Promotion of the controller, in particular sending newsletters, e-mail messages, e-mails of an advertising nature, SMS messages, SMS messages of an advertising nature, 
- communication with the data subject, e.g. carrying out online or telephone communication, 
- recording and publishing reviews and testimonials, 
- organising events, 
- archiving and statistics, 
- cookie management, 
- the registration and implementation of contractual relations of the data subject with the controller, its business partners or with other entities and the possible recovery of claims, 
- obtaining, recording, collecting, storing personal data in the information system, accessing, storing, modifying and altering, searching, browsing, transmitting, sorting and combining them for the purpose of recording business results, the size of the business network and the client network, rewards, registering registered or commissioned transactions, 
- disclosure of selected personal data to members of the Bilionbuy community who are classified as supervisors in their careers, 
- the processing and publication of photographs depicting the likeness of a Bilionbuy community member for the purposes of marketing and promotional activities and the publication of the likeness in a personal profile on the web platform, 
- identifying and verifying the identity of clients and their representatives, 
- entering into and performing contractual relationships, including pre-contractual relationships, 
- relationship management, 
- managing contractual relationships, including making changes and terminating them, 
- receiving and handling complaints and complaints from clients or members of the Bilionbuy community, 
- documenting and ensuring the activities of members of the Bilionbuy community with regard to the fulfilment of the obligations arising from the contractual relationships entered into, in accordance with the provisions of the specific regulations, 
- the fulfilment of the obligations arising for Bilionbuy members from Act No 297/2008 Coll. on the protection against the legalisation of the proceeds from crime and the protection against the financing of terrorism, 
- other activities related to the fulfilment of Bilionbuy's tasks and obligations under applicable legislation, 
- marketing in the provision of products and services, 
- consumer competitions (evaluation and determination of winners and contestants), 
- competitions for members of the Bilionbuy community (evaluation and determination of winners and contestants). 


The legal basis for the processing of personal data for the above purposes is the fact that the processing of personal data is necessary for the performance of a contract to which the data subject is a party. All the purposes in question are necessary for the performance of the contract, however, in case of any doubt, the data subject hereby consents to the processing of personal data for the above purposes. This processing of personal data is revocable only in the event of termination of the contractual relationship with the controller. The contract on which our processing is based is created by entering your data via registration or in the internal zone.

Personal data is only provided in connection with the above purposes to authorised persons at the controller who process the personal data on the basis of the processing for the above purposes, and also to processors who process personal data on behalf of the controller, on the basis of an intermediary contract.

The personal data of the data subject shall be retained by the controller in connection with the above-mentioned purposes for the period specified by specific regulations, in other cases for the duration of the contractual relationship.

The personal data shall not be transferred to a third country in connection with the above-mentioned purposes.

The provision of personal data in connection with the above purposes is a legal requirement.

The controller informs the data subject that it carries out automated decision-making and profiling in connection with the aforementioned purposes. Within the framework of this, he or she ascertains by means of a technical operation what the user has expressed an interest in on the digital site, and on the basis of this, he or she further ascertains whether the interest has been reflected in a transaction and, if not, sends him or her information in the future in digital form about a possible conclusion of a transaction (e.g. in the form of a newsletter, a commercial or promotional offer in the form of an e-mail or an SMS, etc.). This is done in the interest of providing a better user experience and does not pose any risks or consequences for the data subject other than the offer of products and services.

The rights of the data subject include: 
The right to access their personal data 
The right to rectification of their personal data 
The right to erasure of his or her personal data (right to be forgotten) 
The right to restrict the processing of their personal data 
The right to object to the processing of their personal data 
The right to portability of your personal data 
The right to lodge a complaint with the supervisory authority, i.e. the Office for Personal Data Protection of the Slovak Republic 
The right to object to automated individual decision-making and profiling 
The right to bring an action pursuant to Section 100 of the Personal Data Protection Act 
Right to withdraw consent to the disclosure of personal data 
Contact details of the responsible person: 
Mgr. Mgr. Štefan Lazorčák 
E-mail: info@bilionbuy.com

[image1]: https://bbuy.fra1.cdn.digitaloceanspaces.com/terms/terms_en.png
`,
    pl: `
    ** Regulamin społeczności Bilionbuy**

- Warunki użytkowania dla członków społeczności Bilionbuy 
- Warunki sprzedaży online 
- Warunki odpowiedzialności za treść postów i recenzji 
- Polityka prywatności Bilionbuy

**Warunki korzystania z witryny członka społeczności Bilionbuy**

**1\. Postanowienia podstawowe**

1.1 Bilionbuy to platforma internetowa zapewniająca sprzedaż online własnych i partnerskich produktów i usług, jednocześnie umożliwiając zarejestrowanym członkom społeczności uzyskiwanie korzyści finansowych i innych w postaci zwrotu gotówki lub nagród.

1.2 Niniejsze warunki regulują zasady korzystania z platformy internetowej Bilionbuy, zasady dołączania do społeczności Bilionbuy oraz wynikające z tego korzyści dla zarejestrowanych członków społeczności Bilionbuy.

**2\. Definicje**

2.1 Platforma internetowa \ - strona internetowa [www.bilionbuy.com] (http://www.bilionbuy.com).

2.2 Operator - operatorem platformy internetowej jest spółka Bilionbuy International s.r.o., nr identyfikacyjny: 56190999, z siedzibą pod adresem Farbiarska 53/29, 064 01 Stara Lubownia.

2.3 Członek społeczności \ - rejestrując się na platformie internetowej, rejestrujący automatycznie staje się członkiem społeczności Bilionbuy.

2.4 Sieć biznesowa - sieć biznesowa członka społeczności Bilionbuy obejmuje wszystkich bezpośrednio i pośrednio zarejestrowanych znajomych, którzy zostali zaproszeni do społeczności Bilionbuy bezpośrednio przez członka społeczności lub pośrednio przez zarejestrowanych znajomych zaproszonych przez członka społeczności.

2.5 Bezpośrednio zaproszony znajomy - znajomy, który został zaproszony do Bilionbuy przez członka społeczności Bilionbuy bezpośrednio poprzez swoją własną aktywność, wysyłając zaproszenie za pośrednictwem platformy internetowej lub wysyłając link polecający. 

2.6 Pośrednio zaproszony znajomy - znajomy, który został zaproszony do Bilionbuy przez jednego ze znajomych zaproszonych przez członka społeczności Bilionbuy lub jednego z jego znajomych.

**3\. Rejestracja i zaproszenie do społeczności Bilionbuy**

3.1 Rejestracja odbywa się na platformie internetowej. 

3.2 Rejestracja jest możliwa poprzez zaproszenie (polecenie) od znajomego lub bezpośrednio poprzez rejestrację na platformie internetowej bez osoby polecającej.

3.3 Rejestracja poprzez zaproszenie odbywa się poprzez wysłanie linku polecającego lub bezpośrednio na platformie internetowej poprzez wysłanie zaproszenia za pomocą funkcji „Zaproś znajomego”.

3.4 Rejestrując się, rejestrujący automatycznie staje się członkiem społeczności Bilionbuy bez obowiązku uiszczania jakichkolwiek opłat za członkostwo w społeczności Bilionbuy.

3.5 Rejestrując się na platformie internetowej, członek społeczności otrzymuje korzyści wynikające z członkostwa w społeczności Bilionbuy.

**4\. Korzyści z członkostwa w społeczności Bilionbuy**

4.1 Bilionbuy nie nakłada żadnych restrykcyjnych warunków, aby otrzymać korzyści. Nie ma minimalnych zakupów ani minimalnych działań. Korzyści finansowe zależą od osiągniętego obrotu zakupami.

4.2 Członek społeczności Bilionbuy ma prawo do następujących korzyści z tytułu członkostwa w społeczności Bilionbuy. 

4.3 Zwrot gotówki za własne zakupy \ - kwota zwrotu gotówki za własne zakupy jest wskazana dla sklepu internetowego, konkretnego produktu lub usługi wymienionej na platformie internetowej Bilionbuy i jest różna dla każdego sklepu internetowego, produktu lub usługi.

4.4 Bezpośredni zwrot gotówki za polecenie \ - to forma zwrotu gotówki za zakupy dokonane bezpośrednio przez zaproszonych znajomych. Kwota zwrotu gotówki za bezpośrednie polecenie jest wskazana dla sklepu internetowego, produktu lub usługi wymienionej na platformie internetowej Bilionbuy i jest inna dla każdego sklepu internetowego, produktu lub usługi.

4.5 Nagrody za karierę \ - to forma nagrody za polecanie i budowanie społeczności Bilionbuy zgodnie z punktami zdobytymi za zakupy w sieci handlowej członka społeczności Bilionbuy. Warunki osiągnięcia pozycji kariery i wysokość nagród za te pozycje można znaleźć w sekcji „Ścieżka kariery”.

4.6 Premia docelowa \- to forma nagrody ustalona dla członka społeczności Bilionbuy w przypadku, gdy bezpośrednio zaproszony przez niego członek społeczności Bilionbuy osiągnie pozycję kariery Partnera w danym miesiącu. W takim przypadku członek społeczności jest włączony do grupy członków społeczności Bilionbuy, którym premia docelowa jest wypłacana w równych częściach. Premia docelowa to kwota stanowiąca 1% obrotu Bilionbuy bez podatku VAT.

4.7 Inne specjalne korzyści \ - są to dodatkowe korzyści, o których Bilionbuy informuje członków społeczności Bilionbuy za pośrednictwem poczty elektronicznej lub platformy internetowej.

**5\. Punkty, okres śledzenia i ścieżka kariery**

5.1 Za każdy zakup członek społeczności Bilionbuy zdobywa punkty. Liczba punktów jest wskazana dla produktu lub usługi wymienionej na stronie internetowej Bilionbuy i różni się dla każdego produktu lub usługi.

5.2 W celu zdobycia nagród za karierę członek społeczności Bilionbuy gromadzi punkty w każdym miesiącu, zaczynając od zera. Na początku każdego miesiąca zgromadzone punkty są resetowane do zera.

5.3 Okres monitorowania to okres, w którym śledzony jest obrót osiągnięty przez członka społeczności Bilionbuy i wszystkich jego podwładnych członków społeczności Bilionbuy. 

5.3 Okres monitorowania trwa od 1 do ostatniego dnia każdego miesiąca. Rozpoczyna się zawsze na początku miesiąca od osiągniętego obrotu 0\.

5.4 Okres monitorowania obejmuje wyłącznie obroty z tytułu prowizji za zakupy produktów i usług. Nie wystarczy, że kwota zakupu została zapłacona, prowizja musi zostać zapłacona Bilionbuy. Czas trwania płatności prowizji na rzecz Bilionbuy różni się w zależności od partnera i może wynosić od 1 do 40 dni.

5.5 Tabela ścieżki kariery

![][image1]

5.6 Członek społeczności BILIONBUY jest uprawniony do otrzymania nagrody za karierę zgodnie z pozycją osiągniętą na ścieżce kariery zgodnie z liczbą punktów zdobytych w danym miesiącu pomnożoną przez wartość euro. Pozycja może zostać osiągnięta poprzez historycznie zgromadzone punkty, w którym to przypadku członek jest uprawniony do pozycji na zawsze, lub poprzez wykonanie obrotu w danym miesiącu, w którym to przypadku członek jest uprawniony do pozycji w tym miesiącu. W przypadku, gdy część obrotu punktowego została zdobyta przez podległego mu członka społeczności BILIONBUY, w takim przypadku przełożony członka społeczności BILIONBUY ma prawo do nagrody różnicowej o wartości euro.

**6\. Warunki płatności**

6.1 Nagrody Cashback są wypłacane co tydzień, zawsze w poniedziałki. 

6.2 Nagrody za karierę są wypłacane raz w miesiącu, do 5 dnia następnego miesiąca, za który członkowi społeczności BILIONBUY przysługuje nagroda za karierę.

**7\. Reklamacje**

7.1 Reklamacje dotyczące przyznanych korzyści finansowych są składane za pośrednictwem komunikacji e-mail [info@bilionbuy.com](mailto:info@bilionbuy.com) lub za pośrednictwem wewnętrznego obszaru platformy internetowej.

7.2 Bilionbuy zobowiązuje się do rozpatrzenia reklamacji w ciągu 30 dni od daty udokumentowania wszystkich informacji niezbędnych do rozpatrzenia reklamacji.

**8\. Postanowienia końcowe**

8.1 Operator zastrzega, że strona internetowa Bilionbuy może nie być zawsze dostępna, w szczególności z powodu problemów technicznych lub regularnej konserwacji. Operator nie ponosi odpowiedzialności za jakiekolwiek szkody spowodowane niedostępnością, zmianami na stronie Bilionbuy.

8.2 Operator ma prawo do jednostronnej modyfikacji niniejszego Regulaminu w rozsądnym zakresie, w szczególności w celu uzupełnienia bardziej szczegółowego brzmienia Regulaminu. Członek społeczności zostanie powiadomiony o zmianie Warunków co najmniej 14 dni przed wejściem w życie nowych Warunków, pocztą elektroniczną lub za pośrednictwem Platformy internetowej. Członek społeczności ma możliwość odrzucenia zmian w Warunkach, żądając usunięcia swojego konta Bilionbuy lub zaprzestając korzystania ze strony internetowej Bilionbuy.

8.3 Prawa i obowiązki nieuregulowane niniejszymi Warunkami podlegają prawu obowiązującemu w kraju, do którego odnosi się Platforma.

8.4 Niniejsze ogólne warunki wchodzą w życie w dniu 19\. 09\. 2024\.

**Warunki sprzedaży online**

**1\. Postanowienia podstawowe**

1.1 Warunki sprzedaży internetowej regulują prawa i obowiązki stron umowy przy zawieraniu umowy kupna negocjowanej na odległość między partnerem operatora a kupującym, której przedmiotem jest sprzedaż towarów lub usług.

**2\. Definicje**

2.1. platforma internetowa - strona internetowa www.bilionbuy.com.

2.2 Partnerski sklep internetowy \ - to sklep internetowy partnera Bilionbuy, który jest wyświetlany na stronie Bilionbuy na podstawie specjalnej współpracy między Bilionbuy a partnerem.

2.3 Partner \ - operator sklepu internetowego, do którego Bilionbuy odnosi się w porównaniu produktów i usług, przy czym faktyczne zamówienie i zawarcie umowy kupna odbywa się w sklepie internetowym Partnera na własny rachunek i odpowiedzialność Partnera.

**3\. Sprzedaż za pośrednictwem Bilionbuy**

3.1 Członek społeczności Bilionbuy lub niezarejestrowany użytkownik ma prawo wyszukać produkt, którego zakupem jest zainteresowany, lub wyszukać sklep internetowy Partnera, w którym chce dokonać zakupu. W takim przypadku sprzedaż online nie odbywa się na stronie Bilionbuy, ale bezpośrednio w partnerskim sklepie internetowym, zgodnie z warunkami partnerskiego sklepu internetowego.

**4\. Postanowienia końcowe**

4.1 Kwestie nieobjęte niniejszymi warunkami podlegają odpowiednim obowiązującym przepisom.

4.2 Niniejsze warunki sprzedaży online stanowią uzupełnienie Warunków użytkowania dla członków społeczności Bilionbuy.

4.3 Niniejsze Warunki sprzedaży internetowej wchodzą w życie z dniem 03**. 09\. 2024\.

**Warunki przesyłania postów i recenzji na platformę internetową**

**1\. Postanowienia podstawowe**

Niniejsze warunki przesyłania postów i recenzji na platformę internetową Bilionbuy mają na celu umożliwienie członkom społeczności Bilionbuy uczestniczenia w treści witryny poprzez dzielenie się własnymi doświadczeniami z zakupionymi produktami lub usługami (zwanymi recenzjami).

**2\. Odpowiedzialność za treść recenzji**

2.1 Członek społeczności Bilionbuy ponosi pełną odpowiedzialność cywilną i karną za treść swojej recenzji. 

2.2 Członek społeczności Bilionbuy jest zobowiązany do udostępnienia recenzji, która jest zgodna z prawdą i nie została stworzona w celu zaszkodzenia konkurentowi w walce konkurencyjnej.

**3\. Weryfikacja treści recenzji**

3.1 Zarówno zweryfikowane, jak i niezweryfikowane recenzje są publikowane na platformie internetowej Bilionbuy i są wyraźnie oznaczone jako zweryfikowane lub niezweryfikowane na stronie.

3.2 Bilionbuy zastrzega sobie prawo do weryfikacji każdej recenzji dostarczonej przez członka społeczności Bilionbuy i w tym celu Bilionbuy ma prawo zażądać niezbędnych dowodów od członka społeczności lub partnera operatora.

3.3 Partner operatora ma prawo sprzeciwić się nielegalnej treści recenzji lub zażądać weryfikacji legalności treści recenzji, wysyłając wiadomość e-mail na adres [partner@bilionbuy.com](mailto:partner@bilionbuy.com), wskazując, która recenzja jest kwestionowana i powody sprzeciwu.

3.4 Bilionbuy zastrzega sobie prawo do usuwania niezweryfikowanych recenzji, sfabrykowanych recenzji lub recenzji, których autentyczność jest wysoce nieprawdopodobna.

**4\. Postanowienia końcowe**

4.1 Niniejsze warunki przesyłania postów i recenzji na platformę internetową stanowią uzupełnienie Warunków użytkowania dla członków społeczności Bilionbuy.

4.2 Niniejsze Warunki przesyłania postów i recenzji na platformę internetową wchodzą w życie z dniem 03/03/2018. 09\. 2024\.

**Polityka prywatności**

Operator:

Bilionbuy International Ltd.

Siedziba: Farbiarska 53/29, 064 01 Stará Ľubovňa

zarejestrowana w Sądzie Miejskim w Bratysławie III, sekcja: Sro, nr akt 177746/B

Organ statutowy. Mgr. Štefan Lazorčák, dyrektor zarządzający

Administrator przetwarza wspólne kategorie danych osobowych oraz szczególne kategorie danych osobowych.

Wspólne kategorie danych osobowych przetwarzanych przez administratora to: 
imię, nazwisko, podobizna, data urodzenia, adres stały, adres korespondencyjny, narodowość, IBAN, telefon, e-mail, adres IP, numer produktu (umowy), dane dotyczące wykorzystywanych produktów i usług, takie jak dane dotyczące wykorzystywanych produktów i usług, dane związane z obsługą żądań użytkownika, dane społeczno-demograficzne, takie jak wiek, płeć, nagrania audio, takie jak nagrania rozmów prowadzonych za pośrednictwem centrum kontaktowego, dane związane z korzystaniem z naszych stron internetowych i aplikacji (takie jak pliki cookie), dane związane ze śledzeniem naszych kanałów marketingowych, takie jak godziny otwarcia wysyłanych wiadomości, na jakim urządzeniu odczytujesz informacje, jakie parametry techniczne i system operacyjny ma urządzenie, dane związane z wypełnianiem obowiązków umownych i zobowiązań, dane wynikające z aktywności w sieciach społecznościowych, dane pochodne wynikające z ustawień użytkownika, dane dotyczące produktów i usług, dane dotyczące korzystania z naszych stron internetowych.

Administrator nie przetwarza szczególnych kategorii danych osobowych.

Administrator przetwarza dane osobowe w celu:

- promocji administratora danych, w szczególności wysyłania newsletterów, wiadomości e-mail, wiadomości e-mail o charakterze reklamowym, wiadomości SMS, wiadomości SMS o charakterze reklamowym, 
- komunikacji z osobą, której dane dotyczą, np. prowadzenie komunikacji online lub telefonicznej, 
- rejestrowanie i publikowanie recenzji i referencji, 
- organizowanie wydarzeń, 
- archiwizacja i statystyki, 
- zarządzanie plikami cookie, 
- rejestracja i realizacja stosunków umownych osoby, której dane dotyczą, z administratorem, jego partnerami biznesowymi lub z innymi podmiotami oraz ewentualne dochodzenie roszczeń, 
- pozyskiwanie, rejestrowanie, gromadzenie, przechowywanie danych osobowych w systemie informatycznym, uzyskiwanie do nich dostępu, przechowywanie, modyfikowanie i zmienianie, pobieranie, przeglądanie, przesyłanie, sortowanie i łączenie ich w celu rejestrowania wyników biznesowych, wielkości sieci biznesowej i sieci klientów, nagród, rejestrowania zarejestrowanych lub zleconych transakcji, 
- ujawnianie wybranych danych osobowych członkom społeczności Bilionbuy, którzy są sklasyfikowani jako przełożeni w swojej karierze, 
- przetwarzanie i publikacja zdjęć przedstawiających podobiznę członka społeczności Bilionbuy do celów działań marketingowych i promocyjnych oraz publikacja podobizny w osobistym profilu na platformie internetowej, 
- identyfikacja i weryfikacja tożsamości klientów i ich przedstawicieli, 
- zawieranie i wykonywanie stosunków umownych, w tym stosunków przedumownych, 
- zarządzanie relacjami, 
- zarządzanie stosunkami umownymi, w tym wprowadzanie zmian i ich rozwiązywanie, 
- przyjmowanie i rozpatrywanie skarg i zażaleń od klientów lub członków społeczności Bilionbuy 
- dokumentowanie i zapewnianie działań członków społeczności Bilionbuy w zakresie wypełniania zobowiązań wynikających z zawartych stosunków umownych, zgodnie z postanowieniami szczegółowych przepisów, 
- wypełnianie obowiązków wynikających dla członków Bilionbuy z ustawy nr 297/2008 Coll. w sprawie ochrony przed legalizacją dochodów z przestępstwa i ochrony przed finansowaniem terroryzmu, 
- inne działania związane z wypełnianiem zadań i obowiązków Bilionbuy wynikających z obowiązującego prawa, 
- marketing w zakresie dostarczania produktów i usług, 
- konkursy konsumenckie (ocena i określanie zwycięzców i uczestników), 
- konkursy dla członków społeczności Bilionbuy (ocena i wyłonienie zwycięzców i uczestników). 


Podstawą prawną przetwarzania danych osobowych w powyższych celach jest fakt, że przetwarzanie danych osobowych jest niezbędne do wykonania umowy, której stroną jest osoba, której dane dotyczą. Wszystkie cele, o których mowa, są niezbędne do wykonania umowy, jednak w razie jakichkolwiek wątpliwości osoba, której dane dotyczą, niniejszym wyraża zgodę na przetwarzanie danych osobowych w powyższych celach. Przetwarzanie danych osobowych jest odwołalne wyłącznie w przypadku zakończenia stosunku umownego z administratorem. Umowa, na której opiera się nasze przetwarzanie, jest tworzona poprzez wprowadzenie danych użytkownika podczas rejestracji lub w strefie wewnętrznej.

Dane osobowe są przekazywane wyłącznie w związku z powyższymi celami upoważnionym osobom u administratora, które przetwarzają dane osobowe na podstawie przetwarzania w powyższych celach, a także podmiotom przetwarzającym, które przetwarzają dane osobowe w imieniu administratora, na podstawie umowy pośrednictwa.

Dane osobowe osoby, której dane dotyczą, są przechowywane przez administratora w związku z wyżej wymienionymi celami przez okres określony w przepisach szczególnych, w innych przypadkach przez czas trwania stosunku umownego.

Dane osobowe nie będą przekazywane do państwa trzeciego w związku z wyżej wymienionymi celami.

Podanie danych osobowych w związku z powyższymi celami jest wymogiem prawnym.

Administrator informuje osobę, której dane dotyczą, że dokonuje zautomatyzowanego podejmowania decyzji i profilowania w związku z wyżej wymienionymi celami. W ramach tego za pomocą operacji technicznej ustala, czym użytkownik wyraził zainteresowanie na stronie cyfrowej, i na tej podstawie sprawdza, czy zainteresowanie znalazło odzwierciedlenie w transakcji, a jeśli nie, wysyła mu w przyszłości informacje w formie cyfrowej o możliwym zawarciu transakcji (np. w formie biuletynu, oferty handlowej lub promocyjnej w formie wiadomości e-mail lub SMS itp.) Odbywa się to w interesie zapewnienia lepszego doświadczenia użytkownika i nie stwarza żadnego ryzyka ani konsekwencji dla osoby, której dane dotyczą, poza ofertą produktów i usług.

Prawa osoby, której dane dotyczą, obejmują: 
prawo dostępu do swoich danych osobowych 
prawo do sprostowania swoich danych osobowych 
prawo do usunięcia danych osobowych (prawo do bycia zapomnianym) 
prawo do ograniczenia przetwarzania danych osobowych 
prawo do wniesienia sprzeciwu wobec przetwarzania danych osobowych 
prawo do przenoszenia danych osobowych 
prawo do wniesienia skargi do organu nadzorczego, tj. Urzędu Ochrony Danych Osobowych Republiki Słowackiej 
prawo do wniesienia sprzeciwu wobec zautomatyzowanego indywidualnego podejmowania decyzji i profilowania 
prawo do wniesienia skargi zgodnie z art. 100 ustawy o ochronie danych osobowych 
Prawo do wycofania zgody na ujawnienie danych osobowych 
Dane kontaktowe osoby odpowiedzialnej: 
Mgr. Mgr Štefan Lazorčák 
E-mail: info@bilionbuy.com

[image1]: https://bbuy.fra1.cdn.digitaloceanspaces.com/terms/terms_pl.png
    
    `,
    de: `
    **Bilionbuy Community Nutzungsbedingungen**

- Nutzungsbedingungen für Bilionbuy-Community-Mitglieder 
- Allgemeine Geschäftsbedingungen für Online-Verkäufe 
- Bedingungen für die Verantwortung für den Inhalt von Beiträgen und Bewertungen 
- Bilionbuy Datenschutzrichtlinien

**Nutzungsbedingungen der Bilionbuy Community Member Site**

**1\. Grundlegende Bestimmungen**

1.1 Bilionbuy ist eine Internetplattform, die den Online-Verkauf von eigenen Produkten und Dienstleistungen sowie von Produkten und Dienstleistungen von Partnern anbietet und es registrierten Community-Mitgliedern ermöglicht, finanzielle und andere Vorteile in Form von Cashback oder Prämien zu erhalten.

1.2 Diese Allgemeinen Geschäftsbedingungen regeln die Regeln für die Nutzung der Bilionbuy-Webplattform, die Regeln für den Beitritt zur Bilionbuy-Community und die daraus resultierenden Vorteile für registrierte Mitglieder der Bilionbuy-Community.

**2\. Begriffsbestimmungen**

2.1 Webplattform \- Website [www.bilionbuy.com](http://www.bilionbuy.com).

2.2 Betreiber \- der Betreiber der Webplattform ist die Firma Bilionbuy International s.r.o., ID-Nr.: 56190999, mit Sitz in Farbiarska 53/29, 064 01 Stará Ľubovňa.

2.3 Community-Mitglied \- durch die Registrierung auf der Web-Plattform wird der Registrierte automatisch Mitglied der Bilionbuy-Community.

2.4 Business-Netzwerk \- das Business-Netzwerk eines Bilionbuy-Community-Mitglieds umfasst alle direkt und indirekt registrierten Freunde, die direkt von dem Community-Mitglied oder indirekt von den registrierten Freunden, die von dem Community-Mitglied eingeladen wurden, in die Bilionbuy-Community eingeladen worden sind.

2.5 Direkt eingeladene Freunde \ ein Freund, der von einem Bilionbuy-Community-Mitglied direkt durch seine eigene Aktivität, durch Versenden einer Einladung über die Web-Plattform oder durch Versenden eines Empfehlungslinks zu Bilionbuy eingeladen wurde. 

2.6 Indirekt eingeladene Freunde \- ein Freund, der von einem der Freunde, die von einem Bilionbuy-Community-Mitglied oder einem seiner Freunde eingeladen wurden, zu Bilionbuy eingeladen wurde.

**3\. Registrierung und Einladung in die Bilionbuy-Community**

3.1 Die Registrierung erfolgt über die Webplattform. 

3.2 Die Registrierung ist durch Einladung (Referral) eines Freundes oder direkt durch Registrierung auf der Webplattform ohne Referrer möglich.

3.3 Die Registrierung durch Einladung erfolgt durch Versenden eines Empfehlungslinks oder direkt in der Webplattform durch Versenden einer Einladung über die Funktion „Freunde einladen“.

3.4 Mit der Registrierung wird der Registrant automatisch Mitglied der Bilionbuy-Community, ohne dass er verpflichtet ist, Gebühren für die Mitgliedschaft in der Bilionbuy-Community zu zahlen.

3.5 Mit der Registrierung auf der Web-Plattform erhält das Community-Mitglied die Vorteile einer Mitgliedschaft in der Bilionbuy-Community.

**4\. Vorteile der Bilionbuy-Community-Mitgliedschaft**

4.1 Bilionbuy stellt keine einschränkenden Bedingungen für den Erhalt von Vorteilen auf. Es gibt keine Mindesteinkäufe oder Mindestaktivitäten. Die finanziellen Leistungen richten sich nach dem erzielten Einkaufsumsatz.

4.2 Als Mitglied der Bilionbuy-Community haben Sie Anspruch auf die folgenden Vorteile. 

4.3 Cashback auf eigene Einkäufe \- die Höhe des Cashbacks auf eigene Einkäufe wird für den auf der Bilionbuy-Webplattform aufgeführten Online-Shop, das jeweilige Produkt oder die Dienstleistung angegeben und ist für jeden Online-Shop, jedes Produkt oder jede Dienstleistung unterschiedlich.

4.4 Direktempfehlungs-Cashback \- ist eine Form von Cashback für Einkäufe, die direkt von eingeladenen Freunden getätigt werden. Die Höhe des direkten Empfehlungs-Cashbacks wird für den Online-Shop, das Produkt oder die Dienstleistung auf der Bilionbuy-Webplattform angegeben und ist für jeden Online-Shop, jedes Produkt oder jede Dienstleistung unterschiedlich.

4.5 Career Rewards \- ist eine Form der Belohnung für die Empfehlung und den Aufbau der Bilionbuy-Community entsprechend der Punkte, die für Einkäufe des Händlernetzwerks des Bilionbuy-Community-Mitglieds gesammelt werden. Die Bedingungen für das Erreichen von Karrierepositionen und die Höhe der Belohnungen für diese Positionen entnehmen Sie bitte dem Abschnitt „Karriereweg“.

4.6 Zielbonus \- ist eine Form der Belohnung für ein Bilionbuy-Community-Mitglied für den Fall, dass ein von ihm direkt eingeladenes Bilionbuy-Community-Mitglied die Partner-Karriereposition in einem bestimmten Monat erreicht. In diesem Fall wird das Community-Mitglied in die Gruppe der Bilionbuy Community-Mitglieder aufgenommen, an die der Zielbonus zu gleichen Teilen ausgezahlt wird. Der Zielbonus ist ein Betrag in Höhe von 1 % des Bilionbuy-Umsatzes ohne Mehrwertsteuer.

4.7 Sonstige Sonderleistungen \- Hierbei handelt es sich um zusätzliche Leistungen, über die Bilionbuy die Mitglieder der Bilionbuy-Community per E-Mail oder über die Web-Plattform informiert.

**5\. Punkte, Trackingzeitraum und Karrierepfad**

5.1 Für jeden Einkauf sammelt ein Bilionbuy-Community-Mitglied Punkte. Die Anzahl der Punkte ist für das jeweilige Produkt oder die jeweilige Dienstleistung auf der Bilionbuy-Website angegeben und variiert je nach Produkt oder Dienstleistung.

5.2 Zum Zwecke des Erwerbs von Karriereprämien sammelt ein Bilionbuy-Community-Mitglied in jedem Monat Punkte, beginnend bei Null. Zu Beginn eines jeden Monats werden die gesammelten Punkte auf Null zurückgesetzt.

5.3 Der Trackingzeitraum ist der Zeitraum, in dem der Umsatz des Bilionbuy Community Mitglieds und aller ihm unterstellten Bilionbuy Community Mitglieder erfasst wird. 

5.3 Der Trackingzeitraum läuft vom 1. bis zum letzten Tag eines jeden Monats. Er beginnt immer am Anfang des Monats mit dem erzielten Umsatz 0.

5.4 In den Trackingzeitraum fallen nur Umsätze, die sich aus Provisionen für Käufe von Produkten und Dienstleistungen zusammensetzen. Es reicht nicht aus, dass der Betrag des Kaufs bezahlt wurde, die Provision muss an Bilionbuy gezahlt werden. Die Dauer der Provisionszahlung an Bilionbuy ist von Partner zu Partner unterschiedlich und kann zwischen 1 und 40 Tagen liegen.

5.5 Laufbahntabelle

![][image1]

5.6 Ein Mitglied der BILIONBUY-Community hat Anspruch auf eine Karriereprämie entsprechend der erreichten Position in der Karrieretabelle, die sich aus der Anzahl der in einem bestimmten Monat verdienten Punkte multipliziert mit dem Euro-Wert ergibt. Die Position kann durch die in der Vergangenheit gesammelten Punkte erreicht werden, wobei das Mitglied in diesem Fall Anspruch auf die Position für immer hat, oder durch den Abschluss des Umsatzes für den Monat, wobei das Mitglied Anspruch auf die Position in diesem Monat hat. Falls ein Teil des Punkteumsatzes von einem ihm unterstellten Mitglied der BILIONBUY-Gemeinschaft erwirtschaftet wurde, hat in diesem Fall das vorgesetzte Mitglied der BILIONBUY-Gemeinschaft Anspruch auf eine Differenzprämie im Wert von einem Euro.

**6\. Zahlungsbedingungen**

6.1 Cashback-Belohnungen werden wöchentlich, immer montags, ausgezahlt. 

6.2 Karriere-Prämien werden einmal im Monat ausgezahlt, und zwar bis zum 5. Tag des Folgemonats, für den das BILIONBUY Community-Mitglied eine Karriere-Prämie erhalten hat.

**7\. Reklamationen**

7.1 Beanstandungen der gewährten finanziellen Leistungen erfolgen per E-Mail-Kommunikation [info@bilionbuy.com] (mailto:info@bilionbuy.com) oder über den internen Bereich der Web-Plattform.

7.2 Bilionbuy verpflichtet sich, die Reklamation innerhalb von 30 Tagen ab dem Datum der Dokumentation aller für die Reklamation erforderlichen Informationen zu lösen.

**8\. Schlussbestimmungen**

8.1 Der Betreiber behält sich das Recht vor, dass die Bilionbuy-Website insbesondere aufgrund technischer Probleme oder regelmäßiger Wartungsarbeiten nicht immer verfügbar ist. Der Betreiber haftet nicht für Schäden, die durch Nichtverfügbarkeit, Änderungen an der Bilionbuy-Website entstehen.

8.2 Der Betreiber hat das Recht, diese AGB in zumutbarem Umfang einseitig zu ändern, insbesondere um den spezielleren Wortlaut der AGB zu ergänzen. Das Community-Mitglied wird über die Änderung der Bedingungen mindestens 14 Tage vor Inkrafttreten der neuen Bedingungen entweder per E-Mail oder über die Web-Plattform informiert. Das Community-Mitglied hat die Möglichkeit, die Änderungen der Bedingungen abzulehnen, indem es die Löschung seines Bilionbuy-Kontos beantragt oder die Nutzung der Bilionbuy-Website einstellt.

8.3 Die Rechte und Pflichten, die nicht in diesen Bedingungen geregelt sind, unterliegen den Gesetzen, die in dem Land gelten, in dem die Plattform betrieben wird.

8.4 Diese Allgemeinen Geschäftsbedingungen treten am 19\. 09\. 2024\.

**Geschäftsbedingungen für den Online-Verkauf**

**1\. Grundlegende Bestimmungen**

1.1 Die Allgemeinen Geschäftsbedingungen für den Internetverkauf regeln die Rechte und Pflichten der Vertragsparteien beim Abschluss eines zwischen dem Partner des Betreibers und dem Käufer im Fernabsatz ausgehandelten Kaufvertrags, der den Verkauf von Waren oder Dienstleistungen zum Gegenstand hat.

**2\. Definitionen**

2.1. Webplattform \- Website www.bilionbuy.com.

2.2 Partner-Onlineshop \- ist der Onlineshop eines Bilionbuy-Partners, der aufgrund einer besonderen Kooperation zwischen Bilionbuy und dem Partner auf der Bilionbuy-Website dargestellt wird.

2.3 Partner \- ist der Betreiber des Online-Shops, auf den Bilionbuy beim Vergleich von Produkten und Dienstleistungen verweist, wobei die eigentliche Bestellung und der Abschluss des Kaufvertrages im Online-Shop des Partners auf dessen Rechnung und Verantwortung erfolgt.

**3\. Verkäufe über Bilionbuy**

3.1 Ein Mitglied der Bilionbuy-Community oder ein nicht registrierter Nutzer ist berechtigt, nach dem Produkt, das er kaufen möchte, oder nach dem Online-Shop des Partners zu suchen, in dem er kaufen möchte. In diesem Fall findet der Online-Verkauf nicht auf der Bilionbuy-Website statt, sondern direkt im Partner-Onlineshop, gemäß den Geschäftsbedingungen des Partner-Onlineshops.

**4\. Schlussbestimmungen**

4.1 Für Angelegenheiten, die in diesen Geschäftsbedingungen nicht geregelt sind, gilt das jeweils anwendbare Recht.

4.2 Diese Online-Verkaufsbedingungen ergänzen die Nutzungsbedingungen für Bilionbuy-Community-Mitglieder.

4.3 Diese Allgemeinen Geschäftsbedingungen für den Internetverkauf treten am 03**. 09\. 2024\.

**Bedingungen für das Hochladen von Beiträgen und Bewertungen auf die Webplattform**

**1\. Grundlegende Bestimmungen**

Diese Bedingungen für das Hochladen von Beiträgen und Bewertungen auf die Bilionbuy-Webplattform sollen den Mitgliedern der Bilionbuy-Community die Möglichkeit geben, sich an den Inhalten der Website zu beteiligen, indem sie ihre eigenen Erfahrungen mit den von ihnen gekauften Produkten oder Dienstleistungen mitteilen (sogenannte Bewertungen).

**2\. Verantwortung für den Inhalt der Rezensionen**

2.1 Ein Mitglied der Bilionbuy-Community haftet in vollem Umfang zivil- und strafrechtlich für den Inhalt seiner Bewertung. 

2.2 Ein Mitglied der Bilionbuy-Community ist verpflichtet, eine Bewertung abzugeben, die wahrheitsgemäß ist und nicht zu dem Zweck erstellt wurde, einen Mitbewerber in einem Konkurrenzkampf zu schädigen.

**3\. Verifizierung des Inhalts der Bewertung**

3.1 Sowohl verifizierte als auch nicht verifizierte Bewertungen werden auf der Bilionbuy-Webplattform veröffentlicht und auf der Seite sichtbar als verifiziert oder nicht verifiziert gekennzeichnet.

3.2 Bilionbuy behält sich das Recht vor, jede von einem Mitglied der Bilionbuy-Community abgegebene Bewertung zu verifizieren und ist berechtigt, zu diesem Zweck die erforderlichen Nachweise von dem Community-Mitglied oder dem Partner des Betreibers zu verlangen.

3.3 Der Partner des Betreibers ist berechtigt, dem rechtswidrigen Inhalt einer Bewertung zu widersprechen oder eine Überprüfung der Rechtmäßigkeit des Inhalts einer Bewertung zu verlangen, indem er eine E-Mail an [partner@bilionbuy.com](mailto:partner@bilionbuy.com) schickt, in der er angibt, um welche Bewertung es sich handelt und wie er seinen Widerspruch begründet.

3.4 Bilionbuy behält sich das Recht vor, nicht verifizierte Bewertungen, gefälschte Bewertungen oder Bewertungen, die höchstwahrscheinlich nicht echt sind, zu löschen.

**4\. Schlussbestimmungen**

4.1 Diese Bedingungen für das Hochladen von Beiträgen und Bewertungen auf der Webplattform gelten zusätzlich zu den Nutzungsbedingungen für Bilionbuy-Community-Mitglieder.

4.2 Diese Bedingungen für das Hochladen von Beiträgen und Bewertungen auf der Webplattform treten am 03.03.2018 in Kraft. 09\. 2024\.

**Datenschutzbestimmungen**

Betreiber:

Bilionbuy International Ltd.

Eingetragener Sitz: Farbiarska 53/29, 064 01 Stará Ľubovňa

eingetragen beim Stadtgericht Bratislava III, Abteilung: Sro, Aktenzeichen 177746/B

Statutarisches Organ. Mgr. Štefan Lazorčák, Geschäftsführender Direktor

Der für die Verarbeitung Verantwortliche verarbeitet sowohl allgemeine Kategorien personenbezogener Daten als auch besondere Kategorien personenbezogener Daten.

Allgemeine Kategorien personenbezogener Daten, die von dem für die Verarbeitung Verantwortlichen verarbeitet werden, sind: 
Name, Vorname, Konterfei, Geburtsdatum, ständige Anschrift, Korrespondenzanschrift, Staatsangehörigkeit, IBAN, Telefon, E-Mail, IP-Adresse, Produkt-(Vertrags-)Nummer, Daten über in Anspruch genommene Produkte und Dienstleistungen, wie z. B. Daten über in Anspruch genommene Produkte und Dienstleistungen, Daten im Zusammenhang mit der Bearbeitung Ihrer Anfragen, soziodemografische Daten, wie z. B. Alter, Geschlecht, Audioaufzeichnungen, wie z. B. Aufzeichnungen von Anrufen über das Kontaktzentrum, Daten im Zusammenhang mit der Nutzung unserer Websites und Anwendungen (z. B. Cookies), Daten im Zusammenhang mit der Nachverfolgung unserer Marketingkanäle, wie z. B. die Öffnungszeiten der gesendeten Nachrichten, auf welchem Gerät Sie die Informationen lesen, welche technischen Parameter und welches Betriebssystem das Gerät hat, Daten im Zusammenhang mit der Erfüllung Ihrer vertraglichen Verpflichtungen und Zusagen, Daten, die sich aus Aktivitäten in sozialen Netzwerken ergeben, abgeleitete Daten, die sich aus Ihren Einstellungen ergeben, Daten über Produkte und Dienstleistungen, Daten über die Nutzung unserer Websites.

Besondere Kategorien von personenbezogenen Daten werden von dem für die Verarbeitung Verantwortlichen nicht verarbeitet.

Der für die Verarbeitung Verantwortliche verarbeitet personenbezogene Daten zu folgenden Zwecken:

- Werbung des für die Verarbeitung Verantwortlichen, insbesondere Versand von Newslettern, E-Mail-Nachrichten, E-Mails mit Werbecharakter, SMS-Nachrichten, SMS-Nachrichten mit Werbecharakter, 
- Kommunikation mit der betroffenen Person, z.B. Durchführung von Online- oder Telefonkommunikation, 
- Aufzeichnung und Veröffentlichung von Bewertungen und Erfahrungsberichten 
- Organisation von Veranstaltungen, 
- Archivierung und Statistiken, 
- die Verwaltung von Cookies, 
- die Erfassung und Durchführung von vertraglichen Beziehungen der betroffenen Person mit dem für die Verarbeitung Verantwortlichen, seinen Geschäftspartnern oder anderen Stellen und die eventuelle Beitreibung von Forderungen, 
- Beschaffung, Erfassung, Sammlung, Speicherung von personenbezogenen Daten im Informationssystem, Zugriff, Speicherung, Änderung und Veränderung, Abruf, Durchsuchen, Übermittlung, Sortierung und Kombination zum Zweck der Erfassung von Geschäftsergebnissen, der Größe des Geschäfts- und Kundennetzes, von Belohnungen, der Registrierung von registrierten oder in Auftrag gegebenen Transaktionen, 
- die Weitergabe ausgewählter personenbezogener Daten an Mitglieder der Bilionbuy-Gemeinschaft, die in ihrer Laufbahn als Vorgesetzte eingestuft sind, 
- die Verarbeitung und Veröffentlichung von Fotos, auf denen das Bildnis eines Mitglieds der Bilionbuy-Community abgebildet ist, zu Marketing- und Werbezwecken und die Veröffentlichung des Bildnisses in einem persönlichen Profil auf der Web-Plattform, 
- die Identifizierung und Überprüfung der Identität von Kunden und deren Vertretern, 
- das Eingehen und die Durchführung von Vertragsbeziehungen, einschließlich vorvertraglicher Beziehungen, 
- Beziehungsmanagement, 
- Verwaltung von Vertragsverhältnissen, einschließlich der Vornahme von Änderungen und deren Beendigung 
- Entgegennahme und Bearbeitung von Beschwerden und Reklamationen von Kunden oder Mitgliedern der Bilionbuy-Community, 
- die Dokumentation und Sicherstellung der Aktivitäten der Mitglieder der Bilionbuy-Gemeinschaft im Hinblick auf die Erfüllung der Verpflichtungen aus den eingegangenen Vertragsverhältnissen nach Maßgabe der spezifischen Regelungen, 
- die Erfüllung der Verpflichtungen, die sich für Bilionbuy-Mitglieder aus dem Gesetz Nr. 297/2008 Slg. über den Schutz gegen die Legalisierung von Erträgen aus Straftaten und den Schutz gegen die Finanzierung des Terrorismus ergeben, 
- sonstige Tätigkeiten im Zusammenhang mit der Erfüllung der Aufgaben und Verpflichtungen von Bilionbuy gemäß den geltenden Rechtsvorschriften, 
- Marketing bei der Bereitstellung von Produkten und Dienstleistungen, 
- Verbraucherwettbewerbe (Bewertung und Ermittlung von Gewinnern und Teilnehmern), 
- Gewinnspiele für Mitglieder der Bilionbuy-Community (Bewertung und Ermittlung von Gewinnern und Teilnehmern). 


Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten zu den oben genannten Zwecken ist die Tatsache, dass die Verarbeitung personenbezogener Daten für die Erfüllung eines Vertrags erforderlich ist, bei dem die betroffene Person Vertragspartei ist. Alle genannten Zwecke sind für die Erfüllung des Vertrages erforderlich. Im Zweifelsfall willigt die betroffene Person hiermit in die Verarbeitung personenbezogener Daten für die oben genannten Zwecke ein. Diese Verarbeitung personenbezogener Daten ist nur im Falle einer Beendigung des Vertragsverhältnisses mit dem für die Verarbeitung Verantwortlichen widerrufbar. Der Vertrag, auf dem unsere Verarbeitung beruht, kommt durch die Eingabe Ihrer Daten bei der Registrierung oder im internen Bereich zustande.

Personenbezogene Daten werden im Zusammenhang mit den vorgenannten Zwecken nur an berechtigte Personen bei dem für die Verarbeitung Verantwortlichen, die die personenbezogenen Daten auf der Grundlage der Verarbeitung für die vorgenannten Zwecke verarbeiten, sowie an Auftragsverarbeiter, die personenbezogene Daten im Auftrag des für die Verarbeitung Verantwortlichen verarbeiten, auf der Grundlage eines Auftragsverarbeitungsvertrages weitergegeben.

Die personenbezogenen Daten der betroffenen Person werden von dem für die Verarbeitung Verantwortlichen im Zusammenhang mit den oben genannten Zwecken für den in spezifischen Vorschriften festgelegten Zeitraum aufbewahrt, in anderen Fällen für die Dauer des Vertragsverhältnisses.

Die personenbezogenen Daten dürfen im Zusammenhang mit den oben genannten Zwecken nicht in ein Drittland übermittelt werden.

Die Bereitstellung der personenbezogenen Daten im Zusammenhang mit den oben genannten Zwecken ist gesetzlich vorgeschrieben.

Der für die Verarbeitung Verantwortliche klärt die betroffene Person darüber auf, dass er im Zusammenhang mit den vorgenannten Zwecken eine automatisierte Entscheidungsfindung und ein Profiling durchführt. In diesem Rahmen stellt er mit Hilfe eines technischen Verfahrens fest, wofür sich der Nutzer auf der digitalen Seite interessiert hat, und stellt auf dieser Grundlage weiter fest, ob sich das Interesse in einer Transaktion niedergeschlagen hat, und sendet ihm, falls nicht, in Zukunft in digitaler Form Informationen über einen möglichen Abschluss einer Transaktion zu (z. B. in Form eines Newsletters, eines Handels- oder Werbeangebots in Form einer E-Mail oder einer SMS usw.). Dies geschieht im Interesse einer besseren Nutzererfahrung und birgt außer dem Angebot von Produkten und Dienstleistungen keine weiteren Risiken oder Folgen für die betroffene Person.

Zu den Rechten der betroffenen Person gehören: 
das Recht auf Auskunft über ihre personenbezogenen Daten 
das Recht auf Berichtigung ihrer personenbezogenen Daten 
das Recht auf Löschung ihrer personenbezogenen Daten (Recht auf Vergessenwerden) 
das Recht auf Einschränkung der Verarbeitung ihrer personenbezogenen Daten 
Das Recht auf Widerspruch gegen die Verarbeitung ihrer personenbezogenen Daten 
Das Recht auf Übertragbarkeit ihrer personenbezogenen Daten 
Recht auf Beschwerde bei der Aufsichtsbehörde, d. h. dem Amt für den Schutz personenbezogener Daten der Slowakischen Republik 
Das Recht auf Widerspruch gegen die automatisierte individuelle Entscheidungsfindung und das Profiling 
Das Recht, eine Klage gemäß § 100 des Gesetzes zum Schutz personenbezogener Daten einzureichen 
Recht auf Widerruf der Zustimmung zur Weitergabe von personenbezogenen Daten 
Kontaktangaben der verantwortlichen Person: 
Mgr. Mgr. Štefan Lazorčák 
E-Mail: info@bilionbuy.com.

[Bild1]: https://bbuy.fra1.cdn.digitaloceanspaces.com/terms/terms_de.png
    `,
    hu: `**Bilionbuy közösségi feltételek**

- Bilionbuy Közösségi Felhasználási Feltételek 
- Az online értékesítés feltételei 
- A hozzászólások és vélemények tartalmáért való felelősség feltételei 
- Bilionbuy Adatvédelmi szabályzat

**A Bilionbuy közösségi tagok oldalának használati feltételei**

**1\. Alapvető rendelkezések**

1.1. A Bilionbuy egy webes platform, amely saját és partner termékeinek és szolgáltatásainak online értékesítését biztosítja, miközben lehetővé teszi a regisztrált közösségi tagok számára, hogy pénzbeli és egyéb előnyöket szerezzenek cashback vagy jutalmak formájában.

1.2. Ezek a feltételek szabályozzák a Bilionbuy webes platform használatának szabályait, a Bilionbuy közösséghez való csatlakozás szabályait, valamint a Bilionbuy közösség regisztrált tagjai számára ebből eredő előnyöket.

**2\. Fogalommeghatározások**

2.1 Webes platform \- weboldal [www.bilionbuy.com](http://www.bilionbuy.com).

2.2. Üzemeltető \- a webes platform üzemeltetője a Bilionbuy International s.r.o. társaság, azonosítószám: 56190999, székhelye: 064 01 Stará Ľubovňa, Farbiarska 53/29, 064 01.

2.3 Közösségi tag \- a webes platformon történő regisztrációval a regisztráló automatikusan a Bilionbuy közösség tagjává válik.

2.4 Üzleti hálózat \- a Bilionbuy közösségi tag üzleti hálózata magában foglalja az összes közvetlenül és közvetve regisztrált barátot, akiket a közösségi tag közvetlenül vagy a közösségi tag által meghívott regisztrált barátok közvetve meghívtak a Bilionbuy közösségbe.

2.5 Közvetlenül meghívott barát \- olyan barát, akit a Bilionbuy közösségi tag közvetlenül saját tevékenységén keresztül, a webes platformon keresztül küldött meghívóval vagy ajánló link elküldésével hívott meg a Bilionbuy-ra. 

2.6. Közvetve meghívott barát \- olyan barát, akit a Bilionbuy Közösségi Tag által meghívott barátok egyike vagy azok egyik barátja hívott meg a Bilionbuy-ra.

**3\. Regisztráció és meghívás a Bilionbuy közösségbe**

3.1. A regisztráció a webes platformon történik. 

3.2 A regisztráció egy barát meghívásával (ajánlásával) vagy közvetlenül a webes platformon történő regisztrációval lehetséges, ajánló nélkül.

3.3 A meghívással történő regisztráció meghívó link küldésével vagy közvetlenül a webes platformon a „Hívj meg egy barátot” funkcióval történő meghívás küldésével történik.

3.4 A regisztrációval a regisztráló automatikusan a Bilionbuy közösség tagjává válik, anélkül, hogy a Bilionbuy közösség tagságáért bármilyen díjat kellene fizetnie.

3.5 A webes platformon történő regisztrációval a közösségi tag részesül a Bilionbuy közösséghez való csatlakozással járó előnyökben.

**4\. A Bilionbuy közösségi tagság előnyei**

4.1 A Bilionbuy nem szab korlátozó feltételeket az előnyök igénybevételéhez. Nincsenek minimális vásárlások vagy minimális tevékenységek. A pénzügyi előnyöket az elért vásárlások forgalma befolyásolja.

4.2 A Bilionbuy közösség tagja a Bilionbuy közösség tagjaként a következő előnyökre jogosult. 

4.3 Cashback saját vásárlások után \ - a saját vásárlások után járó cashback összege a Bilionbuy webes platformon felsorolt webáruház, konkrét termék vagy szolgáltatás esetében van feltüntetve, és minden egyes webáruház, termék vagy szolgáltatás esetében eltérő.

4.4 Közvetlen ajánlási cashback \- a cashback egy olyan formája, amelyet a meghívott barátok által közvetlenül végrehajtott vásárlásokra állapítottak meg. A közvetlen ajánlási cashback összege a Bilionbuy webes platformon felsorolt webáruház, termék vagy szolgáltatás esetében van feltüntetve, és minden egyes webáruház, termék vagy szolgáltatás esetében eltérő.

4.5. Karrierjutalom \- a Bilionbuy közösség ajánlásáért és építéséért meghatározott jutalomforma a Bilionbuy közösségi tag kereskedőhálózatában történő vásárlásokért szerzett pontok alapján. A karrierpozíciók elérésének feltételeit és az ezekért járó jutalmak összegét lásd a „Karrierút” részben.

4.6 Célbónusz \- a Bilionbuy közösségi tag számára meghatározott jutalomforma abban az esetben, ha egy általa közvetlenül meghívott Bilionbuy közösségi tag egy adott hónapban eléri a Partner karrierpozíciót. Ebben az esetben a közösségi tag bekerül azon Bilionbuy közösségi tagok csoportjába, akiknek a célbónusz egyenlő mértékben kerül kifizetésre. A célbónusz a Bilionbuy áfa nélküli forgalmának 1%-ából álló összeg.

4.7 Egyéb különleges juttatások \- ezek olyan további juttatások, amelyekről a Bilionbuy e-mailben vagy a webes platformon keresztül tájékoztatja a Bilionbuy közösség tagjait.

**5\. Pontok, követési időszak és karrierút**

5.1 A Bilionbuy közösség tagja minden egyes vásárlásért pontokat gyűjt. A pontok száma a Bilionbuy weboldalán felsorolt terméknél vagy szolgáltatásnál van feltüntetve, és termékenként vagy szolgáltatásonként változik.

5.2 A karrierjutalmak megszerzéséhez a Bilionbuy közösségi tag minden hónapban nulláról kezdve gyűjti a pontokat. Minden hónap elején a felhalmozott pontok nullára kerülnek visszaállításra.

5.3 A követési időszak az az időszak, amelyben a Bilionbuy Közösségi Tag és az összes beosztott Bilionbuy Közösségi Tag által elért forgalmat követik. 

5.3 A nyomonkövetési időszak minden hónap 1. napjától az utolsó napjáig tart. Mindig a hónap elején kezdődik az elért 0\ forgalomtól.

5.4 A nyomonkövetési időszak csak a termékek és szolgáltatások vásárlásaiért fizetett jutalékokból álló forgalmat tartalmazza. Nem elegendő, hogy a vásárlás összege kifizetésre került, a jutalékot a Bilionbuy részére kell kifizetni. A Bilionbuy részére történő jutalékfizetés időtartama partnerenként változik, és ez az időszak 1 és 40 nap között lehet.

5.5. Karrierút-táblázat

![][kép1]

5.6 A BILIONBUY közösség tagja a karrierútban elért pozíciójának megfelelő karrierjutalomra jogosult az adott hónapban megszerzett pontok számának és az euró értékének szorzata alapján. A pozíciót el lehet érni történelmileg összegyűjtött pontokkal, ebben az esetben a tag örökre jogosult a pozícióra, vagy az adott hónap forgalmának teljesítésével, ebben az esetben a tag az adott hónapban jogosult a pozícióra. Abban az esetben, ha a pontforgalom egy részét egy neki alárendelt BILIONBUY közösségi tag szerezte meg, ebben az esetben a felettes BILIONBUY közösségi tagot euró értékű különbözeti jutalom illeti meg.

**6\. Fizetési feltételek**

6.1 A Cashback jutalmak kifizetése hetente történik, mindig hétfőnként. 

6.2 A karrierjutalmak kifizetése havonta egyszer történik, a következő hónap 5\. napjáig, amelyre a BILIONBUY közösségi tagnak a karrierjutalom jár.

**7\. Követelések**

7.1 A nyújtott pénzügyi juttatásokkal kapcsolatos reklamációkat az [info@bilionbuy.com](mailto:info@bilionbuy.com) e-mail kommunikáción keresztül vagy a webes platform belső felületén keresztül lehet benyújtani.

7.2 A Bilionbuy vállalja, hogy a reklamációt a reklamációhoz szükséges összes információ dokumentálásától számított 30 napon belül megoldja.

**8\. Záró rendelkezések**

8.1 Az Üzemeltető fenntartja a jogot arra, hogy a Bilionbuy weboldal nem mindig elérhető, különösen technikai problémák vagy rendszeres karbantartás miatt. Az Üzemeltető nem vállal felelősséget a Bilionbuy weboldal elérhetetlenségéből, módosításából eredő károkért.

8.2. Az Üzemeltetőnek jogában áll a jelen Általános Szerződési Feltételeket ésszerű mértékben egyoldalúan módosítani, különösen az Általános Szerződési Feltételek konkrétabb megfogalmazásának kiegészítése érdekében. A Közösségi Tagot a Feltételek módosításáról az új Feltételek hatályba lépése előtt legalább 14 nappal e-mailben vagy a Webes Platformon keresztül értesítjük. A közösségi tagnak lehetősége van arra, hogy elutasítsa a Feltételek módosításait a Bilionbuy-fiókja törlésének kérésével vagy a Bilionbuy weboldal használatának beszüntetésével.

8.3 A jelen Feltételekben nem szabályozott jogokra és kötelezettségekre a Platform helye szerinti ország hatályos jogszabályai az irányadók.

8.4 A jelen általános szerződési feltételek 19\-én lépnek hatályba. 09\. 2024\.

**Az online értékesítés feltételei**

**1\. Alapvető rendelkezések**

1.1. Az internetes értékesítés feltételei szabályozzák a szerződő felek jogait és kötelezettségeit az üzemeltető partnere és a vevő között távolról megtárgyalt adásvételi szerződés megkötésekor, amelynek tárgya áruk vagy szolgáltatások értékesítése.

**2\. Fogalommeghatározások**

2.1. Webes platform \- weboldal www.bilionbuy.com.

2.2. Partner webáruház \- a Bilionbuy partnerének webáruháza, amely a Bilionbuy és a partner közötti külön együttműködés alapján jelenik meg a Bilionbuy weboldalán.

2.3 Partner \- annak az online áruháznak az üzemeltetője, amelyre a Bilionbuy a termékek és szolgáltatások összehasonlításakor hivatkozik, ahol a tényleges megrendelés és az adásvételi szerződés megkötése a partner online áruházában történik a partner saját számlájára és felelősségére.

**3\. Bilionbuy-n keresztüli értékesítés**

3.1. A Bilionbuy közösség tagja vagy nem regisztrált felhasználó jogosult arra, hogy rákereszen a megvásárolni kívánt termékre, vagy megkeresse a Partner online áruházát, ahol vásárolni kíván. Ebben az esetben az online értékesítés nem a Bilionbuy oldalon, hanem közvetlenül a partner webáruházban történik, a partner webáruház feltételeinek megfelelően.

**4\. Záró rendelkezések**

4.1 A jelen általános szerződési feltételekben nem szabályozott kérdésekre a vonatkozó hatályos jogszabályok az irányadók.

4.2 Ezek az online értékesítési feltételek kiegészítik a Bilionbuy közösségi tagok felhasználási feltételeit.

4.3 A jelen internetes értékesítési feltételek 03** napján lépnek hatályba. 09\. 2024\.

**Az internetes platformra történő hozzászólások és vélemények feltöltésének feltételei**

**1\. Alapvető rendelkezések**

A Bilionbuy webes platformra történő hozzászólások és vélemények feltöltésének jelen feltételei lehetőséget biztosítanak a Bilionbuy közösség tagjai számára, hogy részt vegyenek a weboldal tartalmában az általuk megvásárolt termékekkel vagy szolgáltatásokkal kapcsolatos saját tapasztalataik megosztásával (úgynevezett vélemények).

**2\. Felelősség az értékelés tartalmáért**

2.1. A Bilionbuy közösség tagja teljes polgári jogi és büntetőjogi felelősséggel tartozik a véleményének tartalmáért. 

2.2 A Bilionbuy közösség tagja köteles olyan értékelést megosztani, amely a valóságnak megfelel, és nem azzal a céllal készült, hogy egy versenytársnak kárt okozzon a konkurenciaharcban.

**3\. Az értékelés tartalmának ellenőrzése**

3.1. Mind az ellenőrzött, mind az ellenőrizetlen értékeléseket közzéteszik a Bilionbuy webes platformján, és az oldalon jól láthatóan megjelölik, hogy ellenőrzött vagy ellenőrizetlen.

3.2 A Bilionbuy fenntartja a jogot, hogy ellenőrizze a Bilionbuy közösség valamely tagja által adott értékelést, és e célból a Bilionbuy jogosult a szükséges bizonyítékokat a közösség tagjától vagy az üzemeltető partnerétől kérni.

3.3. Az üzemeltető partnere jogosult kifogást emelni egy értékelés jogellenes tartalma ellen, vagy kérheti egy értékelés tartalmának jogszerűségének ellenőrzését a [partner@bilionbuy.com](mailto:partner@bilionbuy.com) címre küldött e-mailben, megjelölve, hogy melyik értékelésről van szó, és a kifogás indokait.

3.4 A Bilionbuy fenntartja a jogot, hogy törölje az ellenőrizetlen, hamisított vagy nagy valószínűséggel nem valódi értékeléseket.

**4\. Záró rendelkezések**

4.1 A webes platformra történő hozzászólások és vélemények feltöltésére vonatkozó jelen feltételek a Bilionbuy közösségi tagok használati feltételein felül érvényesek.

4.2 A webes platformra történő hozzászólások és vélemények feltöltésére vonatkozó jelen Feltételek 2018. 03. 03-án lépnek hatályba. 09\. 2024\.

**Adatvédelmi szabályzat**

Üzemeltető:

Bilionbuy International Ltd.

Bejegyzett székhely: Farbiarska 53/29, 064 01 Stará Ľubovňa, 064 01 Stará Ľubovňa.

bejegyezve a Pozsonyi Városi Bíróság III. szekciójában: Sro, iktatószám: 177746/B.

Törvényes szerv. Mgr. Štefan Lazorčák, ügyvezető igazgató.

Az adatkezelő a személyes adatok közös kategóriáit, valamint a személyes adatok különleges kategóriáit kezeli.

Az adatkezelő által kezelt személyes adatok közös kategóriái a következők: 
Név, vezetéknév, képmás, születési dátum, állandó lakcím, levelezési cím, állampolgárság, IBAN, telefon, e-mail, IP-cím, termék (szerződés) száma, az igénybe vett termékekre és szolgáltatásokra vonatkozó adatok, például az igénybe vett termékekre és szolgáltatásokra vonatkozó adatok, az Ön kéréseinek kezelésével kapcsolatos adatok, szociodemográfiai adatok, például életkor, nem, hangfelvételek, például a kapcsolattartó központon keresztül folytatott hívások felvételei, weboldalaink és alkalmazásaink használatával kapcsolatos adatok (például sütik), marketingcsatornáink nyomon követésével kapcsolatos adatok, például a kiküldött üzenetek nyitvatartási ideje, hogy Ön melyik eszközön olvassa az információkat, milyen technikai paraméterekkel és milyen operációs rendszerrel rendelkezik az eszköz, az Ön szerződéses kötelezettségeinek és kötelezettségvállalásainak teljesítésével kapcsolatos adatok, a közösségi hálózatokon végzett tevékenységből származó adatok, az Ön beállításaiból származó származtatott adatok, a termékekre és szolgáltatásokra vonatkozó adatok, a weboldalaink használatára vonatkozó adatok.

A személyes adatok különleges kategóriáit az adatkezelő nem kezeli.

Az adatkezelő a személyes adatokat a következők érdekében dolgozza fel:

- Az adatkezelő promóciója, különösen hírlevelek, e-mail üzenetek, reklám jellegű e-mailek, SMS üzenetek, reklám jellegű SMS üzenetek küldése, 
- az érintettel való kommunikáció, pl. online vagy telefonos kommunikáció lebonyolítása, 
- vélemények és ajánlások rögzítése és közzététele, 
- rendezvények szervezése, 
- archiválás és statisztikák készítése, 
- cookie-k kezelése, 
- az érintettnek az adatkezelővel, annak üzleti partnereivel vagy más szervezetekkel fennálló szerződéses kapcsolatainak nyilvántartása és végrehajtása, valamint a követelések esetleges behajtása, 
- a személyes adatok megszerzése, rögzítése, gyűjtése, tárolása az információs rendszerben, hozzáférés, tárolás, módosítás és megváltoztatás, visszakeresés, böngészés, továbbítás, rendezés és kombinálás az üzleti eredmények, az üzleti hálózat és az ügyfélhálózat mérete, jutalmak, regisztrált vagy megbízási ügyletek nyilvántartása céljából, 
- kiválasztott személyes adatok közlése a Bilionbuy közösség azon tagjai számára, akik a pályafutásuk során felügyelőnek minősülnek, 
- a Bilionbuy közösség tagjainak képmását ábrázoló fényképek feldolgozása és közzététele marketing és promóciós tevékenységek céljából, valamint a képmás közzététele a webes platformon található személyes profilban, 
- az ügyfelek és képviselőik személyazonosságának azonosítása és ellenőrzése, 
- szerződéses kapcsolatok megkötése és teljesítése, beleértve a szerződéskötést megelőző kapcsolatokat is, 
- kapcsolattartás, 
- a szerződéses kapcsolatok kezelése, beleértve a módosításokat és azok megszüntetését, 
- az ügyfelektől vagy a Bilionbuy közösség tagjaitól érkező panaszok és panaszok fogadása és kezelése, 
- a Bilionbuy közösség tagjainak tevékenységének dokumentálása és biztosítása a megkötött szerződéses kapcsolatokból eredő kötelezettségek teljesítése tekintetében, a külön szabályzatban foglaltaknak megfelelően, 
- a bűncselekményekből származó jövedelmek legalizálása elleni védelemről és a terrorizmus finanszírozása elleni védelemről szóló 297/2008. sz. törvényből eredő kötelezettségek teljesítése a Bilionbuy tagjai számára, 
- a Bilionbuy feladatainak és a vonatkozó jogszabályok szerinti kötelezettségeinek teljesítésével kapcsolatos egyéb tevékenységek, 
- marketing a termékek és szolgáltatások nyújtása során, 
- fogyasztói versenyek (a nyertesek és a versenyzők értékelése és meghatározása), 
- a Bilionbuy közösség tagjai számára szervezett versenyek (a nyertesek és versenyzők értékelése és meghatározása). 


A személyes adatok fenti célú feldolgozásának jogalapja az, hogy a személyes adatok feldolgozása olyan szerződés teljesítéséhez szükséges, amelyben az érintett félként vesz részt. A szóban forgó célok mindegyike szükséges a szerződés teljesítéséhez, azonban kétség esetén az érintett ezúton hozzájárul a személyes adatok fenti célokból történő feldolgozásához. A személyes adatok ezen feldolgozása csak az adatkezelővel fennálló szerződéses kapcsolat megszűnése esetén vonható vissza. Az adatkezelésünk alapjául szolgáló szerződés az Ön adatainak regisztráció útján vagy a belső zónában történő megadásával jön létre.

Személyes adatokat csak a fenti célokkal összefüggésben adunk át az adatkezelőnél olyan felhatalmazott személyeknek, akik a személyes adatokat a fenti célokból történő feldolgozás alapján dolgozzák fel, valamint olyan adatfeldolgozóknak, akik az adatkezelő nevében, közvetítői szerződés alapján személyes adatokat dolgoznak fel.

Az érintett személyes adatait az adatkezelő a fenti célokkal összefüggésben a külön jogszabályokban meghatározott ideig, egyéb esetekben a szerződéses jogviszony fennállásáig őrzi meg.

A személyes adatok a fent említett célokkal összefüggésben nem továbbíthatók harmadik országba.

A személyes adatok megadása a fenti célokkal összefüggésben jogszabályi követelmény.

Az adatkezelő tájékoztatja az érintettet, hogy a fent említett célokkal összefüggésben automatizált döntéshozatalt és profilalkotást végez. Ennek keretében technikai művelet segítségével megállapítja, hogy a felhasználó a digitális oldalon mi iránt érdeklődött, és ennek alapján megállapítja továbbá, hogy az érdeklődés tranzakcióban nyilvánult-e meg, és ha nem, akkor a jövőben digitális formában tájékoztatást küld a felhasználónak egy esetleges tranzakció megkötéséről (pl. hírlevél, kereskedelmi vagy promóciós ajánlat e-mail vagy SMS stb. formájában). Ez a jobb felhasználói élmény biztosítása érdekében történik, és a termékek és szolgáltatások felajánlásán kívül semmilyen más kockázatot vagy következményt nem jelent az érintett számára.

Az érintett jogai a következők: 
a személyes adatokhoz való hozzáférés joga 
a személyes adatok helyesbítéséhez való jog 
a személyes adatainak törléséhez való jog (az elfeledtetéshez való jog) 
a személyes adatok feldolgozásának korlátozásához való jog 
a személyes adatai kezelése elleni tiltakozáshoz való jog 
a személyes adatok hordozhatóságához való jog 
a felügyeleti hatóságnál, azaz a Szlovák Köztársaság Személyes Adatvédelmi Hivatalánál történő panasztételhez való jog. 
Az automatizált egyedi döntéshozatal és profilalkotás elleni tiltakozás joga. 
A személyes adatok védelméről szóló törvény 100. szakasza szerinti kereset benyújtásának joga. 
A személyes adatok közléséhez adott hozzájárulás visszavonásához való jog 
A felelős személy elérhetőségei: 
Mgr. Mgr. Štefan Lazorčák 
E-mail: info@bilionbuy.com

[image1]: https://bbuy.fra1.cdn.digitaloceanspaces.com/terms/terms_hu.png`,
  };

  const terms = termsContent[languageKey] || termsContent["sk"]; // Default to 'sk' if language not found

  return (
    <>
      <BasicNavbar />
      <div className="text-center my-12">
        <h1 className="text-5xl font-semibold">Obchodné podmienky</h1>
      </div>
      <div className="quill-content max-w-5xl">
        <ReactMarkdown>{terms}</ReactMarkdown>
      </div>

      <Footer />
    </>
  );
};

export default TermsAndConditions;
