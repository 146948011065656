import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
  Spinner,
  TextField,
} from "@nextui-org/react";
import api from "../services/api";
import { toast } from "react-toastify";
import TagsSelector from "./TagsSelector"; // Import the TagsSelector component
import CountrySelector from "./CountrySelector";

const CreateShopButton = () => {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [formData, setFormData] = useState({});
  const [selectedTags, setSelectedTags] = useState([]); // State for tags
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [additionalCommissionGroups, setAdditionalCommissionGroups] = useState(
    []
  ); // State for commission groups
  const [isExpanded, setIsExpanded] = useState({ [-1]: false }); // State to handle the toggle of additional fields
  const scrollBehavior = "inside";
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  const countryOptions = [
    { value: "", label: "Vyberte krajinu" },
    { value: "sk", label: "Slovensko" },
    { value: "cz", label: "Česko" },
    { value: "hu", label: "Maďarsko" },
    { value: "pl", label: "Poľsko" },
    { value: "ro", label: "Rumunsko" },
    { value: "bg", label: "Bulharsko" },
    { value: "at", label: "Rakúsko" },
    { value: "de", label: "Nemecko" },
    { value: "it", label: "Taliansko" },
    { value: "fr", label: "Francúzsko" },
    { value: "es", label: "Španielsko" },
    { value: "hr", label: "Chorvátsko" },
    { value: "rs", label: "Srbsko" },
    { value: "si", label: "Slovinsko" },
    { value: "nl", label: "Holandsko" },
    { value: "be", label: "Belgicko" },
    { value: "pt", label: "Portugalsko" },
    { value: "gr", label: "Grécko" },
    { value: "se", label: "Švédsko" },
    { value: "dk", label: "Dánsko" },
    { value: "fi", label: "Fínsko" },
    { value: "no", label: "Nórsko" },
    { value: "ch", label: "Švajčiarsko" },
    { value: "ie", label: "Írsko" },
    { value: "uk", label: "Spojené kráľovstvo" },
    { value: "cn", label: "Čína" },
    { value: "ca", label: "Kanada" },
    { value: "us", label: "USA" },
  ];

  const handleCountryChange = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const country = event.target.value;
    setSelectedCountry(country);
    setFormData({ ...formData, country: country });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]:
        name === "has_feed"
          ? value === "true"
            ? true
            : value === "false"
              ? false
              : null
          : value,
    });
  };

  const handleTagsChange = (tagIDs) => {
    setSelectedTags(tagIDs);
    setFormData({ ...formData, shoptags: tagIDs }); // Sync formData with selected tags
  };

  const handleAddCommissionGroup = () => {
    setAdditionalCommissionGroups([
      ...additionalCommissionGroups,
      { name: "", value: "", pk: "", ok: "", sk: "" },
    ]);
    setIsExpanded({
      ...isExpanded,
      [additionalCommissionGroups.length]: false,
    }); // Add new expanded state for the new group
  };

  const toggleExpand = (index) => {
    setIsExpanded((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const handleRemoveCommissionGroup = (index) => {
    setAdditionalCommissionGroups(
      additionalCommissionGroups.filter((_, i) => i !== index)
    );
  };

  const handleCommissionGroupChange = (index, field, newValue) => {
    const updatedGroups = [...additionalCommissionGroups];
    updatedGroups[index][field] = newValue;
    setAdditionalCommissionGroups(updatedGroups);
  };

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      additional_countries: selectedCountries,
    }));
  }, [selectedCountries]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const dataToSend = {
      ...formData,
      additional_commision_groups: additionalCommissionGroups,
      additional_countries: selectedCountries,
      country: selectedCountry,
    };

    try {
      const response = await api.post(`create-shop`, dataToSend);

      // Check if the response status is in the 200-299 range
      if (response.status >= 200 && response.status < 300) {
        toast.success("Shop created successfully!");
        setSuccess(true);
        setFormData({}); // Clear the form data immediately on success
        onOpenChange(false); // Close the modal
      } else {
        throw new Error(`Unexpected response status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error creating shop:", error);
      toast.error(
        "Creation failed: " + (error.response?.data?.message || error.message)
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setFormData({}); // Clear the form data
    setSelectedTags([]); // Reset tags
    setAdditionalCommissionGroups([]); // Reset commission groups
    setIsExpanded({ [-1]: false }); // Reset the expanded state for commission groups
    setSuccess(false); // Reset success state
    setSelectedCountries([]);
    setSelectedCountry("");
    onOpenChange(false); // Close the modal
  };

  return (
    <>
      <Button className="bg-white border-1 rounded-lg" onPress={onOpen}>
        +
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={handleModalClose}
        size="5xl"
        scrollBehavior={scrollBehavior}
      >
        <ModalContent className="p-8">
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1">
                Add Shop
              </ModalHeader>
              <ModalBody>
                <form onSubmit={handleSubmit} className="space-y-4 w-full">
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Názov:
                      <input
                        type="text"
                        name="name"
                        value={formData.name || ""}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  <div>
                    Pôvod
                    <select
                      name="origin"
                      value={formData.origin || ""}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value=""></option>
                      <option value="bilionbuy">bilionbuy</option>
                      <option value="ehub">ehub</option>
                      <option value="affial">affial</option>
                      <option value="dognet">dognet</option>
                      <option value="cj">cj</option>
                      <option value="affiliateport">affiliateport</option>
                      <option value="mylead">mylead</option>
                    </select>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Typ obchodu:
                      <select
                        name="shop_type"
                        value={formData.shop_type || ""}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      >
                        <option value="">-</option>
                        <option value="Shopping">Shopping</option>
                        <option value="Travel">Travel</option>
                        <option value="Food">Food</option>
                        <option value="Second Hand">Second Hand</option>
                        <option value="Services">Services</option>
                      </select>
                    </label>
                  </div>
                  <div>
                    Status (agent)
                    <select
                      name="agent_status"
                      value={formData.agent_status || 6}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    >
                      <option value="1">Chceme osloviť</option>
                      <option value="2">Oslovený</option>
                      <option value="3">Požiadané - affiliate</option>
                      <option value="4">Dohodnuté - zaregistrované</option>
                      <option value="5">Dohodnuté - affiliate</option>
                      <option value="6"></option>
                    </select>
                  </div>
                  <div>
                    Kampaňový link
                    <input
                      type="text"
                      name="campaing_link"
                      value={formData.campaing_link || ""}
                      onChange={handleInputChange}
                      className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Logo URL:
                      <input
                        type="text"
                        name="logo_url"
                        value={formData.logo_url || ""}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Web URL:
                      <input
                        type="text"
                        name="web_url"
                        value={formData.web_url || ""}
                        onChange={handleInputChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  <div>
                    <label className=" text-sm font-medium text-gray-700 flex flex-row gap-2 items-center">
                      Má feed?:
                      <select
                        className="mt-1 block w-16 px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        name="has_feed"
                        value={
                          formData.has_feed === true
                            ? "true"
                            : formData.has_feed === false
                              ? "false"
                              : ""
                        }
                        onChange={(e) =>
                          handleInputChange({
                            target: {
                              name: "has_feed",
                              value: e.target.value,
                            },
                          })
                        }
                      >
                        <option value=""></option> {/* Blank option for null */}
                        <option value="true">Ano</option>{" "}
                        {/* Option for true */}
                        <option value="false">Nie</option>{" "}
                        {/* Option for false */}
                      </select>
                    </label>
                  </div>
                  {formData.has_feed && (
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        XML Feed URL:
                        <input
                          type="text"
                          name="feed_url"
                          value={formData.feed_url || ""}
                          onChange={handleInputChange}
                          className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                      </label>
                    </div>
                  )}
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                      Krajina:
                    </label>
                    <div>
                      <select
                        value={selectedCountry}
                        onChange={handleCountryChange}
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      >
                        {countryOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div>
                    {/* Add the CountrySelector component for additional countries */}
                    <div>
                      <label className="block text-sm font-medium text-gray-700">
                        Ďalšie zobrazovacie krajiny:
                      </label>
                      <CountrySelector
                        selectedCountries={selectedCountries}
                        setSelectedCountries={setSelectedCountries}
                      />
                    </div>
                  </div>
                  <div>
                    {/* Tag Selection */}
                    <div>
                      <div>Tagy</div>
                      <div className="text-xs text-lightgray"></div>
                      <TagsSelector
                        selectedTags={selectedTags}
                        setSelectedTags={handleTagsChange}
                      />
                    </div>
                  </div>
                  {/* Commission Groups Section */}
                  <div className="my-4 flex flex-col gap-3">
                    <div className="font-semibold mb-4">Provízne skupiny:</div>

                    {/* Default Cashback (Ostatné) */}
                    <div className="flex flex-col gap-2 mb-2">
                      <div className="flex items-center gap-4">
                        <input
                          type="text"
                          value="Ostatné"
                          disabled
                          className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                        <input
                          type="text"
                          name="default_cashback"
                          value={formData.default_cashback || ""}
                          onChange={handleInputChange}
                          placeholder="Hodnota"
                          className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                        />
                        <button
                          type="button"
                          onClick={() => toggleExpand(-1)}
                          className="bg-primary text-white px-2 py-1 rounded-md"
                        >
                          {isExpanded[-1] ? "Skryť" : "Viac"}
                        </button>
                      </div>

                      {isExpanded[-1] && (
                        <div className="flex items-center gap-4 mt-2">
                          <input
                            type="text"
                            name="shop_direct_coeff"
                            value={formData.shop_direct_coeff || ""}
                            onChange={handleInputChange}
                            placeholder="Priamy koeficient"
                            className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          />
                          <input
                            type="text"
                            name="shop_refferal_coeff"
                            value={formData.shop_refferal_coeff || ""}
                            onChange={handleInputChange}
                            placeholder="Odporúčateľský koeficient"
                            className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          />
                          <input
                            type="text"
                            name="shop_ml_coeff"
                            value={formData.shop_ml_coeff || ""}
                            onChange={handleInputChange}
                            placeholder="Štruktúrny koeficient"
                            className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                          />
                        </div>
                      )}
                    </div>

                    {/* Existing Commission Groups */}
                    {Array.isArray(additionalCommissionGroups) &&
                    additionalCommissionGroups.length > 0 ? (
                      additionalCommissionGroups.map((group, index) => (
                        <div key={index} className="flex flex-col gap-2 mb-2">
                          <div className="flex items-center gap-4">
                            <input
                              type="text"
                              value={group.name}
                              onChange={(e) =>
                                handleCommissionGroupChange(
                                  index,
                                  "name",
                                  e.target.value
                                )
                              }
                              placeholder="Skupina"
                              className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                            <input
                              type="text"
                              value={group.value}
                              onChange={(e) =>
                                handleCommissionGroupChange(
                                  index,
                                  "value",
                                  e.target.value
                                )
                              }
                              placeholder="Hodnota"
                              className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                            />
                            <button
                              type="button"
                              onClick={() => toggleExpand(index)}
                              className="bg-primary text-white px-2 py-1 rounded-md"
                            >
                              {isExpanded[index] ? "Skryť" : "Viac"}
                            </button>
                            <button
                              type="button"
                              onClick={() => handleRemoveCommissionGroup(index)}
                              className="bg-red-500 text-white px-2 py-1 rounded-md"
                            >
                              -
                            </button>
                          </div>

                          {isExpanded[index] && (
                            <div className="flex items-center gap-4 mt-2">
                              <input
                                type="text"
                                value={group.pk}
                                onChange={(e) =>
                                  handleCommissionGroupChange(
                                    index,
                                    "pk",
                                    e.target.value
                                  )
                                }
                                placeholder="Priamy koeficient"
                                className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                              <input
                                type="text"
                                value={group.ok}
                                onChange={(e) =>
                                  handleCommissionGroupChange(
                                    index,
                                    "ok",
                                    e.target.value
                                  )
                                }
                                placeholder="Odporúčateľský koeficient"
                                className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                              <input
                                type="text"
                                value={group.sk}
                                onChange={(e) =>
                                  handleCommissionGroupChange(
                                    index,
                                    "sk",
                                    e.target.value
                                  )
                                }
                                placeholder="Štruktúrny koeficient"
                                className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                              />
                            </div>
                          )}
                        </div>
                      ))
                    ) : (
                      <p className="text-sm text-gray-500">
                        Zatiaľ neboli pridané žiadne ďalšie provízne skupiny
                      </p>
                    )}

                    {/* Add Group Button */}
                    <Button
                      type="button"
                      onClick={handleAddCommissionGroup}
                      className="bg-primary text-white px-4 py-2 rounded-md mt-2 w-[200px]"
                    >
                      Pridať skupinu
                    </Button>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Popis:
                      <textarea
                        name="description"
                        value={formData.description || ""}
                        onChange={handleInputChange}
                        rows="3"
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-700">
                      Ďalšie informácie:
                      <textarea
                        name="additional_admin_info"
                        value={formData.additional_admin_info || ""}
                        onChange={handleInputChange}
                        rows="3"
                        className="mt-1 block w-full px-4 py-2 text-sm placeholder-gray-500 border rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
                      />
                    </label>
                  </div>
                  <div className="flex gap-2 font-bold">
                    <Button
                      type="submit"
                      className="px-4 py-2 bg-white text-success text-base rounded-md w-full shadow-sm focus:outline-none ring-2 ring-success"
                    >
                      {isLoading ? (
                        <Spinner color="success" size="sm" />
                      ) : (
                        "Uložiť"
                      )}
                    </Button>
                    <Button
                      onPress={onClose}
                      className="px-4 py-2 bg-white text-danger text-base rounded-md w-full shadow-sm focus:outline-none ring-2 ring-danger"
                    >
                      Zrušiť
                    </Button>
                  </div>
                </form>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateShopButton;
