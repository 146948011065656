import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import api from "../services/api";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const UsersSelector = ({ selectedUsers = [], onChange }) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Predefined groups
  const groupOptions = [
    { id: "all_users", email: t("Všetci používatelia"), isGroup: true },
  ];

  useEffect(() => {
    // Always start with group options
    setOptions(groupOptions);

    // Initialize selected options based on selectedUsers prop
    if (selectedUsers.includes("all_users")) {
      setSelectedOptions(groupOptions);
    } else {
      setSelectedOptions([]);
    }
  }, []);

  const handleSearch = async (searchTerm) => {
    if (!searchTerm) {
      setOptions(groupOptions);
      return;
    }

    try {
      const response = await api.get("fetch-users/", {
        params: {
          search: searchTerm,
          limit: 10,
        },
      });
      const userOptions = response.data.map((user) => ({
        id: user.id,
        email: user.email,
        isGroup: false,
      }));
      setOptions([...groupOptions, ...userOptions]);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length >= 3) {
      handleSearch(newInputValue);
    } else {
      setOptions(groupOptions);
    }
  };

  const handleChange = (event, newValue) => {
    // Check if the new selection includes the group option
    const hasGroup = newValue.some((option) => option.isGroup);

    if (hasGroup) {
      // If group is selected, clear other selections
      const groupSelection = groupOptions;
      setSelectedOptions(groupSelection);
      setOptions(groupOptions);
      onChange({ type: "group", value: "all_users" });
    } else {
      // For individual users
      const individualUsers = newValue.filter((option) => !option.isGroup);
      setSelectedOptions(individualUsers);
      onChange({
        type: "users",
        value: individualUsers.map((user) => user.id),
      });
    }
  };

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option.email}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterSelectedOptions={false}
      disableCloseOnSelect={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t("Používatelia")}
          variant="outlined"
          helperText={t(
            "Zadajte aspoň 3 znaky pre vyhľadávanie alebo vyberte skupinu"
          )}
        />
      )}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <Chip
            key={option.id}
            label={option.email}
            {...getTagProps({ index })}
            color={option.isGroup ? "primary" : "default"}
          />
        ))
      }
      renderOption={(props, option) => (
        <li
          {...props}
          key={option.id}
          style={option.isGroup ? { fontWeight: "bold" } : {}}
        >
          {option.email}
        </li>
      )}
      noOptionsText={t("Žiadne výsledky")}
      loadingText={t("Načítavam...")}
    />
  );
};

UsersSelector.propTypes = {
  selectedUsers: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onChange: PropTypes.func.isRequired,
};

export default UsersSelector;
