import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  Pagination,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import api from "../../services/api";

export default function WalletPayouts({ EmptyState }) {
  const { t } = useTranslation();
  const [payouts, setPayouts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const fetchPayouts = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/get-user-wallet-bills/?page=${pageNum}`);
      console.log("API Response:", response.data); // Debug log

      const results = response.data.results || response.data;
      const count =
        response.data.count ||
        (Array.isArray(response.data) ? response.data.length : 0);

      setPayouts(results);
      setTotalPages(Math.ceil(count / 10));

      console.log("Processed payouts:", results); // Debug log
    } catch (error) {
      console.error("Error fetching payouts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPayouts(page);
  }, [page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleDownload = (fileLink) => {
    if (!fileLink) return;
    window.open(fileLink, "_blank");
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const formatAmount = (amount, currency) => {
    return `${Math.abs(parseFloat(amount)).toFixed(2)} ${currency}`;
  };

  const getStatusDisplay = (status) => {
    if (!status) return t("Čaká na spracovanie");

    switch (status.toLowerCase()) {
      case "pending":
        return t("Na vyplatenie");
      case "completed":
        return t("Vyplatené");
      default:
        return status;
    }
  };

  if (isLoading && payouts.length === 0) {
    return <EmptyState message={t("Načítavam...")} />;
  }

  if (!payouts || payouts.length === 0) {
    return <EmptyState message={t("Žiadne výplaty")} />;
  }

  return (
    <div>
      <Typography variant="h5" component="h2" className="mb-4">
        {t("História výplat na bankový účet")}
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: "bold" }}>{t("Dátum")}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{t("Číslo")}</TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{t("Suma")}</TableCell>
              <TableCell style={{ fontWeight: "bold", textAlign: "center" }}>
                {t("Vyúčtovanie")}
              </TableCell>
              <TableCell style={{ fontWeight: "bold" }}>{t("Stav")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payouts.map((payout) => (
              <TableRow key={payout.id}>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {formatDate(payout.date)}
                </TableCell>
                <TableCell>{payout.number || "-"}</TableCell>
                <TableCell>
                  {formatAmount(payout.amount, payout.currency)}
                </TableCell>
                <TableCell align="center">
                  {payout.pdf_link ? (
                    <Button
                      variant="text"
                      color="primary"
                      size="small"
                      onClick={() => handleDownload(payout.pdf_link)}
                    >
                      {t("STIAHNUŤ")}
                    </Button>
                  ) : (
                    "-"
                  )}
                </TableCell>
                <TableCell>
                  <span
                    className={
                      payout.status?.toLowerCase() === "completed"
                        ? "text-green-600"
                        : "text-orange-500"
                    }
                  >
                    {getStatusDisplay(payout.status)}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box className="flex justify-center mt-4">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>
    </div>
  );
}
