import { Button, Link } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { withAdultContent } from "./hoc/withAdultContent";

const ProductWithImageCard = ({ shop, productData, onRegisterClick }) => {
  const { t } = useTranslation();

  return (
    <div className="px-2 pb-2 flex justify-center items-center h-full scale-85 md:scale-100">
      <div className="flex flex-col justify-between items-center shadow-md border-1 relative rounded-lg bg-white w-48 h-full">
        <div className="flex flex-col gap-4 transition duration-300 min-w-48 w-48 h-full">
          {/* Product Image */}
          <div className="rounded-xl relative flex justify-center items-center w-full h-32">
            <img
              src={shop?.img_url || productData?.product_image_url || ""}
              alt={shop.shop_name}
              className="w-full h-full rounded-t-lg border-1 border-b-0 object-cover"
              loading="lazy"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "";
              }}
            />
            <a className="bg-white text-white font-semibold p-1 rounded-lg absolute top-3 z-10 right-2">
              <img
                src={shop.shop_image}
                alt={shop.shop_name}
                className="w-12"
              />
            </a>
          </div>

          {/* Shop Details */}
          <div className="flex flex-col gap-2 px-4">
            {/* Price */}
            <div className="font-bold text-sm">
              {shop.price_vat?.replace(".", ",")}{" "}
              {shop.currency?.replace("EUR", "€")}
            </div>

            {/* Shop Name */}
            <div className="font-bold text-[#6B6B70] line-clamp-1 text-sm">
              {shop.shop_name}
            </div>

            {/* Cashback Info */}
            {shop.computed_cashback > 0 && (
              <div className="text-xs text-primary font-semibold">
                Cashback{" "}
                {(
                  shop.computed_cashback *
                  parseFloat(shop.price_vat.replace(/[^\d.]/g, ""))
                ).toFixed(2)}{" "}
                {shop.currency?.replace("EUR", "€")}{" "}
              </div>
            )}
          </div>
        </div>

        {/* Button */}
        <div className="w-full">
          <div className="flex justify-center text-xs pb-2">
            <Button
              className="bg-primary text-white rounded-sm px-8 scale-90 w-full"
              onClick={() =>
                onRegisterClick(shop.final_url, shop.id, shop.shop_name)
              }
            >
              {t("Do obchodu")} &nbsp; &nbsp; &rarr;
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAdultContent(ProductWithImageCard);
