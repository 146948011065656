import React, { useState, useEffect } from "react";
import {
  Box,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Paper,
  Button,
  Divider,
  IconButton,
  TextField,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Stack,
  Grid,
  Link,
  Chip,
  CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import PersonIcon from "@mui/icons-material/Person";
import PaymentIcon from "@mui/icons-material/Payment";
import VerifiedIcon from "@mui/icons-material/Verified";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import { useFormik } from "formik";
import * as Yup from "yup";
import { OrderSuccess } from "./OrderSuccess";
import InventoryIcon from "@mui/icons-material/Inventory";
import { cartService } from "../../services/cartService";
import { useSnackbar } from "notistack";
import api from "../../services/api";
import { loadStripe } from "@stripe/stripe-js";

const steps = [
  { label: "Košík", icon: <ShoppingCartIcon /> },
  { label: "Doprava", icon: <LocalShippingIcon /> },
  { label: "Dodacie údaje", icon: <PersonIcon /> },
  { label: "Platba", icon: <PaymentIcon /> },
  { label: "Kontrola", icon: <FactCheckIcon /> },
];

const deliveryOptions = [{ id: "dpd", name: "DPD Kuriér", price: 0.0 }];

const paymentMethods = [
  {
    id: "card",
    name: "Platba kartou",
    price: 0,
    icons: ["visa", "mastercard"],
  },
  { id: "transfer", name: "Platba prevodom", price: 0 },
  { id: "paypal", name: "PayPal", price: 0 },
  { id: "gpay", name: "Google Pay", price: 0 },
  { id: "applepay", name: "Apple Pay", price: 0 },
  { id: "tatrapay", name: "Tatra Pay", price: 0 },
];

const deliveryValidationSchema = Yup.object({
  firstName: Yup.string().required("Povinné pole"),
  lastName: Yup.string().required("Povinné pole"),
  phone: Yup.string()
    .matches(/^[0-9+\s-]{9,}$/, "Neplatné telefónne číslo")
    .required("Povinné pole"),
  street: Yup.string().required("Povinné pole"),
  city: Yup.string().required("Povinné pole"),
  postalCode: Yup.string()
    .matches(/^\d{3}\s?\d{2}$/, "Neplatné PSČ")
    .required("Povinné pole"),
  email: Yup.string().email("Neplatný email").required("Povinné pole"),
  isCompany: Yup.boolean(),
  companyName: Yup.string().when("isCompany", {
    is: true,
    then: () => Yup.string().required("Povinné pole"),
    otherwise: () => Yup.string(),
  }),
  ico: Yup.string().when("isCompany", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{8}$/, "Neplatné IČO")
        .required("Povinné pole"),
    otherwise: () => Yup.string(),
  }),
  dic: Yup.string().when("isCompany", {
    is: true,
    then: () =>
      Yup.string()
        .matches(/^\d{10}$/, "Neplatné DIČ")
        .required("Povinné pole"),
    otherwise: () => Yup.string(),
  }),
  ic_dph: Yup.string().when("isCompany", {
    is: true,
    then: () =>
      Yup.string().matches(
        /^SK\d{10}$/,
        "Neplatné IČ DPH (formát: SK0123456789)"
      ),
    otherwise: () => Yup.string(),
  }),
});

const DeliveryDetailsForm = ({ onFormChange }) => {
  const [profileData, setProfileData] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        setLoadingProfile(true);
        const response = await api.get("/get-user-data/");
        const userData = response.data;

        const hasCompanyData = Boolean(
          userData.billing_data?.company &&
            userData.billing_data?.company_id &&
            userData.billing_data?.tax_id
        );

        setProfileData({
          first_name: userData.first_name,
          last_name: userData.last_name,
          email: userData.email,
          phone: userData.phone_number,
          street: userData.address,
          city: userData.city,
          postal_code: userData.zip_code,
          ...(hasCompanyData && {
            company_name: userData.billing_data.company,
            ico: userData.billing_data.company_id,
            dic: userData.billing_data.tax_id,
            ic_dph: userData.billing_data.vat_id || "",
          }),
        });
      } catch (error) {
        console.error("Failed to fetch profile data:", error);
        setProfileData(null);
        enqueueSnackbar("Nepodarilo sa načítať údaje profilu", {
          variant: "error",
        });
      } finally {
        setLoadingProfile(false);
      }
    };

    fetchProfile();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      street: "",
      city: "",
      postalCode: "",
      isCompany: false,
      companyName: "",
      ico: "",
      dic: "",
      ic_dph: "",
      useProfileData: false,
    },
    validationSchema: deliveryValidationSchema,
    onSubmit: (values, { setSubmitting }) => {
      onFormChange(values);
      setSubmitting(false);
    },
  });

  const handleUseProfileData = (event) => {
    if (event.target.checked) {
      formik.setValues({
        ...formik.values,
        firstName: profileData?.first_name || "",
        lastName: profileData?.last_name || "",
        phone: profileData?.phone || "",
        email: profileData?.email || "",
        street: profileData?.street || "",
        city: profileData?.city || "",
        postalCode: profileData?.postal_code || "",
        isCompany: Boolean(profileData?.company_name),
        companyName: profileData?.company_name || "",
        ico: profileData?.ico || "",
        dic: profileData?.dic || "",
        ic_dph: profileData?.ic_dph || "",
        useProfileData: true,
      });
    } else {
      formik.setValues({
        ...formik.initialValues,
        isCompany: formik.values.isCompany,
      });
    }
  };

  return (
    <Box
      component="form"
      onSubmit={(e) => {
        e.preventDefault();
        formik.handleSubmit(e);
      }}
    >
      {profileData && (
        <FormControlLabel
          control={
            <Checkbox
              checked={formik.values.useProfileData}
              onChange={handleUseProfileData}
              disabled={loadingProfile}
            />
          }
          label={
            loadingProfile ? (
              <Box display="flex" alignItems="center" gap={1}>
                <CircularProgress size={16} />
                Načítavam profil...
              </Box>
            ) : (
              "Použiť údaje z profilu"
            )
          }
          sx={{ mb: 3 }}
        />
      )}

      <FormControlLabel
        control={
          <Checkbox
            checked={formik.values.isCompany}
            onChange={(e) => {
              formik.setFieldValue("isCompany", e.target.checked);
            }}
          />
        }
        label="Fakturácia na firmu"
        sx={{ mb: 3, display: "block" }}
      />

      {formik.values.isCompany && (
        <Grid container spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="companyName"
              label="Názov spoločnosti"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="ico"
              label="IČO"
              value={formik.values.ico}
              onChange={formik.handleChange}
              error={formik.touched.ico && Boolean(formik.errors.ico)}
              helperText={formik.touched.ico && formik.errors.ico}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              name="dic"
              label="DIČ"
              value={formik.values.dic}
              onChange={formik.handleChange}
              error={formik.touched.dic && Boolean(formik.errors.dic)}
              helperText={formik.touched.dic && formik.errors.dic}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              name="ic_dph"
              label="IČ DPH"
              value={formik.values.ic_dph}
              onChange={formik.handleChange}
              error={formik.touched.ic_dph && Boolean(formik.errors.ic_dph)}
              helperText={formik.touched.ic_dph && formik.errors.ic_dph}
              placeholder="SK0123456789"
            />
          </Grid>
        </Grid>
      )}

      <Typography variant="h6" sx={{ mb: 3 }}>
        Kontaktné údaje
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="firstName"
            label="Meno"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="lastName"
            label="Priezvisko"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="phone"
            label="Telefón"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="email"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" sx={{ mb: 3, mt: 4 }}>
        Adresa dodania
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="street"
            label="Ulica a číslo"
            value={formik.values.street}
            onChange={formik.handleChange}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="city"
            label="Mesto"
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            name="postalCode"
            label="PSČ"
            value={formik.values.postalCode}
            onChange={formik.handleChange}
            error={
              formik.touched.postalCode && Boolean(formik.errors.postalCode)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const PaymentMethodSelection = ({
  cart,
  calculateTotal,
  selectedPayment,
  setSelectedPayment,
  termsAccepted,
  setTermsAccepted,
  termsError,
  cartTotals,
}) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" sx={{ mb: 3 }}>
        Vybrať platobnú metódu
      </Typography>

      <RadioGroup
        value={selectedPayment}
        onChange={(e) => setSelectedPayment(e.target.value)}
      >
        <Paper
          sx={{
            mb: 2,
            p: 2,
            border: selectedPayment === "card" ? "2px solid #1976d2" : "none",
          }}
        >
          <FormControlLabel
            value="card"
            control={<Radio />}
            label={
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Box display="flex" gap={1}>
                    <img src="/visa-logo.png" alt="Visa" height="24" />
                    <img
                      src="/mastercard-logo.png"
                      alt="Mastercard"
                      height="24"
                    />
                  </Box>
                  <Box>
                    <Typography>Online platba kartou</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Rýchla a bezpečná platba
                    </Typography>
                  </Box>
                </Box>
                <Typography color="success.main">Zadarmo</Typography>
              </Box>
            }
          />
        </Paper>

        <Paper
          sx={{
            mb: 2,
            p: 2,
            border:
              selectedPayment === "transfer" ? "2px solid #1976d2" : "none",
          }}
        >
          <FormControlLabel
            value="transfer"
            control={<Radio />}
            label={
              <Box
                display="flex"
                justifyContent="space-between"
                width="100%"
                alignItems="center"
              >
                <Box display="flex" alignItems="center" gap={2}>
                  <Box>
                    <Typography>Bankový prevod</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Platba prevodom na účet
                    </Typography>
                  </Box>
                </Box>
                <Typography color="success.main">Zadarmo</Typography>
              </Box>
            }
          />
        </Paper>
      </RadioGroup>

      <Paper sx={{ p: 2, mt: 4 }}>
        <Typography variant="h6" gutterBottom>
          Súhrn objednávky
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>Suma bez DPH:</Typography>
            <Typography>
              {(calculateTotal() - cartTotals?.total_vat).toFixed(2)}€
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={1}>
            <Typography>DPH (20%):</Typography>
            <Typography>{cartTotals?.total_vat.toFixed(2)}€</Typography>
          </Box>
          <Divider sx={{ my: 1 }} />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="h6">Celkom na úhradu:</Typography>
            <Typography variant="h6" color="primary">
              {calculateTotal().toFixed(2)}€
            </Typography>
          </Box>
        </Box>
      </Paper>

      <Box sx={{ mt: 3 }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
            />
          }
          label={
            <Box component="span">
              Súhlasím s{" "}
              <Link
                href="/obchodne-podmienky"
                target="_blank"
                underline="hover"
              >
                obchodnými podmienkami
              </Link>
            </Box>
          }
        />
        {termsError && (
          <Typography
            color="error"
            variant="caption"
            sx={{ display: "block", mt: 1 }}
          >
            Pre pokračovanie musíte súhlasiť s obchodnými podmienkami
          </Typography>
        )}
      </Box>
    </Box>
  );
};

const generateOrderNumber = () => {
  const date = new Date();
  const year = date.getFullYear().toString().slice(-2);
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const orderCount = Math.floor(Math.random() * 999999)
    .toString()
    .padStart(6, "0");
  return `${year}${month}${orderCount}`;
};

const OrderReview = ({
  cart,
  cartTotals,
  deliveryDetails,
  selectedPayment,
  selectedDelivery,
}) => {
  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        Kontrola objednávky
      </Typography>

      {/* Delivery Details Summary */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Dodacie údaje
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="text.secondary">
              Meno a priezvisko
            </Typography>
            <Typography>
              {deliveryDetails.firstName} {deliveryDetails.lastName}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" color="text.secondary">
              Kontakt
            </Typography>
            <Typography>{deliveryDetails.phone}</Typography>
            <Typography>{deliveryDetails.email}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" color="text.secondary">
              Adresa doručenia
            </Typography>
            <Typography>{deliveryDetails.street}</Typography>
            <Typography>
              {deliveryDetails.postalCode} {deliveryDetails.city}
            </Typography>
          </Grid>
          {deliveryDetails.isCompany && (
            <Grid item xs={12}>
              <Typography variant="body2" color="text.secondary">
                Fakturačné údaje
              </Typography>
              <Typography>{deliveryDetails.companyName}</Typography>
              <Typography>IČO: {deliveryDetails.ico}</Typography>
              <Typography>DIČ: {deliveryDetails.dic}</Typography>
              {deliveryDetails.ic_dph && (
                <Typography>IČ DPH: {deliveryDetails.ic_dph}</Typography>
              )}
            </Grid>
          )}
        </Grid>
      </Paper>

      {/* Payment Method */}
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Spôsob platby
        </Typography>
        <Typography>
          {selectedPayment === "card"
            ? "Online platba kartou"
            : "Bankový prevod"}
        </Typography>
      </Paper>

      {/* Order Summary */}
      <Paper sx={{ p: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          Súhrn objednávky
        </Typography>
        {cart.map((item) => (
          <Box
            key={item.id}
            display="flex"
            justifyContent="space-between"
            mb={1}
          >
            <Typography>
              {item.name} ({item.quantity}x)
            </Typography>
            <Typography>{(item.price * item.quantity).toFixed(2)}€</Typography>
          </Box>
        ))}
        <Divider sx={{ my: 2 }} />
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography>Suma bez DPH:</Typography>
          <Typography>
            {(cartTotals.total_amount - cartTotals.total_vat).toFixed(2)}€
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mb={1}>
          <Typography>DPH (20%):</Typography>
          <Typography>{cartTotals.total_vat.toFixed(2)}€</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">Celkom na úhradu:</Typography>
          <Typography variant="h6" color="primary">
            {cartTotals.total_amount.toFixed(2)}€
          </Typography>
        </Box>
      </Paper>
    </Box>
  );
};

export default function Cart() {
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDelivery, setSelectedDelivery] = useState("dpd");
  const [selectedPayment, setSelectedPayment] = useState("card");
  const [cart, setCart] = useState(null);
  const [deliveryDetails, setDeliveryDetails] = useState(null);
  const [orderComplete, setOrderComplete] = useState(false);
  const [orderNumber, setOrderNumber] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsError, setTermsError] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [cartTotals, setCartTotals] = useState(null);
  const [initialLoading, setInitialLoading] = useState(true);
  const [updatingItemId, setUpdatingItemId] = useState(null);

  useEffect(() => {
    fetchCart(true);
  }, []);

  const fetchCart = async (isInitial = false) => {
    try {
      if (isInitial) {
        setInitialLoading(true);
      }
      const response = await cartService.getCart();
      const activeCart = response[0];
      if (activeCart) {
        const transformedItems = activeCart.items.map((item) => ({
          id: item.id,
          name: item.product_name,
          price: parseFloat(item.total_price) / item.quantity,
          total_price: parseFloat(item.total_price),
          quantity: item.quantity,
          image: item.shopping_product.product_image_url,
          vat_amount: parseFloat(item.vat_amount) / item.quantity,
          points: item.points,
          cashback: item.cashback,
          product_id: item.shopping_product.id,
          slug: item.shopping_product.slug,
        }));
        setCart(transformedItems);
        setCartTotals({
          total_amount: parseFloat(activeCart.total_amount),
          total_vat: parseFloat(activeCart.total_vat),
        });
      } else {
        setCart([]);
        setCartTotals(null);
      }
    } catch (error) {
      enqueueSnackbar("Nepodarilo sa načítať košík", { variant: "error" });
      setCart([]);
      setCartTotals(null);
    } finally {
      if (isInitial) {
        setInitialLoading(false);
      }
    }
  };

  const handleDeliveryFormChange = (values) => {
    setDeliveryDetails(values);
    setActiveStep((prev) => prev + 1);
  };

  const handleNext = () => {
    if (activeStep === 2) {
      const deliveryForm = document.querySelector("form");
      if (deliveryForm) {
        const submitEvent = new Event("submit", {
          bubbles: true,
          cancelable: true,
        });
        deliveryForm.dispatchEvent(submitEvent);
      }
      return;
    }
    setActiveStep((prev) => prev + 1);
  };

  const handleBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const handleQuantityChange = async (id, change) => {
    try {
      setUpdatingItemId(id);
      const item = cart.find((item) => item.id === id);
      const newQuantity = Math.max(1, item.quantity + change);

      await cartService.updateQuantity(id, newQuantity);
      await fetchCart();
    } catch (error) {
      enqueueSnackbar("Nepodarilo sa aktualizovať množstvo", {
        variant: "error",
      });
    } finally {
      setUpdatingItemId(null);
    }
  };

  const handleRemoveItem = async (id) => {
    try {
      setUpdatingItemId(id);
      await cartService.removeItem(id);
      await fetchCart();
    } catch (error) {
      enqueueSnackbar("Nepodarilo sa odstrániť položku", { variant: "error" });
    } finally {
      setUpdatingItemId(null);
    }
  };

  const calculateTotal = () => {
    if (!cartTotals) return 0;
    return cartTotals.total_amount;
  };

  const calculateVAT = () => {
    if (!cartTotals) return { withoutVAT: 0, vatAmount: 0, total: 0 };

    return {
      withoutVAT: cartTotals.total_amount - cartTotals.total_vat,
      vatAmount: cartTotals.total_vat,
      total: cartTotals.total_amount,
    };
  };

  const renderCartItems = () => {
    if (!cart?.length) {
      return (
        <Paper sx={{ p: 4, textAlign: "center" }}>
          <Typography variant="h6" gutterBottom>
            Váš košík je prázdny
          </Typography>
          <Typography color="text.secondary">
            Pridajte produkty do košíka pre pokračovanie v nákupe
          </Typography>
        </Paper>
      );
    }

    return (
      <div className="space-y-4">
        <Paper sx={{ p: 2, bgcolor: "white", mb: 3 }}>
          <Box display="flex" gap={2} alignItems="center">
            <VerifiedIcon color="primary" />
            <Typography>
              Garancia spokojnosti: Vaša spokojnosť je pre nás prvoradá, preto,
              ak nedodržíme dobu dodania, získate od nás ako ospravedlnenie 5
              EUR bonus na ďalší nákup
            </Typography>
          </Box>
        </Paper>

        {cart.map((item) => (
          <Paper key={item.id} sx={{ p: 2 }}>
            <Box display="flex" justifyContent="space-between">
              <Box display="flex" gap={2}>
                <img
                  src={item.image}
                  alt={item.name}
                  style={{ width: 80, height: 80, objectFit: "contain" }}
                />
                <Box>
                  <Typography variant="h6">{item.name}</Typography>
                  <Box display="flex" gap={2} mt={1}>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Body
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="primary"
                      >
                        {item.points.toFixed(3)}
                      </Typography>
                    </Box>
                    <Box>
                      <Typography variant="caption" color="text.secondary">
                        Cashback
                      </Typography>
                      <Typography
                        variant="body2"
                        fontWeight="bold"
                        color="success.main"
                      >
                        {item.cashback.toFixed(2)}€
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box>
                <Box display="flex" alignItems="center" gap={1}>
                  <IconButton
                    onClick={() => handleQuantityChange(item.id, -1)}
                    disabled={updatingItemId === item.id}
                  >
                    <RemoveIcon />
                  </IconButton>
                  <Typography>
                    {updatingItemId === item.id ? (
                      <CircularProgress size={20} />
                    ) : (
                      item.quantity
                    )}
                  </Typography>
                  <IconButton
                    onClick={() => handleQuantityChange(item.id, 1)}
                    disabled={updatingItemId === item.id}
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    onClick={() => handleRemoveItem(item.id)}
                    disabled={updatingItemId === item.id}
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Box textAlign="right" mt={1}>
                  <Typography variant="body2" color="text.secondary">
                    Bez DPH: {(item.price - item.vat_amount).toFixed(2)}€
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    DPH: {item.vat_amount.toFixed(2)}€
                  </Typography>
                  <Typography variant="h6" color="primary">
                    {item.price.toFixed(2)}€ × {item.quantity} ={" "}
                    {(item.price * item.quantity).toFixed(2)}€
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Získate: {item.points.toFixed(3)} × {item.quantity} ={" "}
                    {(item.points * item.quantity).toFixed(3)} bodov
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    Cashback: {item.cashback.toFixed(2)}€ × {item.quantity} ={" "}
                    {(item.cashback * item.quantity).toFixed(2)}€
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Paper>
        ))}

        <Paper sx={{ p: 2, mt: 3 }}>
          <Typography variant="h6" gutterBottom>
            Súhrn objednávky
          </Typography>
          <Box sx={{ mt: 2 }}>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>Suma bez DPH:</Typography>
              <Typography>
                {(cartTotals?.total_amount - cartTotals?.total_vat).toFixed(2)}€
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" mb={1}>
              <Typography>DPH (20%):</Typography>
              <Typography>{cartTotals?.total_vat.toFixed(2)}€</Typography>
            </Box>
            <Divider sx={{ my: 1 }} />
            <Box display="flex" justifyContent="space-between" mb={2}>
              <Typography variant="h6">Celkom s DPH:</Typography>
              <Typography variant="h6" color="primary">
                {cartTotals?.total_amount.toFixed(2)}€
              </Typography>
            </Box>
            <Box bgcolor="grey.100" p={2} borderRadius={1}>
              <Typography variant="subtitle2" gutterBottom>
                Z tejto objednávky získate:
              </Typography>
              <Box display="flex" justifyContent="space-between">
                <Typography>Body:</Typography>
                <Typography color="primary" fontWeight="bold">
                  {cart
                    .reduce((sum, item) => sum + item.points * item.quantity, 0)
                    .toFixed(3)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography>Cashback:</Typography>
                <Typography color="success.main" fontWeight="bold">
                  {cart
                    .reduce(
                      (sum, item) => sum + item.cashback * item.quantity,
                      0
                    )
                    .toFixed(2)}
                  €
                </Typography>
              </Box>
            </Box>
          </Box>
        </Paper>
      </div>
    );
  };

  const renderDelivery = () => (
    <div className="space-y-4">
      <Paper sx={{ p: 2, bgcolor: "#F8F9FA", mb: 3 }}>
        <Box display="flex" gap={2} alignItems="center">
          <LocalShippingIcon color="primary" />
          <Typography>
            Doručenie do {cart[0]?.deliveryDays || 10} pracovných dní
          </Typography>
        </Box>
      </Paper>

      <RadioGroup
        value={selectedDelivery}
        onChange={(e) => setSelectedDelivery(e.target.value)}
      >
        <Paper sx={{ p: 2 }}>
          <FormControlLabel
            value="dpd"
            control={<Radio checked={true} />}
            label={
              <Box display="flex" justifyContent="space-between" width="100%">
                <Box display="flex" alignItems="center" gap={2}>
                  <img src="/path-to-dpd-logo.png" alt="DPD" height="30" />
                  <Box>
                    <Typography>DPD Kuriér</Typography>
                    <Typography variant="caption" color="text.secondary">
                      Doručenie na vašu adresu
                    </Typography>
                  </Box>
                </Box>
                <Typography color="success.main">
                  Bezplatné doručenie
                </Typography>
              </Box>
            }
          />
        </Paper>
      </RadioGroup>
    </div>
  );

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return renderCartItems();
      case 1:
        return renderDelivery();
      case 2:
        return <DeliveryDetailsForm onFormChange={handleDeliveryFormChange} />;
      case 3:
        return (
          <PaymentMethodSelection
            cart={cart}
            calculateTotal={calculateTotal}
            selectedPayment={selectedPayment}
            setSelectedPayment={setSelectedPayment}
            termsAccepted={termsAccepted}
            setTermsAccepted={setTermsAccepted}
            termsError={termsError}
            cartTotals={cartTotals}
          />
        );
      case 4:
        return (
          <OrderReview
            cart={cart}
            cartTotals={cartTotals}
            deliveryDetails={deliveryDetails}
            selectedPayment={selectedPayment}
            selectedDelivery={selectedDelivery}
          />
        );
      default:
        return null;
    }
  };

  const getStepButton = (step) => {
    switch (step) {
      case 0:
        return "Pokračovať k doprave";
      case 1:
        return "Pokračovať k dodacím údajom";
      case 2:
        return "Pokračovať k platbe";
      case 3:
        return "Skontrolovať objednávku";
      default:
        return "Pokračovať";
    }
  };

  const handleSubmitOrder = async () => {
    try {
      setLoading(true);
      const orderData = {
        delivery_method: selectedDelivery,
        payment_method: selectedPayment,
        shipping_name: `${deliveryDetails.firstName} ${deliveryDetails.lastName}`,
        shipping_email: deliveryDetails.email,
        shipping_phone: deliveryDetails.phone,
        shipping_address: `${deliveryDetails.street}, ${deliveryDetails.postalCode} ${deliveryDetails.city}`,
        ...(deliveryDetails.isCompany && {
          billing_name: deliveryDetails.companyName,
          billing_ico: deliveryDetails.ico,
          billing_dic: deliveryDetails.dic,
          billing_ic_dph: deliveryDetails.ic_dph,
          billing_address: `${deliveryDetails.street}, ${deliveryDetails.postalCode} ${deliveryDetails.city}`,
        }),
      };

      const response = await cartService.createOrder(orderData);

      if (selectedPayment === "card") {
        const stripe = await loadStripe(response.publicKey);
        const { error } = await stripe.redirectToCheckout({
          clientSecret: response.clientSecret,
        });

        if (error) {
          throw new Error(error.message);
        }
      } else {
        setOrderNumber(response.order_number);
        setOrderComplete(true);
      }
    } catch (error) {
      console.error("Order creation failed:", error);
      enqueueSnackbar(
        error.response?.data?.message ||
          "Nepodarilo sa vytvoriť objednávku. Skontrolujte prosím všetky údaje.",
        { variant: "error" }
      );
    } finally {
      setLoading(false);
    }
  };

  if (initialLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="60vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (orderComplete && orderNumber) {
    return <OrderSuccess orderNumber={orderNumber} total={calculateTotal()} />;
  }

  const canProceed = cart?.length > 0;

  return (
    <>
      <div className="max-w-screen-xl mx-auto pt-12 px-12">
        <Stepper activeStep={activeStep} sx={{ mb: 2 }}>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={() => (
                  <Box
                    sx={{
                      color: index === activeStep ? "black" : "#bdbdbd",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {step.icon}
                  </Box>
                )}
                sx={{
                  "& .MuiStepLabel-label": {
                    color: index === activeStep ? "black" : "#bdbdbd",
                  },
                }}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <div className="bg-[#F5F5F5]">
        <div className="max-w-screen-xl mx-auto pt-6">
          <Box sx={{ mt: 4 }}>{getStepContent(activeStep)}</Box>

          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
            <Button
              disabled={activeStep === 0}
              onClick={handleBack}
              variant="outlined"
            >
              Späť
            </Button>
            <Box>
              <Typography variant="h6" align="right" mb={2}>
                Celkom: {calculateTotal()}€
              </Typography>
              <Button
                variant="contained"
                onClick={
                  activeStep === steps.length - 1
                    ? handleSubmitOrder
                    : handleNext
                }
                size="large"
                disabled={!canProceed || loading}
              >
                {activeStep === steps.length - 1 ? (
                  loading ? (
                    <>
                      <CircularProgress size={20} sx={{ mr: 1 }} />
                      Spracovávam...
                    </>
                  ) : selectedPayment === "card" ? (
                    "Pokračovať k platbe"
                  ) : (
                    "Dokončiť objednávku"
                  )
                ) : (
                  getStepButton(activeStep)
                )}
              </Button>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
}
