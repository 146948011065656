import React from "react";
import { Button, Link } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

const OfferCard = ({ shop, onRegisterClick }) => {
  const { t } = useTranslation();

  const getDeliveryText = (deliveryDate) => {
    if (deliveryDate === 0) return "skladom";
    if (deliveryDate >= 1 && deliveryDate <= 3) return "do 3 dní";
    if (deliveryDate >= 4 && deliveryDate <= 7) return "do týždňa";
    if (deliveryDate >= 8 && deliveryDate <= 14) return "do 2 týždňov";
    if (deliveryDate >= 15 && deliveryDate <= 30) return "do mesiaca";
    if (deliveryDate >= 31) return "viac ako mesiac";
    return "info v obchode";
  };

  return (
    <div className="flex items-center  w-48 h-full flex-col justify-between shadow-md border-1 relative rounded-lg bg-white scale-85 md:scale-100">
      <div className="flex flex-col justify-start transition duration-300 min-w-48  w-48 h-full">
        {/* Image Section */}
        <div className="relative flex justify-center items-center py-2 pt-4">
          <img
            src={shop.shop_image}
            alt={shop.shop_name}
            className="w-32 h-12 rounded-x object-contain"
          />
        </div>

        {/* Price and Details Section */}
        <div className="flex flex-col gap-2 px-4">
          <div className="font-bold text-sm">
            {shop.price_vat?.replace(".", ",")}{" "}
            {shop.currency?.replace("EUR", "€")}
          </div>
          <div className="font-bold text-[#6B6B70] line-clamp-1 text-sm">
            {shop.shop_name}
          </div>

          {/* Tags Section */}
          <div className="flex flex-wrap gap-1 pb-2">
            {/* Cashback tag */}
            <span
              className={`text-white ${shop.computed_cashback > 0 ? "bg-primary" : "bg-gray-500"} text-xs px-2 py-0.5 rounded-full`}
            >
              {shop.computed_cashback > 0 ? (
                <>
                  Cashback{" "}
                  {(
                    shop.computed_cashback *
                    parseFloat(shop.price_vat.replace(/[^\d.]/g, ""))
                  ).toFixed(2)}{" "}
                  {shop.currency?.replace("EUR", "€")}
                </>
              ) : (
                t("BEZ CASHBACKU")
              )}
            </span>

            {/* Points tag */}
            {shop.computed_points && (
              <span className="bg-primary text-white text-xs px-2 py-0.5 rounded-full">
                {t("Body")}: {shop.computed_points}
              </span>
            )}

            {/* Stock tag */}
            {shop.stock && shop.stock !== "0" && (
              <span className="bg-green text-white text-xs px-2 py-0.5 rounded-full">
                {t("Na sklade")}
                {shop.stock_count ? `: ${shop.stock_count}` : ""}
              </span>
            )}

            {/* Delivery date tag */}
            {shop.delivery_date !== undefined && (
              <span className="bg-orange text-white text-xs px-2 py-0.5 rounded-full">
                {t("Dodanie")}: {t(getDeliveryText(shop.delivery_date))}
              </span>
            )}

            {/* Delivery price tag */}
            {shop.delivery && (
              <span className="bg-purple text-white text-xs px-2 py-0.5 rounded-full">
                {t("Doprava")}:{" "}
                {typeof shop.delivery === "object"
                  ? (
                      shop.delivery.DELIVERY_PRICE ||
                      shop.delivery["g:price"] ||
                      "0"
                    )
                      .toString()
                      .replace(".", ",")
                  : shop.delivery}
              </span>
            )}

            {/* Gift tag */}
            {shop.gift && (
              <span className="bg-yellow-500 text-white text-xs px-2 py-0.5 rounded-full">
                {t("Darček")}
              </span>
            )}

            {/* Extended warranty tag */}
            {shop.extended_warranty && (
              <span className="bg-indigo-500 text-white text-xs px-2 py-0.5 rounded-full">
                {t("Predĺžená záruka")}
              </span>
            )}

            {/* Special service tag */}
            {shop.special_service && (
              <span className="bg-pink text-white text-xs px-2 py-0.5 rounded-full">
                {t("Špeciálna služba")}
              </span>
            )}

            {/* Sales voucher tag */}
            {shop.sales_voucher && (
              <span className="bg-green text-white text-xs px-2 py-0.5 rounded-full">
                {t("Zľavový kupón")}
              </span>
            )}
          </div>
        </div>
      </div>
      {/* Button Section */}
      <div className="w-full">
        <div className="flex justify-center text-xs pb-2">
          <Button
            className="bg-primary text-white rounded-sm px-8 scale-90 w-full"
            onClick={() =>
              onRegisterClick(shop.final_url, shop.id, shop.shop_name)
            }
          >
            {t("Do obchodu")} &nbsp; &nbsp; &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OfferCard;
