import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import api from "../services/api";
import PropTypes from "prop-types";

const TagsSelector = ({ selectedTags = [], setSelectedTags }) => {
  const [options, setOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);

  // Fetch options from the API only once when the component mounts
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const response = await api.get(`get-shop-tags-admin/`);
        setOptions(response.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };

    fetchOptions();
  }, []);

  // Sync selectedOptions with selectedTags from props
  useEffect(() => {
    if (options.length > 0 && selectedTags.length > 0) {
      const preSelected = options.filter((option) =>
        selectedTags.includes(option.key)
      );
      setSelectedOptions(preSelected);
    }
  }, [options, selectedTags]);

  const handleChange = (event, newValue) => {
    // Log the new selected options when selection changes
    console.log("New Value:", newValue);

    // Update selectedOptions state
    setSelectedOptions(newValue);

    // Extract keys (IDs) from selected tag objects and update the parent state
    const selectedKeys = newValue.map((option) => option.key);
    setSelectedTags(selectedKeys);
  };

  return (
    <Autocomplete
      multiple
      id="tags-standard"
      options={options}
      getOptionLabel={(option) => option.value}
      value={selectedOptions}
      onChange={handleChange}
      disablePortal
      renderInput={(params) => (
        <TextField {...params} label="Tag" variant="outlined" />
      )}
    />
  );
};

TagsSelector.propTypes = {
  selectedTags: PropTypes.array, // Ensure selectedTags is always an array
  setSelectedTags: PropTypes.func.isRequired,
};

export default TagsSelector;
