import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Box,
  Autocomplete,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
} from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import api from "../../services/api";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { format } from "date-fns";
import UsersSelector from "../UsersSelector";
import { Input } from "@nextui-org/react";

export default function WalletTransactionsAdmin() {
  const { t } = useTranslation();
  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    date: "",
    status: "",
    amount: "",
    user: null,
  });

  // Form state
  const [formData, setFormData] = useState({
    amount: "",
    description: "",
    users: [],
    userSelection: { type: null, value: null },
  });

  const statusOptions = [
    { value: "pending", label: "Čakáme za overením nákupu" },
    { value: "confirmed", label: "Čakáme na cashback od partnera" },
    {
      value: "to_be_paid",
      label: "Bude vyplatené v najbližšom výplatnom termíne",
    },
    { value: "payed", label: "Vyplatené" },
    { value: "marked_as_bought", label: "Označené ako nakúpené" },
    { value: "ticket", label: "Odoslané na reklamáciu" },
    { value: "not_bought", label: "Označené ako nenakúpené / skryté" },
  ];

  const billStatusOptions = [
    { value: "pending", label: t("Čaká na spracovanie") },
    { value: "confirmed", label: t("Zaplatené") },
  ];

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [transactionToDelete, setTransactionToDelete] = useState(null);

  const fetchTransactions = async (pageNum = 1) => {
    setIsLoading(true);
    try {
      const queryParams = new URLSearchParams({
        page: pageNum.toString(),
      });

      if (filters.date) {
        queryParams.append("date", format(filters.date, "yyyy-MM-dd"));
      }
      if (filters.status) queryParams.append("status", filters.status);
      if (filters.amount) queryParams.append("amount", filters.amount);
      if (filters.user) queryParams.append("user", filters.user.id);

      const response = await api.get(
        `/get-all-wallet-transactions/?${queryParams.toString()}`
      );

      setTransactions(response.data.results);
      setTotalPages(Math.ceil(response.data.count / 10));
    } catch (error) {
      console.error("Error fetching transactions:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUsers = async (search = "") => {
    try {
      const response = await api.get("fetch-users/", { params: { search } });
      setUsers(response.data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  useEffect(() => {
    fetchTransactions(page);
  }, [page, filters]);

  const handleUserSearch = (event, newValue) => {
    setFilters((prev) => ({
      ...prev,
      user: newValue,
    }));
    setPage(1);
  };

  const handleOpenDialog = (transaction = null) => {
    if (transaction) {
      setFormData({
        amount: transaction.amount,
        description: transaction.description,
        users: transaction.user ? [transaction.user] : [],
        userSelection: {
          type: "users",
          value: transaction.user ? [transaction.user] : [],
        },
      });
      setSelectedTransaction(transaction);
    } else {
      setFormData({
        amount: "",
        description: "",
        users: [],
        userSelection: { type: null, value: null },
      });
      setSelectedTransaction(null);
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedTransaction(null);
    setFormData({
      amount: "",
      description: "",
      users: [],
      userSelection: { type: null, value: null },
    });
  };

  const handleSubmit = async () => {
    try {
      const submitData = {
        amount: formData.amount,
        description: formData.description,
        currency: "EUR",
        displayed_amount: parseFloat(formData.amount),
        date: new Date().toISOString(),
        ...(selectedTransaction
          ? {}
          : {
              userSelection: formData.userSelection,
            }),
        ...(selectedTransaction
          ? {
              id: selectedTransaction.id,
              email: selectedTransaction.email,
              user: selectedTransaction.user,
              commision: selectedTransaction.commision,
              bill: selectedTransaction.bill,
            }
          : {}),
      };

      let response;
      if (selectedTransaction) {
        response = await api.put(
          `/update-wallet-transaction/${selectedTransaction.id}/`,
          submitData
        );
        setTransactions((prevTransactions) =>
          prevTransactions.map((transaction) =>
            transaction.id === selectedTransaction.id
              ? response.data
              : transaction
          )
        );
      } else {
        response = await api.post(
          "/create-wallet-transaction-admin/",
          submitData
        );
        fetchTransactions(page);
      }

      handleCloseDialog();
    } catch (error) {
      console.error("Error saving transaction:", error);
    }
  };

  const handleOpenDeleteDialog = (transaction) => {
    setTransactionToDelete(transaction);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    try {
      await api.delete(`/delete-wallet-transaction/${transactionToDelete.id}/`);
      fetchTransactions(page);
      setDeleteDialogOpen(false);
      setTransactionToDelete(null);
    } catch (error) {
      console.error("Error deleting transaction:", error);
    }
  };

  const formatAmount = (amount, currency) => {
    return `${parseFloat(amount || 0).toFixed(2)} ${currency}`;
  };

  const filteredTransactions = transactions.filter((transaction) => {
    const matchesSearch =
      (transaction.description
        ? transaction.description
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        : false) ||
      (transaction.user
        ? transaction.user.toString().includes(searchTerm)
        : false);

    const matchesDateFrom =
      !filters.dateFrom ||
      (transaction.date &&
        new Date(transaction.date) >= new Date(filters.dateFrom));

    const matchesDateTo =
      !filters.dateTo ||
      (transaction.date &&
        new Date(transaction.date) <= new Date(filters.dateTo));

    const matchesAmountMin =
      !filters.amountMin ||
      (transaction.amount &&
        parseFloat(transaction.amount) >= parseFloat(filters.amountMin));

    const matchesAmountMax =
      !filters.amountMax ||
      (transaction.amount &&
        parseFloat(transaction.amount) <= parseFloat(filters.amountMax));

    return (
      matchesSearch &&
      matchesDateFrom &&
      matchesDateTo &&
      matchesAmountMin &&
      matchesAmountMax
    );
  });

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString("sk-SK", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prev) => ({
      ...prev,
      [name]: value,
    }));
    setPage(1); // Reset to first page when filters change
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case "pending":
        return t("Čaká na spracovanie");
      case "confirmed":
        return t("Zaplatené");
      default:
        return status;
    }
  };

  return (
    <div className="p-4">
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h5">{t("Správa transakcií peňaženky")}</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          {t("Nová transakcia")}
        </Button>
      </Box>

      {/* Filters */}
      <Box className="mb-4 grid grid-cols-1 md:grid-cols-4 gap-4">
        <Autocomplete
          options={users}
          getOptionLabel={(option) => `${option.email} (${option.id})`}
          value={filters.user}
          onChange={(event, newValue) => handleUserSearch(event, newValue)}
          onInputChange={(event, newInputValue) => {
            fetchUsers(newInputValue);
          }}
          renderInput={(params) => (
            <TextField {...params} label={t("Používateľ")} fullWidth />
          )}
        />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={t("Dátum")}
            value={filters.date}
            onChange={(newDate) => {
              setFilters((prev) => ({
                ...prev,
                date: newDate,
              }));
              setPage(1);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </LocalizationProvider>
        <TextField
          label={t("Suma")}
          type="number"
          name="amount"
          value={filters.amount}
          onChange={handleFilterChange}
          inputProps={{ step: "0.01" }}
        />
        <FormControl fullWidth>
          <InputLabel>{t("Status")}</InputLabel>
          <Select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            label={t("Status")}
          >
            <MenuItem value="">{t("Všetky")}</MenuItem>
            <MenuItem value="pending">{t("Čaká na spracovanie")}</MenuItem>
            <MenuItem value="confirmed">{t("Zaplatené")}</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("Dátum")}</TableCell>
              <TableCell>{t("Používateľ")}</TableCell>
              <TableCell>{t("Suma")}</TableCell>
              <TableCell>{t("Popis")}</TableCell>
              <TableCell>{t("Status")}</TableCell>
              <TableCell align="right">{t("Akcie")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {t("Načítavam...")}
                </TableCell>
              </TableRow>
            ) : transactions.length === 0 ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  {t("Žiadne transakcie")}
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((transaction) => (
                <TableRow key={transaction.id}>
                  <TableCell>{formatDate(transaction.date)}</TableCell>
                  <TableCell>
                    {transaction.email} ({transaction.user})
                  </TableCell>
                  <TableCell
                    className={
                      parseFloat(transaction.amount || 0) >= 0
                        ? "text-green-600"
                        : "text-red-600"
                    }
                  >
                    {transaction.currency !== "EUR"
                      ? `${formatAmount(
                          transaction.displayed_amount,
                          transaction.currency
                        )} (${formatAmount(transaction.amount, "EUR")})`
                      : formatAmount(transaction.amount, transaction.currency)}
                  </TableCell>
                  <TableCell>
                    {transaction.description}
                    {transaction.bill ? ` ID:${transaction.bill.id}` : ""}
                    {transaction.commision
                      ? ` ID:${transaction.commision.id}`
                      : ""}
                  </TableCell>
                  <TableCell>
                    {getStatusLabel(transaction.bill?.status)}
                  </TableCell>
                  <TableCell align="right">
                    <IconButton
                      onClick={() => handleOpenDialog(transaction)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      onClick={() => handleOpenDeleteDialog(transaction)}
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box className="flex justify-center mt-4">
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          color="primary"
        />
      </Box>

      {/* Create/Edit Dialog */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {selectedTransaction
            ? t("Upraviť transakciu")
            : t("Vytvoriť novú transakciu")}
        </DialogTitle>
        <DialogContent>
          <Box className="space-y-4 pt-4">
            {selectedTransaction ? (
              <TextField
                label={t("Používateľ")}
                value={selectedTransaction.email}
                fullWidth
                disabled
              />
            ) : (
              <UsersSelector
                selectedUsers={
                  formData.userSelection?.type === "group"
                    ? ["all_users"]
                    : formData.users
                }
                onChange={(selection) => {
                  setFormData((prev) => ({
                    ...prev,
                    users: selection.type === "users" ? selection.value : [],
                    userSelection: selection,
                  }));
                }}
              />
            )}
            <TextField
              label={t("Suma")}
              type="number"
              fullWidth
              value={formData.amount}
              onChange={(e) =>
                setFormData({ ...formData, amount: e.target.value })
              }
              inputProps={{ step: "0.01" }}
            />
            <TextField
              label={t("Popis")}
              fullWidth
              value={formData.description || ""}
              onChange={(e) =>
                setFormData({ ...formData, description: e.target.value })
              }
              multiline
              rows={3}
            />

            {/* Commission Information */}
            {selectedTransaction?.commision && (
              <Box className="p-4 bg-gray-50 rounded-md">
                <Typography variant="subtitle1" className="font-bold mb-2">
                  <strong>{t("Informácie o provízii")}</strong>
                </Typography>
                <div className="grid grid-cols-2 gap-2">
                  <Typography variant="body2">
                    {t("ID")}: {selectedTransaction.commision.id}
                  </Typography>
                  <Typography variant="body2">
                    {t("Typ")}: {selectedTransaction.commision.type}
                  </Typography>
                  <TextField
                    label={t("Hodnota")}
                    type="number"
                    value={selectedTransaction.commision.value}
                    onChange={(e) => {
                      setSelectedTransaction((prev) => ({
                        ...prev,
                        commision: {
                          ...prev.commision,
                          value: e.target.value,
                        },
                      }));
                    }}
                    size="small"
                    inputProps={{ step: "0.01" }}
                  />
                  <Typography variant="body2">
                    {t("Dátum")}:{" "}
                    {formatDate(selectedTransaction.commision.date)}
                  </Typography>
                  {selectedTransaction.commision.transaction && (
                    <>
                      <Typography
                        variant="subtitle1"
                        className="font-bold col-span-2 mt-2"
                      >
                        <strong>{t("Informácie o transakcii")}</strong>
                      </Typography>
                      <Typography variant="body2">
                        {t("ID transakcie")}:{" "}
                        {selectedTransaction.commision.transaction.id}
                      </Typography>
                      <TextField
                        label={t("Suma")}
                        type="number"
                        value={selectedTransaction.commision.transaction.amount}
                        onChange={(e) => {
                          setSelectedTransaction((prev) => ({
                            ...prev,
                            commision: {
                              ...prev.commision,
                              transaction: {
                                ...prev.commision.transaction,
                                amount: e.target.value,
                              },
                            },
                          }));
                        }}
                        size="small"
                        inputProps={{ step: "0.01" }}
                      />
                      <Typography variant="body2">
                        {t("Dátum")}:{" "}
                        {
                          selectedTransaction.commision.transaction
                            .formatted_date
                        }
                      </Typography>
                      <FormControl fullWidth size="small">
                        <InputLabel>{t("Status")}</InputLabel>
                        <Select
                          value={
                            selectedTransaction.commision.transaction.status
                          }
                          onChange={(e) => {
                            setSelectedTransaction((prev) => ({
                              ...prev,
                              commision: {
                                ...prev.commision,
                                transaction: {
                                  ...prev.commision.transaction,
                                  status: e.target.value,
                                },
                              },
                            }));
                          }}
                          label={t("Status")}
                        >
                          {statusOptions.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <Typography variant="body2">
                        {t("Používateľ")}:{" "}
                        {selectedTransaction.commision.transaction.username}
                      </Typography>
                      <Typography variant="body2">
                        {t("ID používateľa")}:{" "}
                        {selectedTransaction.commision.transaction.user_id}
                      </Typography>
                      <Typography variant="body2">
                        {t("Obchod")}:{" "}
                        {selectedTransaction.commision.transaction.shop_name}
                      </Typography>
                      <Typography variant="body2">
                        {t("Pôvod obchodu")}:{" "}
                        {selectedTransaction.commision.transaction.shop_origin}
                      </Typography>
                      {selectedTransaction.commision.transaction.click && (
                        <>
                          <Typography variant="body2">
                            {t("ID kliku")}:{" "}
                            {selectedTransaction.commision.transaction.click}
                          </Typography>
                          <Typography variant="body2">
                            {t("Používateľ kliku")}:{" "}
                            {
                              selectedTransaction.commision.transaction
                                .click_user
                            }
                          </Typography>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Box>
            )}

            {/* Bill Information */}
            {selectedTransaction?.bill && (
              <Box className="p-4 bg-gray-50 rounded-md">
                <Typography variant="subtitle1" className="font-bold mb-2">
                  {t("Informácie o faktúre")}
                </Typography>
                <div className="grid grid-cols-2 gap-2">
                  <Typography variant="body2">
                    {t("ID")}: {selectedTransaction.bill.id}
                  </Typography>
                  <Typography variant="body2">
                    {t("Číslo")}: {selectedTransaction.bill.number}
                  </Typography>
                  <Typography variant="body2">
                    {t("Suma")}: {selectedTransaction.bill.amount}
                  </Typography>
                  <FormControl fullWidth size="small">
                    <InputLabel>{t("Status")}</InputLabel>
                    <Select
                      value={selectedTransaction.bill.status}
                      onChange={(e) => {
                        setSelectedTransaction((prev) => ({
                          ...prev,
                          bill: {
                            ...prev.bill,
                            status: e.target.value,
                          },
                        }));
                      }}
                      label={t("Status")}
                    >
                      {billStatusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Typography variant="body2">
                    {t("Dátum")}: {formatDate(selectedTransaction.bill.date)}
                  </Typography>
                  {selectedTransaction.bill.file_link && (
                    <div className="flex flex-col gap-1">
                      <Button
                        variant="outlined"
                        size="small"
                        href={selectedTransaction.bill.pdf_link_sk}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("Zobraziť SK doklad")}
                      </Button>
                      <Button
                        variant="outlined"
                        size="small"
                        href={selectedTransaction.bill.pdf_link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("Zobraziť doklad")}
                      </Button>
                    </div>
                  )}
                </div>
              </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>{t("Zrušiť")}</Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {selectedTransaction ? t("Uložiť") : t("Vytvoriť")}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={() => setDeleteDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Potvrdiť vymazanie transakcie")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t(
              "Ste si istý, že chcete vymazať túto transakciu? Túto akciu nie je možné vrátiť späť."
            )}
          </DialogContentText>
          {transactionToDelete && (
            <Box className="mt-4 p-4 bg-gray-50 rounded-md">
              <Typography variant="subtitle2" className="font-bold mb-2">
                {t("Detaily transakcie")}:
              </Typography>
              <div className="grid grid-cols-2 gap-2">
                <Typography variant="body2">
                  <strong>{t("ID")}:</strong> {transactionToDelete.id}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("Používateľ")}:</strong>{" "}
                  {transactionToDelete.email}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("Dátum")}:</strong>{" "}
                  {formatDate(transactionToDelete.date)}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("Suma")}:</strong>{" "}
                  <span
                    className={
                      parseFloat(transactionToDelete.amount || 0) >= 0
                        ? "text-green-600"
                        : "text-red-600"
                    }
                  >
                    {formatAmount(
                      transactionToDelete.amount,
                      transactionToDelete.currency
                    )}
                  </span>
                </Typography>
                <Typography variant="body2" className="col-span-2">
                  <strong>{t("Popis")}:</strong>{" "}
                  {transactionToDelete.description || "-"}
                </Typography>
                <Typography variant="body2">
                  <strong>{t("Status")}:</strong>{" "}
                  {getStatusLabel(transactionToDelete.status)}
                </Typography>
              </div>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t("Zrušiť")}
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="error"
            variant="contained"
            autoFocus
          >
            {t("Vymazať")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
