import React from "react";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@nextui-org/react"; // Update this import
import InfoIcon from "@mui/icons-material/Info"; // Update this import

const ShopCardLarge = ({ shop, onRegisterClick }) => {
  const { t } = useTranslation();
  const tooltip = (
    <Tooltip
      content={
        <div
          className="text-xs text-center"
          dangerouslySetInnerHTML={{
            __html: t(
              "Výška percent cashbacku a DRC sa vypočítava z nakúpenej sumy bez DPH, bez poštovného. <br /> Počet bodov je vypočítaný z jednorazovej platby 1000 EUR vrátane DPH bez poštovného"
            ),
          }}
        ></div>
      }
    >
      <InfoIcon
        fontSize="inherit"
        className="ml-0.5 text-lightgray/50 text-xs"
      />
    </Tooltip>
  );

  return (
    <div className="mb-6 w-48 scale-85 md:scale-100 ">
      <a
        href={`/view-shop/${shop.id}`}
        className="shadow-md bg-white border-1 px-6 py-2 h-full rounded-xl flex flex-col justify-center items-center  overflow-hidden transition-transform duration-300 min-w-48"
        aria-label={`Visit ${shop.name} and earn cashback`}
      >
        <div className="w-32 h-32 flex justify-center mx-auto ">
          <img
            alt={`${shop.name} logo`}
            src={shop.logo_url}
            width={128}
            height={128}
            className="max-h-32 max-w-32 object-contain "
          />
        </div>
        {/* <div className="font-semibold text-sm text-center mb-1">{shop.name}</div> */}
        {/* <div className="w-full my-1">
        <hr />
      </div> */}

        <div className="text-xs text-center">
          <span
            className="inline"
            dangerouslySetInnerHTML={{
              __html: t("cez Bilionbuy získate"),
            }}
          />
          {tooltip}
        </div>
        {shop.direct_cashback && (
          <div className="text-green font-semibold my-1 flex flex-col gap-0.5 text-[10px] items-center">
            {/* First row */}
            <div className="flex flex-col gap-0.5">
              <span className="bg-green text-white rounded-2xl px-1.5 py-0.5 uppercase whitespace-nowrap text-center">
                cashback{" "}
                {shop.additional_commision_groups &&
                shop.additional_commision_groups.length > 0
                  ? "až "
                  : " "}
                <strong className="text-xs">{shop.max_cashback} %</strong>
              </span>
              <span className="bg-green text-white rounded-2xl px-1.5 py-0.5 font-bold uppercase whitespace-nowrap text-center">
                DRC{" "}
                {shop.additional_commision_groups &&
                shop.additional_commision_groups.length > 0
                  ? "až "
                  : " "}
                {shop.referal_cashback}
              </span>
              <span className="bg-green text-white rounded-2xl px-1.5 py-0.5 font-bold uppercase text-center items-center">
                {shop.additional_commision_groups &&
                shop.additional_commision_groups.length > 0
                  ? "až "
                  : " "}
                {shop.structural_cashback} {t("BODOV")}{" "}
              </span>
            </div>
          </div>
        )}
      </a>
    </div>
  );
};

export default ShopCardLarge;
