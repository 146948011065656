import { Button, Avatar, AvatarGroup } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FaLink } from "react-icons/fa";
import { MdBusinessCenter } from "react-icons/md";
import { BiSolidCategory } from "react-icons/bi";
import { MdLocalOffer } from "react-icons/md";
import { FaHotjar } from "react-icons/fa";
import { BiSolidOffer } from "react-icons/bi";
import { HiQuestionMarkCircle } from "react-icons/hi";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { RiContactsBook2Line } from "react-icons/ri";
import { ImBlogger } from "react-icons/im";
import { SiCashapp } from "react-icons/si";
import { FaUsers } from "react-icons/fa";

import CustomCarousel from "./CustomCarousel";

export default function Hero({ auth }) {
  const { t } = useTranslation();
  const cards = [
    {
      title: t("Obchody"),
      icon: MdBusinessCenter,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Partneri%201%20vel%CC%8Ckej%20vel%CC%8Ckosti.jpeg",
      link: "/shops/",
    },
    // {
    //   title: t("Kategorie"),
    //   icon: BiSolidCategory,
    //   img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Katego%CC%81ria.jpg",
    //   link: "/",
    // },
    {
      title: t("Sezónna ponuka"),
      icon: MdLocalOffer,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Sezo%CC%81nna%20ponuka%20produktov%202%20vel%CC%8Ckej%20vel%CC%8Ckosti.jpeg",
      link: "#seasonal",
    },
    // {
    //   title: t("Najpredávanejšie"),
    //   icon: FaHotjar,
    //   img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Sezo%CC%81nna%20ponuka%20produktov%202.jpg",
    //   link: "/",
    // },
    // {
    //   title: t("Akcie a zľavy"),
    //   icon: BiSolidOffer,
    //   img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Akcie%20a%20zl%CC%8Cavy.jpg",
    //   link: "/",
    // },
    {
      title: t("Ako to funguje"),
      icon: HiQuestionMarkCircle,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Ako%20to%20funguje%20vel%CC%8Ckej%20vel%CC%8Ckosti.jpeg",
      link: "#how-it-works",
    },

    {
      title: t("Výhody cashbacku"),
      icon: SiCashapp,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/vyhodycashbacku%20strednej%20vel%CC%8Ckosti.jpeg",
      link: "#cashback",
    },
    {
      title: t("Výhody odporúčania"),
      icon: FaUsers,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/vyhodyodporucania%20strednej%20vel%CC%8Ckosti.jpeg",
      link: "#affiliate",
    },
    {
      title: t("Čo hovoria ľudia"),
      icon: IoPeopleCircleSharp,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/C%CC%8Co%20hovoria%20l%CC%8Cudia%20vel%CC%8Ckej%20vel%CC%8Ckosti.jpeg",
      link: "#what-people-say",
    },
    {
      title: t("Blog"),
      icon: ImBlogger,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Blog%20strednej%20vel%CC%8Ckosti.jpeg",
      link: "/blog",
    },
    {
      title: t("Kontaktujte nás"),
      icon: RiContactsBook2Line,
      img: "https://bbuy.fra1.cdn.digitaloceanspaces.com/img/Kontakt%20vel%CC%8Ckej%20vel%CC%8Ckosti.jpeg",
      link: "#contact",
    },
  ];
  const copyRefferalCode = () => {
    navigator.clipboard.writeText(
      "https://www.bilionbuy.com/register?ref=" +
        localStorage.getItem("refcode")
    );
  };

  return (
    <div className="relative">
      <div className="w-full bg-gradient-to-r from-primary to-primary">
        {/* Content container */}
        <div className="relative max-w-screen-2xl mx-auto md:min-h-[600px] max-h-[500px]">
          {/* Background image with conditional overlay */}
          <div
            className="absolute inset-0 bg-cover bg-right bg-no-repeat after:content-[''] after:absolute after:inset-0 after:bg-black/60 lg:after:bg-transparent"
            style={{
              backgroundImage:
                "url('https://bbuy.fra1.cdn.digitaloceanspaces.com/img/new_hero3.png')",
            }}
          />

          {/* Content */}
          <div className="relative px-4 md:py-12 py-8 sm:px-6 lg:px-8 flex md:items-center justify-center lg:justify-start min-h-[600px]">
            <div className="w-full lg:w-1/2 text-white text-center lg:text-left">
              {/* Free badge */}
              <div className="inline-block px-4 py-2 bg-pink rounded-full text-sm mb-6 uppercase font-bold">
                {t("zadarmo")}
              </div>

              {/* Main heading */}
              <motion.h1
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="text-2xl md:text-5xl font-bold mb-6"
              >
                {t("Porovnaj kvalitu, cenu a zarábaj úplne ZDARMA!")}
              </motion.h1>

              {/* Subheading with avatars - stacked on mobile/tablet */}
              <div className="flex flex-col lg:flex-row items-center gap-4 mb-8 justify-center lg:justify-start">
                <AvatarGroup isBordered className="justify-center">
                  <Avatar
                    src="https://i.pravatar.cc/150?u=a042581f4e29026024d"
                    alt="User 1"
                  />
                  <Avatar
                    src="https://i.pravatar.cc/150?u=a04258a2462d826712d"
                    alt="User 2"
                  />
                  <Avatar
                    src="https://i.pravatar.cc/150?u=a042581f4e29026704d"
                    alt="User 3"
                  />
                  <Avatar
                    src="https://i.pravatar.cc/150?u=a04258114e29026302d"
                    alt="User 4"
                  />
                </AvatarGroup>
                <p className="text-sm md:text-lg">
                  <span
                    className="font-semibold"
                    dangerouslySetInnerHTML={{
                      __html: t("herotext.smalltitle"),
                    }}
                  />
                  <br />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t("herotext.description"),
                    }}
                  />
                </p>
              </div>

              {/* CTA Buttons */}
              {auth ? (
                <div className="flex flex-row gap-2 items-center justify-center lg:justify-start ">
                  <Button
                    as="a"
                    href="/my-zone#invites"
                    className="bg-pink text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10"
                  >
                    {t("Pozvi priateľa")} &rarr;
                  </Button>
                  <Button
                    isIconOnly
                    className="p-3 md:p-4 rounded-full border-1 border-white bg-pink"
                    onClick={copyRefferalCode}
                  >
                    <FaLink className="text-white" />
                  </Button>
                </div>
              ) : (
                <div className="text-center lg:text-left flex justify-center">
                  <Button
                    as="a"
                    href="/register"
                    className="bg-pink text-white rounded-3xl font-bold py-3 md:py-4 px-8 md:px-10 "
                  >
                    {t("Registrácia zdarma")} &rarr;
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Gradient overlay - using multiple layers for better blending */}
      <div className="absolute bottom-0 left-0 right-0 h-60 bg-gradient-to-t from-white via-white/95  to-transparent" />
      <div className="absolute bottom-0 left-0 right-0 h-40 bg-gradient-to-t from-white via-white/90 to-transparent" />

      {/* Cards section - positioned to overlap */}
      <div className="max-w-screen-2xl mx-auto relative -mt-20 xl:px-8 pb-6">
        {/* Mobile view - scrollable cards */}
        {/* <div className="flex md:hidden flex-row gap-4 justify-center">
          {cards.map((card) => (
            <CardItem key={card.title} card={card} />
          ))}
        </div> */}

        {/* Desktop view - carousel */}
        <div className="">
          <CustomCarousel
            itemWidth="176px"
            itemMaxWidth="280px"
            hideArrows={true}
            breakpoints={{
              "(max-width: 767px)": {
                slides: { perView: 1.8, spacing: 16 },
                origin: "start",
                mode: "free-snap",
              },
              "(min-width: 768px)": {
                slides: { perView: 3, spacing: 4 },
                origin: "start",
                mode: "snap",
              },
              "(min-width: 1024px)": {
                slides: { perView: 4, spacing: 4 },
                origin: "start",
                mode: "snap",
              },
              "(min-width: 1280px)": {
                slides: { perView: 5, spacing: 4 },
                origin: "start",
                mode: "snap",
              },
              "(min-width: 1536px)": {
                slides: { perView: 8, spacing: 8 },
                origin: "start",
                mode: "snap",
              },
            }}
          >
            {cards.map((card) => (
              <div key={card.title} className="sm:p-2 md:p-0">
                <CardItem key={card.title} card={card} />
              </div>
            ))}
          </CustomCarousel>
        </div>
      </div>
    </div>
  );
}

// Add this new component for the card item
const CardItem = ({ card }) => (
  <div className=" pb-4 h-full">
    <a
      href={card.link}
      className=" rounded-lg bg-white shadow-md -2xl transition-shadow duration-200 
      border overflow-hidden h-full flex flex-col w-full"
    >
      <div className="">
        <img
          src={card.img}
          alt={card.title}
          className="w-full h-32 object-cover"
        />
      </div>
      <div className="p-2 flex flex-row items-center gap-2 font-semibold text-sm w-full min-h-[40px]">
        <card.icon className="flex-shrink-0" />
        <div className="line-clamp-1 w-full ">{card.title}</div>
      </div>
    </a>
  </div>
);
