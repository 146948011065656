import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  Pagination,
  Typography,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import api from "../../services/api";

const getStatusLabel = (status) => {
  switch (status) {
    case "pending":
      return "Čakajúce";
    case "in_progress":
      return "V riešení";
    case "resolved":
      return "Vyriešené";
    case "closed":
      return "Uzavreté";
    case "open":
      return "Otvorené";
    default:
      return status;
  }
};

const TicketsTable = () => {
  const [tickets, setTickets] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [formData, setFormData] = useState({
    text: "",
    status: "pending",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    user_email: "",
    status: "all",
  });

  const fetchTickets = async () => {
    try {
      let url = `/tickets/?page=${page}`;
      if (filters.user_email) {
        url += `&user_email=${filters.user_email}`;
      }
      if (filters.status !== "all") {
        url += `&status=${filters.status}`;
      }
      const response = await api.get(url);
      setTickets(response.data.results);
      setTotalPages(response.data.total_pages);
    } catch (error) {
      console.error("Chyba pri načítaní reklamácií:", error);
    }
  };

  useEffect(() => {
    fetchTickets();
  }, [page, filters]);

  const handleOpen = (ticket = null) => {
    setSelectedTicket(ticket);
    setFormData(ticket || { text: "", status: "pending" });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedTicket(null);
    setFormData({ text: "", status: "pending" });
  };

  const handleSubmit = async () => {
    try {
      if (selectedTicket) {
        await api.put(`/tickets/${selectedTicket.id}/update/`, formData);
      } else {
        await api.post("/tickets/create/", formData);
      }
      fetchTickets();
      handleClose();
    } catch (error) {
      console.error("Error saving ticket:", error);
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Naozaj chcete vymazať túto reklamáciu?")) {
      try {
        await api.delete(`/tickets/${id}/delete/`);
        fetchTickets();
      } catch (error) {
        console.error("Chyba pri mazaní reklamácie:", error);
      }
    }
  };

  return (
    <div className="p-4">
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h5">Správa reklamácií</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpen()}
        >
          Nová reklamácia
        </Button>
      </Box>

      {/* Filters */}
      <Box className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <TextField
          label="Filter podľa emailu"
          value={filters.user_email}
          onChange={(e) => {
            setFilters((prev) => ({ ...prev, user_email: e.target.value }));
            setPage(1);
          }}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel>Stav</InputLabel>
          <Select
            value={filters.status}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, status: e.target.value }));
              setPage(1);
            }}
            label="Stav"
          >
            <MenuItem value="all">Všetky stavy</MenuItem>
            <MenuItem value="open">Otvorené</MenuItem>
            <MenuItem value="pending">Čakajúce</MenuItem>
            <MenuItem value="in_progress">V riešení</MenuItem>
            <MenuItem value="resolved">Vyriešené</MenuItem>
            <MenuItem value="closed">Uzavreté</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email používateľa</TableCell>
              <TableCell>Text reklamácie</TableCell>
              <TableCell>Transakcia</TableCell>
              <TableCell>Dátum</TableCell>
              <TableCell>Stav</TableCell>
              <TableCell align="right">Akcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tickets.map((ticket) => (
              <TableRow key={ticket.id}>
                <TableCell>{ticket.id}</TableCell>
                <TableCell>{ticket.user_email}</TableCell>
                <TableCell>{ticket.text || "—"}</TableCell>
                <TableCell>
                  {ticket.transaction ? `#${ticket.transaction}` : "—"}
                </TableCell>
                <TableCell>
                  {new Date(ticket.date).toLocaleString("sk-SK")}
                </TableCell>
                <TableCell>{getStatusLabel(ticket.status)}</TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => handleOpen(ticket)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDelete(ticket.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <Pagination
            count={totalPages}
            page={page}
            onChange={(e, newPage) => setPage(newPage)}
            color="primary"
          />
        </Box>
      )}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {selectedTicket ? "Upraviť reklamáciu" : "Vytvoriť novú reklamáciu"}
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            margin="normal"
            label="Text reklamácie"
            value={formData.text}
            onChange={(e) => setFormData({ ...formData, text: e.target.value })}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Stav</InputLabel>
            <Select
              value={formData.status}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.value })
              }
              label="Stav"
            >
              <MenuItem value="open">Otvorené</MenuItem>
              <MenuItem value="pending">Čakajúce</MenuItem>
              <MenuItem value="in_progress">V riešení</MenuItem>
              <MenuItem value="resolved">Vyriešené</MenuItem>
              <MenuItem value="closed">Uzavreté</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Zrušiť</Button>
          <Button onClick={handleSubmit} variant="contained">
            Uložiť
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default TicketsTable;
