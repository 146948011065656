import React, { useState, useEffect } from "react";
import ItemCard from "./ItemCard";
import CustomCarousel from "./CustomCarousel";
import { Skeleton, Button, Spinner } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import api, { cancelRequest } from "../services/api";

const SimilarProducts = ({ productId }) => {
  const { t } = useTranslation();
  const [similarProducts, setSimilarProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);

  const fetchSimilarProducts = async (page = 1) => {
    try {
      setLoading(true);
      const response = await api.get(
        `similiar-products/${productId}?page=${page}`
      );

      if (response.data.pagination) {
        setHasMore(response.data.pagination.has_next);
        if (page === 1) {
          setSimilarProducts(
            Array.isArray(response.data.results) ? response.data.results : []
          );
        } else {
          setSimilarProducts((prev) => [
            ...prev,
            ...(Array.isArray(response.data.results)
              ? response.data.results
              : []),
          ]);
        }
      } else {
        setSimilarProducts(Array.isArray(response.data) ? response.data : []);
      }
    } catch (error) {
      if (!api.isCancel(error)) {
        console.error("Error fetching similar products:", error);
        setSimilarProducts([]);
      }
    } finally {
      setLoading(false);
      setInitialLoading(false);
    }
  };

  useEffect(() => {
    setInitialLoading(true);
    fetchSimilarProducts(1);
    setCurrentPage(1);

    return () => {
      cancelRequest(`similiar-products/${productId}`);
    };
  }, [productId]);

  const handleLoadMore = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
    fetchSimilarProducts(nextPage);
  };

  if (initialLoading) {
    return (
      <div className="max-w-screen-xl mx-auto px-4 md:px-8">
        <div className="flex flex-col lg:flex-row justify-between py-8 md:py-12">
          <h2 className="text-2xl md:text-2xl lg:text-2xl font-bold">
            {t("Podobné produkty")}
          </h2>
        </div>
        <CustomCarousel
          itemWidth={"196px"}
          breakpoints={{
            "(max-width: 767px)": {
              mode: "free-snap",
              slides: {
                perView: 1.6,
                spacing: 0,
              },
            },
            "(min-width: 700px)": {
              mode: "snap",
              slides: { perView: 3, spacing: 12 },
            },
            "(min-width: 905px)": {
              mode: "snap",
              slides: { perView: 4, spacing: 12 },
            },
            "(min-width: 1079px)": {
              mode: "snap",
              slides: { perView: 5, spacing: 12 },
            },
            "(min-width: 1280px)": {
              mode: "snap",
              slides: { perView: 5, spacing: 12 },
            },
            "(min-width: 1536px)": {
              mode: "snap",
              slides: { perView: 6, spacing: 12 },
            },
          }}
        >
          {Array.from({ length: 7 }).map((_, index) => (
            <div
              key={index}
              className="w-full bg-white rounded-lg shadow-lg p-4 flex flex-col"
            >
              <div className="relative pb-[100%] mb-4">
                <Skeleton className="absolute inset-0 rounded-lg" />
              </div>
              <Skeleton className="h-6 w-3/4 mb-2" />
              <Skeleton className="h-4 w-1/2 mb-2" />
              <Skeleton className="h-4 w-2/3 mb-4" />
              <div className="mt-auto">
                <Skeleton className="h-10 w-full rounded-full" />
              </div>
            </div>
          ))}
        </CustomCarousel>
      </div>
    );
  }

  if (similarProducts.length === 0) {
    return null;
  }

  return (
    <div className="max-w-screen-xl mx-auto ">
      <div className="flex flex-col lg:flex-row justify-between py-8 md:py-12 px-4 lg:px-0">
        <h2 className="text-2xl md:text-2xl lg:text-2xl font-bold">
          {t("Podobné produkty")}
        </h2>
      </div>
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-y-4">
        {similarProducts.map((product, index) => (
          <ItemCard
            key={product.id || index}
            product={product}
            type="product"
          />
        ))}
      </div>
      {hasMore && (
        <div className="flex justify-center mt-8 mb-4">
          {loading ? (
            <Spinner color="primary" />
          ) : (
            <Button color="primary" onClick={handleLoadMore}>
              {t("Zobraziť viac")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default SimilarProducts;
