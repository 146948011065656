import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Box,
  Typography,
  Chip,
  DialogContentText,
  Pagination,
  FormControl,
  InputLabel,
  TablePagination,
} from "@mui/material";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import api from "../../services/api";

const ThesaurusTable = () => {
  const [corrections, setCorrections] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [editingItem, setEditingItem] = useState(null);
  const [formData, setFormData] = useState({
    destination_text: "",
    synonyms: [],
    language: "sk",
    type: "Product",
  });
  const [tempSynonym, setTempSynonym] = useState("");
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [filters, setFilters] = useState({
    destination_text: "",
    language: "all",
    type: "all",
  });
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const debounceTimerRef = useRef(null);

  const debouncedFilterChange = useCallback((field, value) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      setFilters((prev) => ({
        ...prev,
        [field]: value,
      }));
    }, 500);
  }, []);

  useEffect(() => {
    return () => {
      if (debounceTimerRef.current) {
        clearTimeout(debounceTimerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    fetchCorrections();
  }, []);

  const fetchCorrections = async () => {
    try {
      const queryParams = new URLSearchParams();
      queryParams.append("page", page);
      queryParams.append("page_size", rowsPerPage);
      if (filters.destination_text) {
        queryParams.append("destination_text", filters.destination_text);
      }
      if (filters.language !== "all") {
        queryParams.append("language", filters.language);
      }
      if (filters.type !== "all") {
        queryParams.append("type", filters.type);
      }

      const response = await api.get(
        `thesaurus-corrections/?${queryParams.toString()}`
      );
      const correctionsArray = response.data?.results || [];
      setCorrections(correctionsArray);
      setTotalPages(response.data.total_items);
    } catch (error) {
      console.error("Error fetching corrections:", error);
      setCorrections([]);
    }
  };

  useEffect(() => {
    fetchCorrections();
  }, [page, filters, rowsPerPage]);

  const handleOpenDialog = (item = null) => {
    if (item) {
      setEditingItem(item);
      setFormData({
        destination_text: item.destination_text,
        synonyms: item.synonyms || [],
        language: item.language,
        type: item.type,
      });
    } else {
      setEditingItem(null);
      setFormData({
        destination_text: "",
        synonyms: [],
        language: "sk",
        type: "Product",
      });
    }
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setEditingItem(null);
    setTempSynonym("");
  };

  const handleAddSynonym = () => {
    if (tempSynonym && !formData.synonyms.includes(tempSynonym)) {
      setFormData({
        ...formData,
        synonyms: [...formData.synonyms, tempSynonym],
      });
      setTempSynonym("");
    }
  };

  const handleRemoveSynonym = (synonym) => {
    setFormData({
      ...formData,
      synonyms: formData.synonyms.filter((s) => s !== synonym),
    });
  };

  const handleSubmit = async () => {
    try {
      if (editingItem) {
        await api.put(
          `thesaurus-corrections/${editingItem.id}/update/`,
          formData
        );
      } else {
        await api.post("thesaurus-corrections/create/", formData);
      }
      fetchCorrections();
      handleCloseDialog();
    } catch (error) {
      console.error("Error saving correction:", error);
    }
  };

  const handleDeleteClick = (correction) => {
    setItemToDelete(correction);
    setDeleteDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setItemToDelete(null);
  };

  const handleDeleteConfirm = async () => {
    try {
      await api.delete(`thesaurus-corrections/${itemToDelete.id}/delete/`);
      fetchCorrections();
      setDeleteDialogOpen(false);
      setItemToDelete(null);
    } catch (error) {
      console.error("Error deleting correction:", error);
    }
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className="p-4">
      <Box className="flex justify-between items-center mb-4">
        <Typography variant="h5">Správa slovníka</Typography>
        <Button
          variant="contained"
          startIcon={<AddIcon />}
          onClick={() => handleOpenDialog()}
        >
          Pridať novú korekciu
        </Button>
      </Box>

      {/* Filters */}
      <Box className="mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <TextField
          label="Filter podľa textu"
          value={filters.destination_text}
          onChange={(e) => {
            debouncedFilterChange("destination_text", e.target.value);
            setPage(1);
          }}
          fullWidth
        />
        <FormControl fullWidth>
          <InputLabel>Jazyk</InputLabel>
          <Select
            value={filters.language}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, language: e.target.value }));
              setPage(1);
            }}
            label="Jazyk"
          >
            <MenuItem value="all">Všetky jazyky</MenuItem>
            <MenuItem value="sk">Slovenčina</MenuItem>
            <MenuItem value="cz">Čeština</MenuItem>
            <MenuItem value="en">Angličtina</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Typ</InputLabel>
          <Select
            value={filters.type}
            onChange={(e) => {
              setFilters((prev) => ({ ...prev, type: e.target.value }));
              setPage(1);
            }}
            label="Typ"
          >
            <MenuItem value="all">Všetky typy</MenuItem>
            <MenuItem value="Category">Kategória</MenuItem>
            <MenuItem value="Product">Produkt</MenuItem>
            <MenuItem value="Shop">Obchod</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Cieľový text</TableCell>
              <TableCell>Synonymá</TableCell>
              <TableCell>Jazyk</TableCell>
              <TableCell>Typ</TableCell>
              <TableCell align="right">Akcie</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(corrections) && corrections.length > 0 ? (
              corrections.map((correction) => (
                <TableRow key={correction.id}>
                  <TableCell className="whitespace-nowrap">
                    {correction.destination_text}
                  </TableCell>
                  <TableCell>
                    <Box
                      sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}
                      className="max-h-[200px] overflow-auto"
                    >
                      {Array.isArray(correction.synonyms) &&
                        correction.synonyms.map((synonym, index) => (
                          <Chip key={index} label={synonym} size="small" />
                        ))}
                    </Box>
                  </TableCell>
                  <TableCell>{correction.language}</TableCell>
                  <TableCell>{correction.type}</TableCell>
                  <TableCell align="right whitespace-nowrap">
                    <IconButton onClick={() => handleOpenDialog(correction)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(correction)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={5} align="center" sx={{ py: 3 }}>
                  <Typography color="text.secondary">
                    Neboli nájdené žiadne záznamy. Kliknite na tlačidlo "Pridať
                    novú korekciu" pre vytvorenie záznamu.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        component="div"
        count={totalPages}
        page={page - 1}
        onPageChange={(e, newPage) => setPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50]}
        labelRowsPerPage="Riadkov na stránku:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} z ${count}`}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          {editingItem ? "Upraviť korekciu" : "Pridať novú korekciu"}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2, pt: 2 }}>
            <TextField
              label="Cieľový text"
              value={formData.destination_text}
              onChange={(e) =>
                setFormData({ ...formData, destination_text: e.target.value })
              }
              fullWidth
            />

            <Box>
              <Typography variant="subtitle2" sx={{ mb: 1 }}>
                Synonymá
              </Typography>
              <Box sx={{ display: "flex", gap: 1, mb: 1 }}>
                <TextField
                  value={tempSynonym}
                  onChange={(e) => setTempSynonym(e.target.value)}
                  placeholder="Pridať synonymum"
                  size="small"
                />
                <Button variant="outlined" onClick={handleAddSynonym}>
                  Pridať
                </Button>
              </Box>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
                {formData.synonyms.map((synonym, index) => (
                  <Chip
                    key={index}
                    label={synonym}
                    onDelete={() => handleRemoveSynonym(synonym)}
                    size="small"
                  />
                ))}
              </Box>
            </Box>

            <Select
              value={formData.language}
              onChange={(e) =>
                setFormData({ ...formData, language: e.target.value })
              }
              fullWidth
            >
              <MenuItem value="sk">Slovenčina</MenuItem>
              <MenuItem value="cz">Čeština</MenuItem>
              <MenuItem value="en">Angličtina</MenuItem>
            </Select>

            <Select
              value={formData.type}
              onChange={(e) =>
                setFormData({ ...formData, type: e.target.value })
              }
              fullWidth
            >
              <MenuItem value="Category">Kategória</MenuItem>
              <MenuItem value="Product">Produkt</MenuItem>
              <MenuItem value="Shop">Obchod</MenuItem>
            </Select>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Zrušiť</Button>
          <Button onClick={handleSubmit} variant="contained">
            {editingItem ? "Aktualizovať" : "Vytvoriť"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Potvrdenie vymazania</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Ste si istý, že chcete vymazať túto korekciu?
          </DialogContentText>
          {itemToDelete && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="subtitle2" color="text.secondary">
                Cieľový text: <strong>{itemToDelete.destination_text}</strong>
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Synonymá:{" "}
                {itemToDelete.synonyms?.map((synonym, index) => (
                  <Chip
                    key={index}
                    label={synonym}
                    size="small"
                    sx={{ ml: 1, mb: 1 }}
                  />
                ))}
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Jazyk: <strong>{itemToDelete.language}</strong>
              </Typography>
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mt: 1 }}
              >
                Typ: <strong>{itemToDelete.type}</strong>
              </Typography>
            </Box>
          )}
          <Typography
            variant="body2"
            color="error"
            sx={{ mt: 2, fontWeight: "medium" }}
          >
            Táto akcia sa nedá vrátiť späť.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Zrušiť
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            color="error"
            variant="contained"
            autoFocus
          >
            Vymazať
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ThesaurusTable;
