import { Helmet } from "react-helmet-async";
import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import Product from "../components/Product";
import ShopList from "../components/ShopList";
import SimilarProducts from "../components/SimilarProducts";
import RelatedShops from "../components/RelatedShops";
import api from "../services/api";
import { useLeaveModal } from "../components/LeaveModalContext";
import { useTranslation } from "react-i18next";
import { Spinner } from "@nextui-org/react";

export default function ProductView() {
  let { slug } = useParams();
  const [searchOpen, setSearchOpen] = useState(false);
  const [selector, setSelector] = useState("price");
  const [shopOffers, setShopOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [productData, setProductData] = useState(null);
  const { openModal } = useLeaveModal();
  const { t } = useTranslation();

  // Handler for scrolling to offers list
  const handleCompareClick = () => {
    const element = document.getElementById("offers-list");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  // Handler for switching to params tab
  const handleReadMoreClick = () => {
    setSelector("params");
  };

  // Fetch product data and shop offers
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        // Fetch product info
        const productResponse = await api.get(`get-product-info/${slug}/`);
        setProductData(productResponse.data.products);

        // Fetch shop offers
        const offersResponse = await api.get(`get-shop-links/${slug}/`);
        setShopOffers(offersResponse.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
        setProductData(null);
        setShopOffers([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (slug) {
      fetchData();
    }
  }, [slug]);

  // Get best offers from the existing shop data
  const getBestOffers = () => {
    return [...shopOffers]
      .sort((a, b) => {
        const priceA = parseFloat(a.price_vat.replace(/[^\d.]/g, ""));
        const priceB = parseFloat(b.price_vat.replace(/[^\d.]/g, ""));
        const cashbackA = a.computed_cashback
          ? parseFloat(a.computed_cashback)
          : 0;
        const cashbackB = b.computed_cashback
          ? parseFloat(b.computed_cashback)
          : 0;
        return priceA - priceA * cashbackA - (priceB - priceB * cashbackB);
      })
      .slice(0, 2);
  };

  // Get best cashback offers from the existing shop data
  const getBestCashbackOffers = () => {
    return [...shopOffers]
      .sort((a, b) => {
        const priceA = parseFloat(a.price_vat.replace(/[^\d.]/g, ""));
        const priceB = parseFloat(b.price_vat.replace(/[^\d.]/g, ""));
        const cashbackA = a.computed_cashback
          ? parseFloat(a.computed_cashback)
          : 0;
        const cashbackB = b.computed_cashback
          ? parseFloat(b.computed_cashback)
          : 0;
        return priceB * cashbackB - priceA * cashbackA;
      })
      .slice(0, 2);
  };

  const handleRedirectClick = (shopUrl, shopId, shopName) => {
    const userToken = localStorage.getItem("jwt");
    const isAuthenticated = !!userToken;

    if (isAuthenticated) {
      const registerClick = async (linkId) => {
        const response = await api.post("register-click/", {
          linkId,
          userToken,
        });
        console.log("Click registered:", response.data);
      };
      registerClick(shopId);
    }

    openModal(
      shopName,
      () => {
        const link = document.createElement("a");
        link.href = shopUrl;
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      },
      isAuthenticated
    );
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner size="lg" />
      </div>
    );
  }

  return (
    <>
      <Helmet>
        <title>Product Details - Bilionbuy</title>
        <meta
          name="description"
          content="Discover the best deals on products and compare prices from various sellers."
        />
      </Helmet>

      <div className="sticky top-0 z-50">
        <BasicNavbar setSearchOpen={setSearchOpen} />
      </div>

      <Product
        id={slug}
        productData={productData}
        onReadMoreClick={handleReadMoreClick}
        onCompareClick={handleCompareClick}
        handleRedirectClick={handleRedirectClick}
        bestOffers={getBestOffers()}
        bestCashbackOffers={getBestCashbackOffers()}
        isLoading={isLoading}
      />
      <div className="bg-[#F1F3F5] pb-12">
        <ShopList
          id={slug}
          selector={selector}
          setSelector={setSelector}
          shopOffers={shopOffers}
          productData={productData}
          isLoading={isLoading}
          handleRedirectClick={handleRedirectClick}
        />

        <RelatedShops productSlug={slug} t={t} />
        <SimilarProducts productId={slug} t={t} />
      </div>
      <Footer />
    </>
  );
}
