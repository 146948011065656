import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import BasicNavbar from "../components/BasicNavbar";
import Footer from "../components/Footer";
import WalletMenu from "../components/wallet/WalletMenu";
import WalletContent from "../components/wallet/WalletContent";
import { SwipeableDrawer, Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

export default function Wallet() {
  const [activeItemId, setActiveItemId] = useState(0);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const handleDrawerOpen = () => setIsMenuOpen(true);
  const handleDrawerClose = () => setIsMenuOpen(false);

  const handleHashChange = () => {
    const hash = window.location.hash.slice(1);
    const menuItems = {
      balance: 0,
      transactions: 1,
      payouts: 2,
      statements: 3,
    };

    if (hash in menuItems) {
      setActiveItemId(menuItems[hash]);
    }
  };

  useEffect(() => {
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  // Common empty state component
  const EmptyState = ({ message }) => (
    <Paper className="p-8 text-center">
      <Typography variant="h6" color="textSecondary">
        {message}
      </Typography>
    </Paper>
  );

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
        <title>{t("Elektronická peňaženka")}</title>
        <meta
          name="description"
          content={t(
            "Správa elektronickej peňaženky, história transakcií a výplat"
          )}
        />
      </Helmet>
      <BasicNavbar />
      <div className="overflow-x-hidden">
        <SwipeableDrawer
          anchor="left"
          open={isMenuOpen}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
          disableSwipeToOpen={false}
          swipeAreaWidth={30}
        >
          <div className="w-[300px]">
            <WalletMenu
              setActiveItem={setActiveItemId}
              activeItemId={activeItemId}
              onMenuToggle={handleDrawerClose}
            />
          </div>
        </SwipeableDrawer>

        <div className="flex flex-row justify-center gap-2">
          <div className="pt-12 hidden xl:block">
            <WalletMenu
              setActiveItem={setActiveItemId}
              activeItemId={activeItemId}
              onMenuToggle={() => {}}
            />
          </div>

          <div className="w-full xl:w-2/3 px-4">
            <WalletContent
              activeItemId={activeItemId}
              onMenuToggle={handleDrawerOpen}
              EmptyState={EmptyState}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
